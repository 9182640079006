import React from 'react';

import { SeminarFilterModel } from '../../../../api';

import Checkbox from '../../../_shared/Checkbox/Checkbox';
import TextField from '../../../_shared/TextField/TextField';
import DatePicker from '../../../_shared/Datepicker/Datepicker';
import Select from '../../../_shared/Select/Select';
import { SelectOptionsModel } from '../../../_shared/form/models';
import cx from 'classnames';

// Styles
import './seminar-filter.scss';

type SeminarsFilterProps = {
    isBrandingEnabled: boolean;
    isDistanceFilteringEnabled: boolean;
    isZipValid: boolean;
    filter: SeminarFilterModel;
    results: number;
    radius: number;
    search: (filter: SeminarFilterModel) => void;
};

const modifyFilter = (origFilter: SeminarFilterModel) => (
    deltaFilter: Partial<SeminarFilterModel>
) => ({
    ...origFilter,
    pageNumber: 1,
    ...deltaFilter,
});

const normalize = (val: string) => (val?.length > 0 ? val : undefined);


let radiusValues = [5, 10, 25, 50];
let radiusArray: Array<SelectOptionsModel> = [];
for (var i = 0; i < radiusValues.length; i++) {
    radiusArray.push({
        label: radiusValues[i],
        value: radiusValues[i],
    });
}

const createSearchRoutines = (
    search: (filter: SeminarFilterModel) => void,
    filter: SeminarFilterModel
) => {
    const searchModifier = (deltaFilter: Partial<SeminarFilterModel>) =>
        search(modifyFilter(filter)(deltaFilter));
    
    return {
        withName: (name: string) =>
            searchModifier({
                name: normalize(name),
            }),

        withZipCode: (zipCode: string) =>
            searchModifier({
                zip: normalize(zipCode),
            }),

        withRadius: (radius: number | null) =>
            searchModifier({
                radius: radius !== null ? radius : undefined,
            }),

        withDate: (date: Date | null) =>
            searchModifier({
                date: date !== null ? date : undefined,
            }),

        toggleShowFull: () =>
            searchModifier({
                showFull: !filter.showFull,
            }),
    };
};

const SeminarsFilter = ({ filter, isBrandingEnabled, isDistanceFilteringEnabled, isZipValid, radius, results, search }: SeminarsFilterProps) => {
    const routines = createSearchRoutines(search, filter);

    const clearFilter = () => {
        filter.name = '';
        filter.zip = '';
        filter.date = undefined;
        filter.radius = undefined;
        routines.withName('');
        document.getElementById("radiusdropdown").selectedIndex = 0;
        if (document.getElementById("radiusdropdown").classList.contains('select--filled'))
            document.getElementById("radiusdropdown").classList.remove('select--filled');
        routines.withDate(null);
        routines.withZipCode('');
    };
    return (
        <div className="filters-wrapper">
            {isBrandingEnabled ? <p className="filter__results-count">{results} Results </p> : null}

            <TextField
                name="nameFilter"
                placeholder="Search for a seminar"
                onChange={(event) => routines.withName(event.target.value)}
                className={cx('filter', { 'filter-width--advise': isBrandingEnabled })}
                classNameContainer="filter-container"
                showLabel={isBrandingEnabled}
                value={filter.name}
            />
            
            <div className="zipFilter-wrapper">
                <TextField
                    name="zipFilter"
                    placeholder="Filter by ZIP code"
                    onChange={(event) => routines.withZipCode(event.target.value)}
                    className={cx('filter', { 'filter-width--advise': isBrandingEnabled })}
                    classNameContainer="filter-container"
                    showLabel={isBrandingEnabled}
                    value={filter.zip}
                />
                <div className="invalidZipMessage">
                    {(isDistanceFilteringEnabled && filter.zip !== undefined && !isZipValid) ? <p>Please enter a valid zip code.</p> : null}
                </div>
            </div>

            <div className="radiusFilter-wrapper">
                {isDistanceFilteringEnabled ? <Select
                    id={'radiusdropdown'}
                    className={cx('filter', { 'filter-width--advise': isBrandingEnabled })}
                    disabled={filter.zip === undefined}
                    classNameContainer="filter-container"
                    options={radiusArray}
                    onChange={(event) => routines.withRadius(event.target.value)}
                    label="Filter by distance"
                    name="radius"
                    showLabel={isBrandingEnabled}
                    showAlternativeArrow={isBrandingEnabled}
                    value={filter.radius}
                /> : null}
                <div className="zipMessage">
                    {filter.radius !== undefined ? <p>Showing seminars within {filter.radius} miles.</p> : null}
                </div>
            </div>

            <DatePicker
                className="filter"
                name="dateFilter"
                onChange={(date) => routines.withDate(date ? date : null)}
                placeholder="Filter by date"
                label="Filter by date"
                showAlternativeArrow={isBrandingEnabled}
                showLabel={isBrandingEnabled}
                value={filter.date}
            />

            <Checkbox
                text="Include seminars that are full"
                name="showFull"
                value={filter.showFull}
                onChange={routines.toggleShowFull}
                classNameContainer="filter-cb"
            />

            <button className="filter filter-clear" onClick={clearFilter}>
                Clear Filters
            </button>
        </div>
    );
};

export default SeminarsFilter;
