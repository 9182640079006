import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import logoIcon from '../../../../../dist/assets/icon/favicon_rnd.png';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    progress: {
        zIndex: 1300,
        position: 'absolute',
        margin: -10,
        color: 'rgba(63, 81, 181, 0.5)',
        animationDuration: '1000ms',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
  }),
);

type LoadBackdropProps = {
    isLoading: boolean;
};

export default (props: LoadBackdropProps) => {
  const classes = useStyles();

  return props.isLoading ? (
    <div className={classes.container}>
        <div>
            <CircularProgress className={classes.progress} size={100} thickness={4} color="primary" disableShrink />
            <img src={logoIcon} width="80" height="80" style={{ position: 'absolute', zIndex: 1301 }} />
        </div>
        <Backdrop className={classes.backdrop} open={props.isLoading} />
    </div>
  ) : null;
};