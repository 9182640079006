import React, { Fragment } from 'react';

import SeminarStub from './../seminar-stub-item/seminar-stub-item';
import FormSkeleton from '../../../_shared/dynamo/components/form-readonly-skeleton';

import Banner from '../../../_shared/util/components/banner';

import { SeminarFilterModel, SeminarModel } from '../../../../api';
import { AsyncEntity, AsyncStatus } from '../../../_shared/action-model-async';
import { Form } from 'dynamo-models';
import { NotificationType } from '../../../dialog/models';

// Styles
import './seminar-stub-list.scss';

type SeminarStubListProps = {
    seminarsEntity: AsyncEntity<SeminarModel[], SeminarFilterModel>;
    stubFormEntity: AsyncEntity<Form | undefined>;
    isBrandingEnabled: boolean;
    selectSeminar: (seminarId: number) => void;
};

function firstSeminarOftheMonth(seminar, seminars) {
    seminars.forEach((seminarItem) => (seminarItem.date = new Date(seminarItem.startDateTime)));

    let seminarsThisMonth = seminars.filter((seminarItem) => {
        return (
            seminar.date.getMonth() == seminarItem.date.getMonth() &&
            seminar.date.getFullYear() == seminarItem.date.getFullYear()
        );
    });

    if (seminarsThisMonth[0] == seminar) {
        return true;
    }

    return false;
}

const SeminarStubList = ({
    seminarsEntity,
    stubFormEntity,
    isBrandingEnabled,
    selectSeminar,
    beId,
}: SeminarStubListProps) => {
    const seminars = seminarsEntity.entity;
    const form = stubFormEntity.entity;
    const didSucceed = seminarsEntity.didSucceed;

    return (
        <Fragment>
            {didSucceed && seminars && form ? (
                seminars.length > 0 ? (
                    <div className="seminars-list">
                        {seminars.map((seminar) => {
                            let isFirst = firstSeminarOftheMonth(seminar, seminars);

                            return (
                                <div key={seminar.appointmentId}>
                                    {isBrandingEnabled && isFirst ? (
                                        <div className="seminar-stub__header">
                                            <div className="seminar-stub__header-container">
                                                <span className="seminar-stub__month">
                                                    {seminar.date.toLocaleString('default', {
                                                        month: 'long',
                                                    })}
                                                </span>
                                                <div className="seminar-stub__time-heading"></div>
                                                <span className="seminar-stub__heading">
                                                    Seminar details
                                                </span>
                                                <span className="seminar-stub__availability">
                                                    Availability
                                                </span>
                                                <div className="seminar-stub__cta-heading"></div>
                                            </div>
                                        </div>
                                    ) : null}
                                    <SeminarStub
                                        isBrandingEnabled={isBrandingEnabled}
                                        first={isFirst}
                                        key={seminar.appointmentId}
                                        seminar={seminar}
                                        form={stubFormEntity.entity as Form}
                                        onSelect={() => selectSeminar(seminar.appointmentId)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : (beId === 48) ? (
                        <div className="no-seminars-container">
                            <div className="no-seminars-message">
                                There are no events in your area at this time, but additional options are available.
                            </div>
                            <div className="no-seminars-message">
                                Please <span className="please-call-text">call (844) 470-0644</span> for
                            further assistance.
                            </div>
                        </div>
                ) : (beId === 55) ? (
                    <div className="no-seminars-container">
                        <div className="no-seminars-message">
                            There are no events in your area at this time, but additional options are available.
                        </div>
                        <div className="no-seminars-message">
                            Please <span className="please-call-text">call (855) 455-7060</span> for
                            further assistance.
                        </div>
                    </div>
                ) : (beId == 54) ? (
                        <div className="no-seminars-container">
                            <div className="no-seminars-message">
                                        If there are no In-Person events in your area at this time, <span style={{ color: 'red' }}>you can search for a Virtual Meeting!</span>
                                        {"\n"}
                                        Delete/Clear your Zip Code, then under <i>Let&apos;s find a seminar for you,</i> type <span style={{ color: 'red' }} >&quot;Virtual&quot;</span> into  Search for a Seminar for more options.
                                    </div>
                        </div>
                            ) : isBrandingEnabled ? (
                                    <div className="no-seminars-container">
                                        <div className="no-seminars-message">
                                            There are no events in your area at this time. Please check back later to see if an event has been scheduled
                                        </div>
                                    </div>
                            ): (
                                    <div className="no-seminars-container">
                                        <div className="no-seminars-message">
                                            There are no events in your area at this time. Please check back later to see if an event has been scheduled
                                        </div>
                                    </div>
                )
            ) : seminarsEntity.isWorking || stubFormEntity.isWorking ? (
                <FormSkeleton height={120} count={3} />
            ) : seminarsEntity.state.status === AsyncStatus.Failed ? (
                <Banner severity={NotificationType.Error} message={seminarsEntity.state.message} />
            ) : null}
        </Fragment>
    );
};

export default SeminarStubList;
