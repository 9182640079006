import React, { lazy, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';

import LoadBackdrop from '../features/_shared/load/components/load-backdrop';
import ConfirmDialog from '../features/dialog/components/confirm-dialog';
import NotificationDialog from '../features/dialog/components/notification-dialog';

import { fetchFeaturesAsync } from '../features/access/actions';
import { fetchAnalyticsAsync } from '../features/analytics/actions';

import { RootState } from '../store/types';
import { AsyncEntity, AsyncStatus } from '../features/_shared/action-model-async';
import { FeatureSettings, ClientSettings, TemplateModel } from '../api';
import { AnalyticsTrackerModel } from '../api/models';
import Banner from '../features/_shared/util/components/banner';
import { NotificationType } from '../features/dialog/models';
import { fetchSettingsAsync } from '../features/tenants/actions';
import { fetchTemplatesAsync } from '../features/template/actions';
import { getTemplates } from '../features/template/selectors';
import { getSiteSettings, getBranding } from '../features/tenants/selectors';
import { Redirect } from 'react-router';

type StateProps = {
    isLoading: boolean;
    featuresEntity: AsyncEntity<FeatureSettings>;
    analyticsEntity: AsyncEntity<AnalyticsTrackerModel[]>;
    isBrandingEnabled: boolean;
    templatesEntity: AsyncEntity<TemplateModel[]>;
    settingsEntity: AsyncEntity<ClientSettings>;
};

type DispatchProps = {
    fetchTemplates: () => void;
    fetchSettings: () => void;
    fetchFeatures: () => void;
    fetchAnalytics: () => void;
};

type AppContainerProps = StateProps & DispatchProps & { children: any };

const AdviseTheme = lazy(() => import('../styles/themes/advise-theme/advise-theme'));

// Parent component that will conditionally load the components with different styles
const ThemeSelector = ({ children, isBrandingEnabled }) => {
    return (
        <>
            <Suspense fallback={<LoadBackdrop isLoading={true} />}>
                {isBrandingEnabled && <AdviseTheme />}
            </Suspense>
            {children}
        </>
    );
};

const mapStateToProps = (state: RootState): StateProps => ({
    isLoading:
        state.auth.isLoadingUser ||
        state.features.isWorking ||
        state.rsvp.homeVisit.isWorking ||
        state.rsvp.seminar.isWorking ||
        state.template.save.isWorking ||
        state.template.currentTemplate.isWorking ||
        state.tenant.current.isWorking ||
        state.template.revert.isWorking ||
        state.template.templates.isWorking,

    templatesEntity: getTemplates(state),
    settingsEntity: getSiteSettings(state),
    featuresEntity: state.features,
    analyticsEntity: state.analytics,
    isBrandingEnabled: getBranding(state),
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    fetchTemplates: () => dispatch(fetchTemplatesAsync.request()),
    fetchSettings: () => dispatch(fetchSettingsAsync.request()),
    fetchFeatures: () => dispatch(fetchFeaturesAsync.request()),
    fetchAnalytics: () => dispatch(fetchAnalyticsAsync.request()),
});

const AppContainer = (props: AppContainerProps) => {
    const {
        isLoading,
        templatesEntity,
        settingsEntity,
        featuresEntity,
        analyticsEntity,
        isBrandingEnabled,

        fetchTemplates,
        fetchSettings,
        fetchFeatures,
        fetchAnalytics,

        children,
    } = props;
    
    useEffect(() => {
        templatesEntity.HandleStatus(AsyncStatus.Inactive, fetchTemplates);
        settingsEntity.HandleStatus(AsyncStatus.Inactive, fetchSettings);
        featuresEntity.HandleStatus(AsyncStatus.Inactive, fetchFeatures);
        analyticsEntity.HandleStatus(AsyncStatus.Inactive, fetchAnalytics);

        if (typeof settingsEntity.entityField !== 'undefined' && settingsEntity.entityField.url) {
            if (settingsEntity.entityField.url.length !== 0) {
                window.location.href = settingsEntity.entityField.url;
            }
        }

        if (isBrandingEnabled) {
            const scriptTagGlia = document.createElement('script');
            scriptTagGlia.src = 'https://api.glia.com/salemove_integration.js';
            document.body.appendChild(scriptTagGlia);

            const scriptTagAdobe = document.createElement('script');
            scriptTagAdobe.async = true;
            scriptTagAdobe.src = '//assets.adobedtm.com/2b53430d0430/cbe8c8a1d199/launch-12d178f5b708.min.js';
            document.body.appendChild(scriptTagAdobe);
        }

        const hostname = window.location.hostname.split('.')[0].toLowerCase();
        const scriptId = "massadvantageScript";
        if (hostname == "massadvantage" && document.getElementById(scriptId) === null) {
            const massAdvantageScriptTag = document.createElement('script');
            massAdvantageScriptTag.async = true;
            massAdvantageScriptTag.src = 'https://tag.simpli.fi/sifitag/4d421b20-6a80-013a-51eb-06abc14c0bc6?referer=';
            massAdvantageScriptTag.setAttribute('id', scriptId);
            document.body.appendChild(massAdvantageScriptTag);
        }
    });

    return (
        <ThemeSelector isBrandingEnabled={isBrandingEnabled}>
            <React.Fragment>
                <LoadBackdrop isLoading={isLoading} />
                <ConfirmDialog />
                <NotificationDialog />
                {featuresEntity.didSucceed && settingsEntity.didSucceed ? children : null}
            </React.Fragment>
        </ThemeSelector>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
