import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

import { setConfirmDialog } from '../actions';
import { RootState } from '../../../store/types';
import { DialogReactionModel, DialogActionModel, emptyConfirmDialog } from '../models';

type ConfirmDialogProps = {
    model: DialogActionModel;
    isOpen: boolean;

    createHandler: (action: DialogReactionModel) => () => void;
    onCancel: () => void;
};

const mapStateToProps = (state: RootState) => ({
    model: state.dialog.confirmation,
    isOpen: state.dialog.confirmation !== emptyConfirmDialog,
});

const dispatchProps = (dispatch) => ({
    onCancel: () => dispatch(setConfirmDialog(emptyConfirmDialog)),
    createHandler: (action: DialogReactionModel) => () => {
        dispatch(action.action);
        dispatch(setConfirmDialog(emptyConfirmDialog));
    },
});

const ConfirmDialog = (props: ConfirmDialogProps) => (
    <Dialog
        open={props.isOpen}
        onClose={props.onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{props.model.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {
                    props.model.bodySections.map((section, sectionIndex) => (
                        <Fragment key={sectionIndex}>
                            {section}
                            <br />
                        </Fragment>
                    ))
                }
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancel} color="primary" autoFocus>
                Cancel
            </Button>
            {
                props.model.actions.map((action, actionIndex) => (
                    <Button
                        onClick={props.createHandler(action)}
                        color="primary"
                        key={actionIndex}>
                        {action.text}
                    </Button>
                ))
            }
        </DialogActions>
    </Dialog>
);

export default connect(
    mapStateToProps,
    dispatchProps,
)(ConfirmDialog);