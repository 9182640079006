import React from 'react';

type InfoboxItemProps = {
    title?: String;
    titleDescription?: String;
    value?: any;
};

const InfoboxItem = (props: InfoboxItemProps) => {
    const { title, titleDescription, value } = props;

    return (
        <div className="infobox-item">
            <div className="infobox-item__key">
                <label>{title}</label>
            </div>
            <div className="infobox-item__value">
                <label>{value}</label>
            </div>
        </div>
    );
};

export default InfoboxItem;
