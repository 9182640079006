import React from 'react';
import { Form } from 'dynamo-models';
import FormReadonlyContainer from '../../../_shared/dynamo/components/form-readonly-container';
import { SeminarModel } from '../../../../api';

// Styles
import './seminar-stub-item.scss';

type SeminarStubProps = {
    seminar: SeminarModel;
    form: Form;
    first: Boolean;
    isBrandingEnabled: Boolean;
    onSelect: () => void;
};

function displayTime(date) {
    if (date.getHours() > 12) {
        return date.getHours() - 12 + ' pm';
    } else return date.getHours() + ' am';
}
const SeminarStub = ({ seminar, form, first, isBrandingEnabled, onSelect }: SeminarStubProps) => {
    return !isBrandingEnabled ? (
        <div onClick={onSelect} className="seminar-stub">
            <FormReadonlyContainer form={form} context={seminar} />
            <span className="seminar-stub__remaining-seats">{`Remaining seats: ${seminar.seatsAvailable}`}</span>
        </div>
    ) : (
        <div
            className={`seminar-stub ${first ? 'seminar-stub--first-of-the-month' : ''}`}
            onClick={onSelect}
        >
            <p className="seminar-stub__date">{seminar.date.getDate()}</p>
            <div className="seminar-stub__time">
                <p>{seminar.date.toLocaleString('default', { weekday: 'long' })}</p>
                <p>{displayTime(seminar.date)}</p>
            </div>
            <div className="seminar-stub__info">
                <p className="seminar-stub__description">{seminar.description}</p>
                <p className="seminar-stub__location">
                    {seminar.location}, {seminar.address.street1}, {seminar.address.city},{' '}
                    {seminar.address.state}
                </p>
            </div>
            <span className="seminar-stub__remaining-seats">
                {seminar.seatsAvailable > 0 ? `${seminar.seatsAvailable} seats left` : 'Full'}
            </span>
            <button
                onClick={onSelect}
                className="button-root button-secondary button-small seminar-stub__view-details"
            >
                View Details
            </button>
        </div>
    );
};

export default SeminarStub;
