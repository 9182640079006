import Axios from 'axios';
import { AxiosPromise } from 'axios';
import { configUrls } from './endpoints';
import { ClientSettings } from '.';
import { FeatureSettings, AnalyticsTrackerModel, FeaturesModel } from './models';

export const configApi = {
    getSettings: (): AxiosPromise<ClientSettings> => Axios.get<ClientSettings>(configUrls.getSettings),

    getGoogleCodes: (): AxiosPromise<AnalyticsTrackerModel[]> => Axios.get<AnalyticsTrackerModel[]>(configUrls.getGoogleCodes),

    getFeatureSettings: (): AxiosPromise<FeatureSettings> => Axios.get<FeaturesModel>(configUrls.getFeatures).then(response => ({
        ...response,
        data: {
            seminarsEnabled: true,
            homeVisitsEnabled: response.data.homeVisits,
            brandingEnabled: response.data.branding,
            distanceFilteringEnabled: response.data.distanceFiltering,
        },
    })),
};