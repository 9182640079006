import React from "react";

import Skeleton from '@material-ui/lab/Skeleton';
import { numToArray } from "../../util/common-util";

type FormSkeletonProps = {
    height?: number;
    count?: number;
};

export default (props: FormSkeletonProps) => {
    const {
        height,
        count,
    } = props;

    return (
        <React.Fragment>
            {
                numToArray(count ?? 1).map(i => (
                    <Skeleton key={i} animation="wave" height={height ?? 100} />
                ))
            }
        </React.Fragment>
    );
};