import React from "react";

import { Fab } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/publish";


import { useStyles } from "../../../styles";
import { DialogActionModel } from "../../dialog/models";
import { publishAsync } from "../../tenants/actions";
import { ClientSettings } from "../../../api";

type SitePublishFabProps = {
    settings?: ClientSettings;
    disabled: boolean;

    confirm: (dialog: DialogActionModel) => void;
};

const publishDialog = (settings: ClientSettings) => ({
    title: 'Publish Changes',
    bodySections: [
        'Are you sure you want to publish site changes? Changes won\'t take effect until they\'re published.',
    ],
    actions: [
        {
            text: 'Publish',
            action: publishAsync.request(settings),
        },
    ],
});

const SitePublishFab = (props: SitePublishFabProps) => {
    const {
        settings,
        disabled,

        confirm,
    } = props;

    const classes = useStyles();

    const onPublish = () => settings ? confirm(publishDialog(settings)) : {};

    return (
        <Fab
            color="primary"
            variant="extended"
            onClick={onPublish}
            disabled={disabled}
            className={classes.publishFab}
        >
            <PublishIcon style={{ paddingRight: "0.5em" }} />
            Publish
        </Fab>
    );
};

export default SitePublishFab;