import { Epic, combineEpics } from "redux-observable";

import { RootAction, RootState } from "../../store/types";
import { createPayloadFetchEpic, createNotificationEpic, createResponseSettings } from '../_shared/epic-creation';

import { searchHomeVisitsAsync } from "./actions";
import { homeVisitApi } from "../../api";

const searchHomeVisitsEpic: Epic<RootAction, RootAction, RootState> = createPayloadFetchEpic(
    searchHomeVisitsAsync,
    homeVisitApi.search,
    (state: RootState) => state.analytics.schedule,
);

const searchHomeVisitsNotificationEpic = createNotificationEpic(
    searchHomeVisitsAsync,
    createResponseSettings(true, true),
);

export default combineEpics(
    searchHomeVisitsEpic,
    searchHomeVisitsNotificationEpic,
);