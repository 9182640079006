import { Epic, combineEpics, ofType } from "redux-observable";
import * as ReactGA from "react-ga";

import { RootAction, RootState } from "../../store/types";
import { createEmptyFetchEpic } from '../_shared/epic-creation';

import { filter, tap } from 'rxjs/operators';

import { fetchAnalyticsAsync } from "./actions";
import { configApi } from "../../api";
import { AnalyticsTrackerModel } from "../../api/models";
import { LOCATION_CHANGE } from "connected-react-router";

const applyGa = (codes: AnalyticsTrackerModel[]) => ReactGA.initialize(codes.map(tracker => ({
    trackingId: tracker.code,
    gaOptions: {
        name: tracker.name,
    },
})));

const fetchAnalyticsEpic: Epic<RootAction, RootAction, RootState> = createEmptyFetchEpic(
    fetchAnalyticsAsync,
    configApi.getGoogleCodes,
    (state: RootState) => state.analytics.schedule,
    applyGa,
);

// A hack, but it works
const trackPageViewEpic: any = (action$, state) => action$.pipe(
    ofType(LOCATION_CHANGE),
    tap((action: any) => ReactGA.pageview(action.payload.location.pathname, state.value.analytics.entity?.map(v => v.name) ?? [])),
    filter(action => false),
);

export default combineEpics(fetchAnalyticsEpic, trackPageViewEpic);