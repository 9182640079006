import { combineEpics } from 'redux-observable';

import * as analyticsEpics from '../features/analytics/epics';
import * as accessEpics from '../features/access/epics';
import * as leadEpics from '../features/leads/epics';
import * as tagEpics from '../features/tags/epics';
import * as seminarEpics from '../features/seminars/epics';
import * as homeVisitEpics from '../features/home-visits/epics';
import * as localizationEpics from '../features/localization/epics';
import * as locationEpics from '../features/location/epics';
import * as rsvpEpics from '../features/rsvp/epics';
import * as tenantEpics from '../features/tenants/epics';
import * as templateEpics from '../features/template/epics';

export default combineEpics(
    ...Object.values(analyticsEpics),
    ...Object.values(accessEpics),
    ...Object.values(leadEpics),
    ...Object.values(tagEpics),
    ...Object.values(seminarEpics),
    ...Object.values(homeVisitEpics),
    ...Object.values(localizationEpics),
    ...Object.values(locationEpics),
    ...Object.values(rsvpEpics),
    ...Object.values(tenantEpics),
    ...Object.values(templateEpics),
);