import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { EditorConfigurator } from 'dynamo';
import { Form, NodePacker } from 'dynamo-models';

import Editor from '../../features/template/components/template-editor';

import Navigator from '../route-navigator';
import { FormSaveModel, TemplateModel } from '../../api';
import { RootState } from '../../store/types';
import { fetchFormAsync, fetchTemplatesAsync, saveFormAsync } from '../../features/template/actions';
import { AsyncEntity } from '../../features/_shared/action-model-async';
import { getTemplateById } from '../../features/template/selectors';

type StateProps = {
    getTemplate: (templateId: number) => AsyncEntity<TemplateModel | undefined>;
};

type DispatchProps = {
    fetchForm: (template: TemplateModel) => void;
    fetchTemplates: () => void;
    saveForm: (saveModel: FormSaveModel) => void;
    redirectToHome: () => void;
};

type RoutedEditorProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
    getTemplate: (templateId: number) => getTemplateById(state, templateId),
});

const dispatchProps = (dispatch) => ({
    fetchForm: (template: TemplateModel) => dispatch(fetchFormAsync.request(template.stagedFormId)),
    fetchTemplates: () => dispatch(fetchTemplatesAsync.request()),
    saveForm: (saveModel: FormSaveModel) => dispatch(saveFormAsync.request(saveModel)),
    redirectToHome: Navigator.toDashboard(dispatch),
});

const RoutedEditor = (props: RoutedEditorProps) => {
    const {
        getTemplate,

        saveForm,
        fetchForm,
        fetchTemplates,
        redirectToHome,
    } = props;

    const {
        templateId,
    } = useParams();

    const templateIdCast = +(templateId ?? 0);
    const templateEntity = getTemplate(templateIdCast);

    const createSubmitForm = function (config: EditorConfigurator) {
        if (templateEntity.entity) {
            saveForm({
                form: NodePacker.buildPackage(config.form),
                template: templateEntity.entity,
            });

            return redirectToHome();
        }

        return {};
    }

    useEffect(() => {
        if (!templateEntity.didSucceed && !templateEntity.isWorking && !templateEntity.entity) {
            fetchTemplates();
            return;
        }

        if (templateEntity.isWorking) {
            return;
        }

        if (!templateEntity.entity) {
            redirectToHome();
            return;
        }

        if (!templateEntity.didFail && !templateEntity.entity.stagedForm) {
            fetchForm(templateEntity.entity);
        }
    });

    const template = templateEntity.entity;

    return template?.stagedForm ? (
        <Editor
            subType={template.subType}
            form={template.stagedForm}
            saveForm={createSubmitForm}
            onExit={redirectToHome}
        />
    ) : null;
};

export default connect(
    mapStateToProps,
    dispatchProps,
)(RoutedEditor);