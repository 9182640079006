import { ActionType, createAction } from 'typesafe-actions';

import { LeadModel } from '../../api';
import { createAsyncActionPack } from '../_shared/action-creation';

export const upsertLeadAsync = createAsyncActionPack(
    'UPSERT_LEAD_REQUEST',
    'UPSERT_LEAD_SUCCESS',
    'UPSERT_LEAD_FAILURE',
    'UPSERT_LEAD_CANCEL',
)<LeadModel, LeadModel>();

export const setLead = createAction('SET_LEAD')<LeadModel>();
export const clearLead = createAction('CLEAR_LEAD')();

export type LeadAction = ActionType<typeof upsertLeadAsync |  typeof setLead | typeof clearLead>;