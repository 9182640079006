import React from 'react';

import SuccessIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { useStyles } from '../../../../styles';
import { NotificationType } from '../../../dialog/models';
import { Typography } from '@material-ui/core';

type BannerProps = {
    severity: NotificationType;
    message?: string;
};

const Banner = (props: BannerProps) => {
    const {
        severity,
        message,
    } = props;

    const {
        successBanner,
        errorBanner,
        warningBanner,
        infoBanner,
        bannerIcon,
        bannerMessage,
    } = useStyles();

    const className = (
        severity === NotificationType.Success
        ? successBanner :
        severity === NotificationType.Error
        ? errorBanner :
        severity === NotificationType.Warning
        ? warningBanner :
        infoBanner
    );

    return (
        <div className={className} role="alert">
            <div className={bannerIcon}>
            {
                severity === NotificationType.Success
                ? <SuccessIcon /> :
                severity === NotificationType.Error
                ? <ErrorIcon /> :
                severity === NotificationType.Warning
                ? <WarningIcon /> :
                <InfoIcon />
            }
            </div>
            <Typography className={bannerMessage}>
                {message ?? 'An error has occurred'}
            </Typography>
        </div>
    );
};

export default Banner;