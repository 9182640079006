import Axios from "axios";

const seminarBaseUrl = '/seminar';
const homeVisitBaseUrl = '/homevisit';
const formBaseUrl = '/forms';
const locationBaseUrl = '/location';
const leadBaseUrl = '/lead';
const adminBaseUrl = '/admin';
const configBaseUrl = '/home';

Axios.defaults.baseURL = window.location.origin;

const urlMapper = (baseUrl: string, endpoints: any) => Object.keys(endpoints).reduce((result, key) => {
    result[key] = `${baseUrl}\/${endpoints[key]}`;
    return result;
}, endpoints);

const seminarEndpoints = {
    getSeminar: 'seminar',
    getBy: 'seminars',
    getTags: 'tags',
};

const homeVisitEndpoints = {
    search: 'search',
    getLanguages: 'languages',
};

const formEndpoints = {
    getForm: '',
};

const locationEndpoints = {
    getCounties: 'counties',
    getStates: 'states',
};

const leadEndpoints = {
    rsvpHomeVisit: 'rsvphomevisit',
    rsvpSeminar: 'rsvpseminar',
    create: 'createlead',
    getRsvp: 'getrsvp',
    cancelRsvp: 'cancelrsvp',
};

const adminEndpoints = {
    getTenants: 'gettenants',
    copySite: 'copysite',
    publishSite: 'publishsite',
    revertTemplate: 'reverttemplate',
    publishTemplate: 'publishtemplate',
    saveForm: 'saveform',
    upsertTemplate: 'upserttemplate',
    getForm: 'getform',
    getTemplates: 'templates',
};

const configEndpoints = {
    getSettings: 'getsettings',
    getGoogleCodes: 'googlecodes',
    getFeatures: 'features',
};

export const seminarUrls = urlMapper(seminarBaseUrl, seminarEndpoints);
export const homeVisitUrls = urlMapper(homeVisitBaseUrl, homeVisitEndpoints);
export const formUrls = urlMapper(formBaseUrl, formEndpoints);
export const locationUrls = urlMapper(locationBaseUrl, locationEndpoints);
export const leadUrls = urlMapper(leadBaseUrl, leadEndpoints);
export const adminUrls = urlMapper(adminBaseUrl, adminEndpoints);
export const configUrls = urlMapper(configBaseUrl, configEndpoints);