import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TagReadonlyList from '../../features/tags/components/tag-readonly-list';

import { RootState } from '../../store/types';
import { useStyles } from '../../styles';

import LeadInfo from '../../features/leads/components/lead-info-container/lead-info-container';
import FormReadonlyContainer from '../../features/_shared/dynamo/components/form-readonly-container';
import Banner from '../../features/_shared/util/components/banner';

import { RsvpModel, SeminarModel, TemplateModel } from '../../api/models';
import { AsyncEntity } from '../../features/_shared/action-model-async';
import { fetchSeminarAsync } from '../../features/seminars/actions';
import { fetchRsvpAsync, cancelRsvpAsync } from '../../features/rsvp/actions';
import { useParams } from 'react-router';
import { NotificationType } from '../../features/dialog/models';
import { getTemplateForCurrentSeminar } from '../../features/template/selectors';
import { fetchFormAsync } from '../../features/template/actions';

type RoutedPasscodeProps = {
    seminarEntity: AsyncEntity<SeminarModel, number>;
    templateEntity: AsyncEntity<TemplateModel | undefined>;
    rsvpEntity: AsyncEntity<RsvpModel, string>;

    fetchSeminar: (id: number) => void;
    fetchForm: (tempalte: TemplateModel) => void;
    fetchRsvp: (passcode: string) => void;
    cancelRsvp: (passcode: string) => void;
};

const mapStateToProps = (state: RootState) => ({
    seminarEntity: state.seminars.current,
    templateEntity: getTemplateForCurrentSeminar(state),
    rsvpEntity: state.rsvp.current,
});

const mapDispatchToProps = (dispatch) => ({
    fetchSeminar: (seminarId: number) => dispatch(fetchSeminarAsync.request(seminarId)),
    fetchForm: (template: TemplateModel) =>
        dispatch(fetchFormAsync.request(template.publishedFormId)),
    fetchRsvp: (passcode: string) => dispatch(fetchRsvpAsync.request(passcode)),
    cancelRsvp: (passcode: string) => dispatch(cancelRsvpAsync.request(passcode)),
});

const RoutedPasscode = (props: RoutedPasscodeProps) => {
    const {
        seminarEntity,
        templateEntity,
        rsvpEntity,

        fetchSeminar,
        fetchForm,
        fetchRsvp,
    } = props;

    const { passcode } = useParams();

    if (!passcode) {
        return null;
    }

    const hasRsvpFetchLoaded = rsvpEntity.didSucceed && rsvpEntity.inputs === passcode;

    useEffect(() => {
        let seminarId: number | undefined;

        if (!(rsvpEntity.isWorking || hasRsvpFetchLoaded)) {
            fetchRsvp(passcode);
        } else if (hasRsvpFetchLoaded) {
            seminarId = rsvpEntity.entity?.seminar;
        }

        if (
            seminarId &&
            !seminarEntity.isWorking &&
            seminarEntity.entity?.appointmentId !== seminarId
        ) {
            fetchSeminar(seminarId);
        }

        if (!templateEntity.isWorking && templateEntity.entity) {
            fetchForm(templateEntity.entity);
        }
    });

    // WARNING! DO NOT PUT INSIDE THE IF! React unironically BREAKS if you do that!
    const classes = useStyles();

    if (
        seminarEntity.entity &&
        templateEntity.entity?.publishedForm &&
        rsvpEntity.entity &&
        rsvpEntity.entity.lead
    ) {
        return (
            <div className={classes.seminarsContainer}>
                <Paper className={classes.seminarDetailsContent} id="seminarDetails" elevation={3}>
                    <div id="seminarInternals" className={classes.seminarDetailsContentInner}>
                        <h2>Your Info</h2>
                        <LeadInfo />
                        <Button
                            style={{
                                marginTop: 'calc(1em / 3)',
                                marginBottom: '2em',
                                maxWidth: '10em',
                            }}
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                props.cancelRsvp(passcode);
                            }}
                        >
                            Cancel RSVP
                        </Button>
                        <h2>Seminar</h2>
                        <FormReadonlyContainer
                            form={templateEntity.entity.publishedForm}
                            context={seminarEntity.entity}
                        />
                        <p style={{ marginTop: '1em' }}>Tags</p>
                        <TagReadonlyList tags={seminarEntity.entity.tags} />
                    </div>
                </Paper>
            </div>
        );
    } else if (!seminarEntity.entity && templateEntity.entity && !rsvpEntity.entity) {
        return <Banner severity={NotificationType.Error} message="This RSVP has been cancelled." />;
    } else {
        return null;
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutedPasscode);
