import React from 'react';

import PaginatorContainer from './paginator-container/paginator-container';

import { AsyncEntity } from '../../action-model-async';

type LoadPaginatorProps = {
    pagesEntity: AsyncEntity<number, any>;
    currentPage?: number;

    setPage: (page: number) => void;
};

const LoadedPaginator = ({ pagesEntity, currentPage, setPage }: LoadPaginatorProps) => pagesEntity.entity && pagesEntity.entity > 1 ? (
    <PaginatorContainer pages={pagesEntity.entity} currentPage={currentPage ?? 1} setPage={setPage} />
) : null;

export default LoadedPaginator;