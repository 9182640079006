import React from "react";

import { Paper } from "@material-ui/core";

import {
    FormWidget, EditorToolboxWidget, EditorContainer,
    EditorSpacingWidget, EditorModeWidget, EditorActionsWidget, EditorSaveWidget,
    EditorTabsWidget, EditorPropertiesWidget, EditorStylingWidget, EditorConfigurator,
    ImageDashboardWidget,
} from "dynamo";

import { FormSubTypes } from "../../../api";
import { Form } from "dynamo-models";
import { createConfigurator } from "../../_shared/util/form-util";

type EditorProps = {
    form: Form;
    subType: FormSubTypes;

    saveForm: (configurator: EditorConfigurator) => void;
    onExit: () => void;
};

const Editor = (props: EditorProps) => {
    const {
        form,
        subType,

        saveForm,
        onExit,
    } = props;

    const configurator = createConfigurator(subType, form);
    const submitForm = () => saveForm(configurator);

    return (
        <div>
            <EditorContainer container={configurator.generateToolboxProps().nodes} defaultSpacing={10}>
                <ImageDashboardWidget endpoint="/admin/uploadimage" />

                <div className="editorBanner">
                    The Editor is currently in Beta. Improvements are currently being made.
                </div>

                <div id="hholder" style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "stretch",
                    alignItems: "stretch",
                    marginLeft: "1em",
                    marginRight: "1em",
                    marginTop: "1em",
                    height: "calc(100vh - 7em)",
                }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Paper style={{ marginBottom: "1em" }}>
                            <EditorActionsWidget {...configurator.generateToolboxProps()} />
                        </Paper>
                        <Paper style={{ overflowY: "auto" }}>
                            <EditorTabsWidget>
                                <EditorToolboxWidget {...configurator.generateToolboxProps()} />
                                <EditorPropertiesWidget />
                                <EditorStylingWidget maxHeight={100} />
                            </EditorTabsWidget>
                        </Paper>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <Paper style={{ display: "flex", paddingRight: "1em" }}>
                                <EditorModeWidget style={{ alignSelf: "center" }} />
                            </Paper>
                            <Paper style={{ paddingLeft: "1em", paddingRight: "1em" }}>
                                <EditorSpacingWidget style={{ alignSelf: "center" }} />
                            </Paper>
                            <Paper style={{ display: "flex", paddingLeft: "1em", paddingRight: "1em" }}>
                                <EditorSaveWidget onSave={submitForm} onExit={onExit} />
                            </Paper>
                        </div>
                        <div style={{ overflowY: "auto", marginTop: "1em" }}>
                            <FormWidget {...configurator.generatePrimaryProps()} />
                        </div>
                    </div>
                </div>
            </EditorContainer>
        </div>
    );
};

export default Editor;
