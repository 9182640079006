import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '../../../features/_shared/Button/Button';
import { Form } from 'dynamo-models';

import FormReadonlyContainer from '../../../features/_shared/dynamo/components/form-readonly-container';
import FormSkeleton from '../../../features/_shared/dynamo/components/form-readonly-skeleton';
import SeminarsContainer from '../../../features/seminars/components/seminar-container/seminar-container';

import { ClientSettings, TemplateModel } from '../../../api';
import { fetchFormAsync } from '../../../features/template/actions';
import { getSiteSettings, getBranding } from '../../../features/tenants/selectors';

import { RootState } from '../../../store/types';
import { AsyncEntity } from '../../../features/_shared/action-model-async';
import Navigator from '../../route-navigator';

// Styles
import './routed-event.scss';

type StateProps = {
    settingsEntity: AsyncEntity<ClientSettings>;
    primaryFormEntity: AsyncEntity<Form | undefined>;
    areHomeVisitsEnabled: boolean;
    isBrandingEnabled: boolean;
};

type DispatchProps = {
    fetchForm: (template: TemplateModel) => void;
    openHomeVisitScheduler: () => void;
};

type EventContainerProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
    settingsEntity: getSiteSettings(state),
    primaryFormEntity: state.tenant.current.Bind((settings) => settings.main.publishedForm),
    areHomeVisitsEnabled: state.features.entity?.homeVisitsEnabled ?? false,
    isBrandingEnabled: getBranding(state),
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    fetchForm: (template: TemplateModel) =>
        dispatch(fetchFormAsync.request(template.publishedFormId)),
    openHomeVisitScheduler: Navigator.toHomeVisitScheduler(dispatch),
});

const RoutedEvent = (props: EventContainerProps) => {
    const {
        settingsEntity,
        primaryFormEntity,
        areHomeVisitsEnabled,
        isBrandingEnabled,

        fetchForm,
        openHomeVisitScheduler,
    } = props;

    useEffect(() => {
        if (
            settingsEntity.entity?.main &&
            settingsEntity.entity.main.publishedFormId !== 0 &&
            !settingsEntity.isWorking &&
            !settingsEntity.entity.main.publishedForm
        ) {
            fetchForm(settingsEntity.entity.main);
        }
    });

    return (
        <div>
            {isBrandingEnabled ? (
                <div>
                    <div className="landing-hero">
                        <div className="client-view">
                            <div className="landing-hero__title">
                                Learn more about Medicare.
                                <br></br>
                                <strong>Join us for a seminar.</strong>
                            </div>
                            <div className="landing-hero__image"></div>
                        </div>
                    </div>
                    <div className="client-view">
                        <SeminarsContainer />
                    </div>
                </div>
            ) : (
                <div className="client-view">
                    {primaryFormEntity.entity !== undefined && primaryFormEntity.didSucceed ? (
                        <FormReadonlyContainer form={primaryFormEntity.entity} />
                    ) : primaryFormEntity.isWorking ? (
                        <FormSkeleton height={300} />
                    ) : null}
                    {areHomeVisitsEnabled ? (
                        <Button
                            text="Schedule Home Visit"
                            className="schedule-visit-button"
                            onClick={openHomeVisitScheduler}
                            size="large"
                            theme="primary"
                        />
                    ) : null}
                    <SeminarsContainer />
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutedEvent);
