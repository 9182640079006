import React from 'react';
import { connect } from 'react-redux';

import Button from '../../_shared/Button/Button';
import ToastNotification from '../../_shared/ToastNotification/ToastNotification';

import { NotificationActionModel, emptyNotification, NotificationType } from '../models';
import { RootState } from '../../../store/types';
import { setNotification } from '../actions';
import { Action } from 'typesafe-actions';
import { combineThunks } from '../../_shared/util/common-util';

type StateProps = {
    notification: NotificationActionModel;
};

type DispatchProps = {
    clear: () => void;
    dispatcher: (action?: Action<any>) => (() => void) | undefined;
};

type NotificationProps = {
    type: NotificationType;
    message: string;

    clear: () => void;
    retry?: () => void;
};

const mapStateToProps = (state: RootState): StateProps => ({
    notification: state.dialog.notification,
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    clear: () => dispatch(setNotification(emptyNotification)),
    dispatcher: (action?: Action<any>) => (action ? () => dispatch(action) : undefined),
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => ({
    ...stateProps.notification,
    clear: dispatchProps.clear,
    retry: dispatchProps.dispatcher(stateProps.notification.retry),
});

const createTypeProps = (
    severity: 'error' | 'success' | 'warning' | 'info' | undefined,
    autoHideDuration: number
) => ({
    severity,
    autoHideDuration,
});

const getTypeProps = (type: NotificationType) => {
    switch (type) {
        case NotificationType.Error:
            return createTypeProps(type, 100000);

        case NotificationType.Success:
            return createTypeProps(type, 6000);

        case NotificationType.Info:
            return createTypeProps(type, 6000);

        case NotificationType.Warning:
            return createTypeProps(type, 6000);

        default:
            return createTypeProps(undefined, 6000);
    }
};

const Retry = (props: NotificationProps) => {
    const { clear, retry } = props;

    if (retry === undefined) {
        return null;
    }

    const retryAndClear = combineThunks(retry, clear);

    return (
        <Button
            text="Retry"
            theme="secondary"
            className="button-margin-left"
            onClick={retryAndClear}
        />
    );
};

const Notification = (props: NotificationProps) => {
    const { type, message, clear } = props;

    const { severity, autoHideDuration } = getTypeProps(type);

    return (
        <ToastNotification
            autoHideDuration={autoHideDuration}
            open={type !== NotificationType.None}
            onClose={clear}
            theme={severity}
            text={message}
        >
            <Retry {...props} />
        </ToastNotification>
    );
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Notification);
