import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AsyncJob, AsyncState, AsyncEntity } from "../_shared/action-model-async";

import {
    saveFormAsync, fetchFormAsync,
    ManageAction, fetchTemplatesAsync, upsertTemplateAsync, onSelectTemplate, onTemplateNameChange, revertTemplateAsync, publishTemplateAsync,
} from './actions';
import { Form } from 'dynamo-models';
import { TemplateModel } from '../../api';

const mixTemplateForm = (templates: TemplateModel[], form: Form) => templates.map(template => (
    template.publishedFormId === form.id && template.stagedFormId === form.id ? {
        ...template,
        publishedForm: form,
        stagedForm: form,
    }
    : template.publishedFormId === form.id ? {
        ...template,
        publishedForm: form,
    }
    : template.stagedFormId === form.id ? {
        ...template,
        stagedForm: form,
    }
    : template
));

const fetchReducer = createReducer<AsyncEntity<TemplateModel[]>, ManageAction>(AsyncEntity.Hold())
    .handleAction(fetchFormAsync.request, (state, action) => state.ApplyPend(action.payload, true))
    .handleAction(fetchFormAsync.success, (state, action) => state.ApplyPut(mixTemplateForm(state.entity ?? [], action.payload)))
    .handleAction(fetchFormAsync.failure, (state, action) => state.ApplyError(action.payload ?? 'An error occurred while loading your template.'))
    .handleAction(fetchFormAsync.cancel, (state, action) => state.ApplyAbort(false))
    .handleAction(fetchTemplatesAsync.request, (state, action) => state.ApplyPend())
    .handleAction(fetchTemplatesAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(fetchTemplatesAsync.failure, (state, action) => state.ApplyError(action.payload ?? 'An error occurred while loading your templates.'))
    .handleAction(fetchTemplatesAsync.cancel, (state, action) => state.ApplyAbort(false));

const revertTemplateReducer = createReducer<AsyncJob, ManageAction>(AsyncJob.Reserve())
    .handleAction(revertTemplateAsync.request, (state, action) => state.Transition(AsyncState.Work()))
    .handleAction(revertTemplateAsync.success, (state, action) => state.Transition(AsyncState.Complete('Template has been reverted.')))
    .handleAction(revertTemplateAsync.failure, (state, action) => state.Transition(AsyncState.Fail('An error occurred while reverting your template changes. Please try again.')))
    .handleAction(revertTemplateAsync.cancel, (state, action) => state.Transition(AsyncState.Cancel()));

const publishTemplateReducer = createReducer<AsyncJob, ManageAction>(AsyncJob.Reserve())
    .handleAction(publishTemplateAsync.request, (state, action) => state.Transition(AsyncState.Work()))
    .handleAction(publishTemplateAsync.success, (state, action) => state.Transition(AsyncState.Complete('Template has been published.')))
    .handleAction(publishTemplateAsync.failure, (state, action) => state.Transition(AsyncState.Fail('An error occurred while publishing your template changes. Please try again.')))
    .handleAction(publishTemplateAsync.cancel, (state, action) => state.Transition(AsyncState.Cancel()));

const saveFormReducer = createReducer<AsyncJob, ManageAction>(AsyncJob.Reserve())
    .handleAction(saveFormAsync.request, (state, action) => state.Transition(AsyncState.Work()))
    .handleAction(saveFormAsync.success, (state, action) => state.Transition(AsyncState.Complete('Template has been saved.')))
    .handleAction(saveFormAsync.failure, (state, action) => state.Transition(AsyncState.Fail('An error occurred while saving your template changes. Please try again.')))
    .handleAction(saveFormAsync.cancel, (state, action) => state.Transition(AsyncState.Cancel()));

const templateReducer = createReducer<AsyncEntity<TemplateModel, TemplateModel | undefined>, ManageAction>(AsyncEntity.Allocate<TemplateModel, TemplateModel | undefined>(undefined))
    .handleAction(upsertTemplateAsync.request, (state, action) => state.ApplyPend(action.payload))
    .handleAction(upsertTemplateAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(upsertTemplateAsync.failure, (state, action) => state.ApplyError(action.payload ?? 'An error occurred creating your template.'))
    .handleAction(upsertTemplateAsync.cancel, (state, action) => state.ApplyAbort(false))
    .handleAction(onSelectTemplate, (state, action) => AsyncEntity.Allocate<TemplateModel, TemplateModel | undefined>(action.payload))
    .handleAction(onTemplateNameChange, (state, action) => AsyncEntity.Allocate<TemplateModel, TemplateModel | undefined>(state.inputs ? {
        ...state.inputs,
        name: action.payload,
    } : state.inputs));

export default combineReducers({
    save: saveFormReducer,
    revert: revertTemplateReducer,
    publish: publishTemplateReducer,
    templates: fetchReducer,
    currentTemplate: templateReducer,
});
