import Axios, { AxiosPromise } from 'axios';

import { locationUrls } from './endpoints';
import { createConfig } from './util';
import { StateModel } from './models';

export const locationApi = {
    getCounties: (zipCode: string): AxiosPromise<string[]> => Axios.get<string[]>(locationUrls.getCounties, createConfig({
        zipCode,
    })),

    getStates: (): AxiosPromise<StateModel[]> => Axios.get<StateModel[]>(locationUrls.getStates),
};