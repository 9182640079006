import React from 'react';

//  Style
import './Checkbox.scss';
import cx from 'classnames';

type CheckboxProps = {
    className?: string;
    classNameContainer?: string;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    id?: string;
    name?: string;
    onChange?: () => void;
    text?: string;
    value?: boolean;
};

export const Checkbox = ({
    className,
    classNameContainer,
    error,
    id,
    helperText,
    name,
    onChange,
    text,
    value,
}: CheckboxProps) => {
    return (
        <div className={cx('checkbox-field-container', classNameContainer)}>
            <label className="checkbox__container" id={name}>
                <input
                    checked={value}
                    className="input-cb"
                    id={id}
                    name={name!}
                    onChange={onChange}
                    type="checkbox"
                />
                <div
                    className={cx('checkmark', className)}
                    role="checkbox"
                    aria-labelledby={name}
                />
                <span className="checkbox__text">{text}</span>
            </label>

            {error && <span className="checkbox__helper-error">{helperText}</span>}
        </div>
    );
};

export default Checkbox;
