import { Epic, combineEpics } from "redux-observable";

import { RootAction, RootState } from "../../store/types";
import { createEmptyFetchEpic, createPayloadFetchEpic, createNotificationEpic, createResponseSettings } from '../_shared/epic-creation';

import { fetchStatesAsync, fetchCountiesAsync } from "./actions";
import { locationApi } from "../../api";

const fetchStatesEpic: Epic<RootAction, RootAction, RootState> = createEmptyFetchEpic(
    fetchStatesAsync,
    locationApi.getStates,
    (state: RootState) => state.analytics.schedule,
);

const fetchStatesNotificationEpic = createNotificationEpic(
    fetchStatesAsync,
    createResponseSettings(true, true),
);

const fetchCountiesEpic: Epic<RootAction, RootAction, RootState> = createPayloadFetchEpic(
    fetchCountiesAsync,
    locationApi.getCounties,
    (state: RootState) => state.analytics.schedule,
);

const fetchCountiesNotificationEpic = createNotificationEpic(
    fetchCountiesAsync,
    createResponseSettings(true, true),
);

export default combineEpics(
    fetchStatesEpic,
    fetchCountiesEpic,
    fetchStatesNotificationEpic,
    fetchCountiesNotificationEpic,
);