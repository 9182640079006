import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

import { RootAction, RootState } from './types';

import rootReducer from './rootReducer';
import rootEpic from './rootEpic';
import createOidcMiddleware, { loadUser } from 'redux-oidc';
import userManager from '../utils/userManager';
import Axios from 'axios';

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState>();
const oidcMiddleware = createOidcMiddleware(userManager);
const routerMiddleware = createRouterMiddleware(history);
const axiosAuthMiddleware = (store: any) => (next: any) => (action: any) => {
    const token = store.getState().auth.user?.access_token;
    Axios.defaults.headers['Authorization'] = token !== undefined ? token : undefined;

    next(action);
};

const middlewares = [routerMiddleware, oidcMiddleware, epicMiddleware, axiosAuthMiddleware];
const composeEnhancer = composeWithDevTools({})(applyMiddleware(...middlewares));

const initialState = {};

const store = createStore(rootReducer(history), initialState, composeEnhancer);

loadUser(store, userManager);

epicMiddleware.run(rootEpic);

export default store;