import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles, createStyles } from '@material-ui/core';

const commonBanner: CSSProperties = {
    fontWeight: 400,
    fontSize: '0.875rem',
    display: 'flex',
    padding: '0.5em',
    borderRadius: '4px',
};

const commonNotice: CSSProperties = {
    color: '#fff',
    fontWeight: 500,
    display: 'flex',
    padding: '0.2em 0.5em',
    borderRadius: '4px',
};

const styles = createStyles({
    /*** COMMON STYLES ***/
    squaredIconButton: {
        borderRadius: 0,
        justifyContent: 'space-between',
    },

    spacedIconButtonLabel: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    overflowButtonLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    /*** BANNER STYLES ***/
    successBanner: {
        ...commonBanner,
        backgroundColor: 'rgb(237, 247, 237)',
        color: 'rgb(76, 175, 80)',
    },

    errorBanner: {
        ...commonBanner,
        backgroundColor: 'rgb(253, 236, 234)',
        color: 'rgb(244, 67, 54)',
    },

    warningBanner: {
        ...commonBanner,
        backgroundColor: 'rgb(255, 244, 229)',
        color: 'rgb(255, 152, 0);',
    },

    infoBanner: {
        ...commonBanner,
        backgroundColor: 'rgb(232, 244, 253)',
        color: 'rgb(33, 150, 243)',
    },

    bannerMessage: {
        marginTop: '0.3em',
        color: 'rgb(97, 26, 21)',
    },

    bannerIcon: {
        marginTop: '0.3em',
        marginRight: '0.5em',
    },

    /*** NOTIFICATION STYLES ***/
    successNotice: {
        ...commonNotice,
        backgroundColor: '#4caf50',
    },

    errorNotice: {
        ...commonNotice,
        backgroundColor: '#f44336',
    },

    warningNotice: {
        ...commonNotice,
        backgroundColor: '#ff9800',
    },

    infoNotice: {
        ...commonNotice,
        backgroundColor: '#2196f3',
    },

    noticeMessage: {
        marginTop: '0.3em',
    },

    noticeIcon: {
        marginTop: '0.3em',
        marginRight: '0.5em',
    },

    noticeClose: {
        marginLeft: '0.5em',
    },

    /*** SEMINAR STYLES ***/
    seminarsContainer: {
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: '#f5f5f5AA',
        width: '100%',
        height: '100%',
    },

    seminarDetailsContent: {
        display: 'flex',
        flexDirection: 'column',
        margin: '1em',
        width: '80%',
        maxWidth : '80%'
    },

    seminarDetailsContentInner: {
        overflowY: 'auto',
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '0.5em',
    },

    /*** TEMPLATE STYLES ***/
    templateCollection: {
        display: 'flex',
    },

    templateCard: {
        margin: '1em',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '-webkit-fill-available',
    },

    templateCardContent: {
        background: '#f7f7f7',
        flexGrow: 1,
    },

    templateCardActions: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        padding: '0px',
    },

    templateUndoAction: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
    },

    publishFab: {
        display: 'flex',
        alignSelf: 'center',
    },

    templateDataContainer: {
        display: 'flex',
        flexDirection: 'row',
    },

    templateDataList: {
        display: 'flex',
        flexDirection: 'column',
        margin: '1em',
        maxHeight: '35em',
    },

    templateDataListHeaderDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    templateDataItem: {
        background: 'white',
        margin: '0.5em',
    },

    templateDefaultDataItem: {
        background: 'gainsboro',
        margin: '0.5em',
        marginBottom: '2em',
    },

    templateDataItemActions: {
        display: 'flex',
        flexDirection: 'row',
    },

    /*** TENANT STYLES ***/
    tenantList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        maxHeight: '35em',
        overflowY: 'auto',
    },

    tenantItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '1em',
        textAlign: 'center',
        width: '20em',
        height: '15em',
    },

    tenantTagContainer: {
        display: 'flex',
        overflowY: 'auto',
        flexGrow: 1,
        flexFlow: 'wrap',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        backgroundColor: '#f7f7f7',
    },

    emptyTenantContainer: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        marginTop: '0.6em',
    },

    tenantTagItem: {
        display: 'flex',
        marginTop: '0.6em',
        marginBottom: '0.6em',
        marginRight: '0.3em',
        marginLeft: '0.3em',
        justifyContent: 'space-evenly',
        flexGrow: 1,
    },

    /*** CONTAINER STYLES ***/
    adminContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    adminSection: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1em',
    },
});

export default makeStyles(styles);
