import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import SeminarDetails from '../../features/seminars/components/seminar-details/seminar-details';

import Navigator from '../route-navigator';
import { RootState } from '../../store/types';
import { fetchSeminarAsync } from '../../features/seminars/actions';
import { rsvpSeminarAsync } from '../../features/rsvp/actions';
import { AsyncEntity, AsyncJob } from '../../features/_shared/action-model-async';
import { SeminarModel, LeadModel, ClientSettings, TemplateModel } from '../../api';
import { fetchFormAsync } from '../../features/template/actions';
import { getTemplateForCurrentSeminar } from '../../features/template/selectors';
import { getCurrentSeminar } from '../../features/seminars/selectors';
import { getSiteSettings } from '../../features/tenants/selectors';
import LoadBackdrop from '../../features/_shared/load/components/load-backdrop';

type StateProps = {
    isLoading: boolean;
    settingsEntity: AsyncEntity<ClientSettings, ClientSettings | undefined>;
    seminar: AsyncEntity<SeminarModel, number>;
    templateEntity: AsyncEntity<TemplateModel | undefined>;
    rsvpJob: AsyncJob;
};

type DispatchProps = {
    fetchSeminar: (id: number) => void;
    fetchForm: (template: TemplateModel) => void;
    rsvp: (lead: LeadModel, seminarId: number) => void;
    redirect: () => void;
};

type RoutedSeminarDetailsProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
    settingsEntity: getSiteSettings(state),
    seminar: getCurrentSeminar(state),
    templateEntity: getTemplateForCurrentSeminar(state),
    rsvpJob: state.rsvp.seminar,
    isLoading: state.seminars.current.isWorking,
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    fetchSeminar: (id: number) => dispatch(fetchSeminarAsync.request(id)),
    fetchForm: (template: TemplateModel) =>
        dispatch(fetchFormAsync.request(template.publishedFormId)),
    rsvp: (lead: LeadModel, seminarId: number) =>
        dispatch(
            rsvpSeminarAsync.request({
                seminarId,
                lead,
            })
        ),
    redirect: Navigator.toEvents(dispatch),
});

const RoutedSeminarDetails = (props: RoutedSeminarDetailsProps) => {
    const { seminarId } = useParams();

    const {
        isLoading,
        seminar,
        templateEntity,
        rsvpJob,

        rsvp,
        fetchSeminar,
        fetchForm,
        redirect,
    } = props;

    const onRsvpSubmit = (lead: LeadModel) => rsvp(lead, +(seminarId ?? 0));

    useEffect(() => {
        if (!seminarId || +seminarId === 0) {
            redirect();
            return;
        }

        if (!seminar.isWorking && seminar.entity?.appointmentId !== +seminarId) {
            fetchSeminar(+seminarId);
            return;
        }

        if (seminar.isWorking) {
            return;
        }

        if (
            !templateEntity.isWorking &&
            templateEntity.entity &&
            !templateEntity.entity.publishedForm
        ) {
            fetchForm(templateEntity.entity);
        }
    });

    return seminar.didSucceed && seminar.entity && templateEntity.entity?.publishedForm ? (
        <SeminarDetails
            seminar={seminar.entity}
            detailsForm={templateEntity.entity.publishedForm}
            rsvpJob={rsvpJob}
            onRsvpSubmit={onRsvpSubmit}
            onRsvpSuccess={redirect}
            onCancel={redirect}
        />
    ) : (
        <LoadBackdrop isLoading={isLoading} />
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutedSeminarDetails);
