import React from 'react';

import Button from '../../../../_shared/Button/Button';

// Styles
import './paginator-button.scss';

type PaginatorButtonProps = {
    buttonPage: number;
    currentPage: number;
    onSelect: () => void;
};

const PaginatorButton = ({ buttonPage, currentPage, onSelect }: PaginatorButtonProps) => (
    <Button
        text={`${buttonPage}`}
        theme="link"
        className={buttonPage === currentPage ? 'current-page-button' : 'page-button'}
        size="auto"
        disabled={buttonPage === currentPage}
        onClick={onSelect}
    />
);

export default PaginatorButton;
