import React from 'react';

import ReadonlyTag from './tag-readonly-item';

import { TagModel } from '../../../api';

type TagReadonlyListProps = {
    tags: TagModel[];
    hideEmptyText?: boolean;
    itemClass?: string;
    listClass?: string;
    emptyListClass?: string;
};

export default ({ tags, hideEmptyText, itemClass, listClass }: TagReadonlyListProps) => (
    <div className={listClass}>
        {tags.length !== 0 ? (
            tags.map((t) => <ReadonlyTag tag={t} className={itemClass} key={t.tagId} />)
        ) : hideEmptyText !== true ? (
            <span className="no-tag">No tags</span>
        ) : null}
    </div>
);
