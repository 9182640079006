import React, { Fragment } from 'react';

import TenantItem from './tenant-item';
import TenantItemSkeleton from './tenant-item-skeleton';

import { copyTenantAsync } from '../actions';

import { TenantTupleModel } from '../../../api';
import { AsyncEntity } from '../../_shared/action-model-async';
import { useStyles } from '../../../styles';
import { DialogActionModel } from '../../dialog/models';

type TenantListProps = {
    tenantsEntity: AsyncEntity<TenantTupleModel[]>;

    onOpenConfirmDialog: (dialog: DialogActionModel) => void;
};

const createConfirmCopyTenantDialog = (uri: string) => ({
    title: 'Copy Site Layout',
    bodySections: [
        'Are you sure you want to copy the layout of the selected site?',
        "Note: Leads won't see this change until you click 'Publish' at the top of the page.",
    ],
    actions: [
        {
            text: 'Confirm',
            action: copyTenantAsync.request(uri),
        },
    ],
});

const TenantList = ({ tenantsEntity, onOpenConfirmDialog }: TenantListProps) => {
    const onCopyTenant = (uri: string) => onOpenConfirmDialog(createConfirmCopyTenantDialog(uri));
    const onOpenTenant = (uri: string) => (window.location.href = `https://${uri}/admin`);

    const classes = useStyles();

    return tenantsEntity.didSucceed && tenantsEntity.entity ? (
        <div className={classes.tenantList}>
            {tenantsEntity.entity.map((tenant) => (
                <TenantItem
                    tenant={tenant}
                    onCopy={() => onCopyTenant(tenant.url)}
                    onOpen={() => onOpenTenant(tenant.url)}
                    key={tenant.url}
                />
            ))}
        </div>
    ) : tenantsEntity.isWorking ? (
        <div className={classes.tenantList}>
            <TenantItemSkeleton />
            <TenantItemSkeleton />
            <TenantItemSkeleton />
        </div>
    ) : (
        <Fragment></Fragment>
    );
};

export default TenantList;
