import React from 'react';

import { Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import Button from '../../../_shared/Button/Button';
import { LeadModel } from '../../../../api';

//  Style
import './lead-confirm.scss';

type LeadConfirmProps = {
    isOpen: boolean;
    lead: LeadModel;

    confirm: () => void;
    deny: () => void;
};

export default (props: LeadConfirmProps) => {
    const {
        isOpen,
        lead,

        confirm,
        deny,
    } = props;

    return (
        <Dialog
            open={isOpen}
            classes={{
                paper: 'lead-confirm-modal',
            }}
        >
            <DialogTitle className="modal-title">
                Are you {lead.firstName} {lead.lastName}?
            </DialogTitle>
            <DialogActions className="modal__actions">
                <Button onClick={confirm} theme="primary" size="small" text="Yes" />
                <Button
                    className="modal-secondary-button"
                    onClick={deny}
                    theme="secondary"
                    size="small"
                    text="No"
                />
            </DialogActions>
        </Dialog>
    );
};
