import { Epic, combineEpics } from "redux-observable";
import { isActionOf, Action } from 'typesafe-actions';
import { AxiosPromise } from "axios";

import { from, of } from 'rxjs';
import { filter, mergeMap, switchMap, map, catchError, delay, timeoutWith, concat, combineAll, partition } from 'rxjs/operators';

import { RootState } from "../../store/types";

import { rsvpSeminarAsync, rsvpHomeVisitAsync, fetchRsvpAsync, cancelRsvpAsync } from "./actions";
import { leadApi, LeadModel } from "../../api";
import { LeadActionModel, SeminarRsvpActionModel, HomeVisitRsvpActionModel } from "../_shared/action-models";
import { AsyncSchedule } from "../_shared/action-model-async";
import { AsyncActionPack } from "../_shared/action-creation";
import { createNotificationEpic, createResponseSettings, createPayloadFetchEpic } from "../_shared/epic-creation";
import { push } from "connected-react-router";

const createRsvpEpic = <
    TRequest extends string,
    TSuccess extends string,
    TFailure extends string,
    TCancel extends string,
    TPayload extends LeadActionModel,
    >(
        actionPack: AsyncActionPack<TRequest, TSuccess, TFailure, TCancel, TPayload, LeadModel>,
        rsvp: (payload: TPayload, leadId: number) => AxiosPromise<number>,
        getSchedule: (state: RootState) => AsyncSchedule,
): Epic<Action<any>, any, RootState> => (action$, state) => action$.pipe(
    filter(isActionOf(actionPack.request)),
    switchMap(action =>
        from(leadApi.create(action.payload.lead))
                    .pipe(
                        mergeMap(response =>
                        from(rsvp({
                            ...action.payload,
                            lead: response.data,
                        }, response.data.leadId ?? -1))
                             
                    .pipe(
                        delay(getSchedule(state.value).minTime),
                        mergeMap(rsvpResponse => [
                            actionPack.success({
                                ...response.data,
                                rsvpId: rsvpResponse.data,
                        }),
                        push('/event?rsvpSuccess'),
                    ]),
                ),
            ),
            timeoutWith(getSchedule(state.value).maxTime, of(actionPack.failure('The request timed out'))),
            catchError((error: string) => of(actionPack.failure(error))),
        ),
    ),
);

const rsvpSeminarEpic = createRsvpEpic(
    rsvpSeminarAsync,
    (payload: SeminarRsvpActionModel, leadId: number) => leadApi.rsvpSeminar(leadId, payload.seminarId, payload.lead.emailConsent),
    state => state.rsvp.seminar.schedule,
);

const rsvpSeminarNotificationEpic = createNotificationEpic(
    rsvpSeminarAsync,
    createResponseSettings(true, true, undefined, payload => `You have successfully registered for a seminar! Please check your email for details. Your confirmation ID is ${payload.rsvpId}`),
);

const rsvpHomeVisitEpic = createRsvpEpic(
    rsvpHomeVisitAsync,
    (payload: HomeVisitRsvpActionModel, leadId: number) => leadApi.rsvpHomeVisit(leadId, payload.homeVisit),
    state => state.rsvp.homeVisit.schedule,
);

const rsvpHomeVisitNotificationEpic = createNotificationEpic(
    rsvpHomeVisitAsync,
    createResponseSettings(true, true, 'Registration Complete'),
);

const fetchRsvpEpic = createPayloadFetchEpic(
    fetchRsvpAsync,
    leadApi.getRsvp,
    (state: RootState) => state.rsvp.current.schedule,
);

const fetchRsvpNotificationEpic = createNotificationEpic(
    fetchRsvpAsync,
    createResponseSettings(false, false),
);

const cancelRsvpEpic = createPayloadFetchEpic(
    cancelRsvpAsync,
    leadApi.cancelRsvp,
    (state: RootState) => state.rsvp.current.schedule,
);

const cancelRsvpNotificationEpic = createNotificationEpic(
    cancelRsvpAsync,
    createResponseSettings(true, true, 'RSVP Cancelled'),
);

export default combineEpics(
    rsvpSeminarEpic,
    rsvpHomeVisitEpic,
    rsvpSeminarNotificationEpic,
    rsvpHomeVisitNotificationEpic,
    fetchRsvpEpic,
    fetchRsvpNotificationEpic,
    cancelRsvpEpic,
    cancelRsvpNotificationEpic,
);