/* eslint-disable @typescript-eslint/camelcase */
import { createUserManager } from 'redux-oidc';

declare var OIDC_AUTHORITY: string;

const userManagerConfig = {
    client_id: 'eventhub',
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login`,
    response_type: 'token id_token',
    scope: 'openid profile ascend.userid api.soa api.asl seminar_api',
    authority: OIDC_AUTHORITY,
    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: true,
    automaticSilentRenew: true,
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent-renew.html`,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;