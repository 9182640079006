import React from 'react';
import svgMap from '../../../assets/icons';

type IconProps = {
    className?: string;
    name: string;

    onClick?: () => void;
};

const Icon = (props: IconProps) => {
    const { className, name, onClick } = props;
    const SvgIcon = svgMap[name];

    if (!SvgIcon) {
        return null;
    }

    return <SvgIcon className={className} onClick={onClick} />;
};

export default Icon;
