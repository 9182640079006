import { createReducer } from 'typesafe-actions';
import { AsyncEntity } from "../_shared/action-model-async";

import { fetchAnalyticsAsync, AnalyticsAction } from './actions';
import { AnalyticsTrackerModel } from '../../api/models';

export default createReducer<AsyncEntity<AnalyticsTrackerModel[]>, AnalyticsAction>(AsyncEntity.Hold())
    .handleAction(fetchAnalyticsAsync.request, (state, action) => state.ApplyPend())
    .handleAction(fetchAnalyticsAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(fetchAnalyticsAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(fetchAnalyticsAsync.cancel, (state, action) => state.ApplyAbort(false));