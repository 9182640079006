import React, { useEffect, useState } from 'react';

//  Style
import cx from 'classnames';
import './ToastNotification.scss';
import Icon from '../Icon/Icon';

type ToastProps = {
    autoHideDuration?: number;
    children?: any;
    onClose?: () => void;
    open?: boolean;
    text?: string;
    theme?: string;
};

export const Toast = ({ autoHideDuration, children, onClose, open, text, theme }: ToastProps) => {
    //  State
    const [customTheme, setCustomTheme] = useState('');

    //  UseEffect
    useEffect(() => {
        const setTheme = () => {
            open ? setCustomTheme(theme!) : setCustomTheme('hidden');
        };

        const setAutoHide = () => {
            if (autoHideDuration !== undefined) {
                setTimeout(() => {
                    setCustomTheme('hidden');
                    onClose!();
                }, autoHideDuration);
            }
        };

        const scrollToTop = () => {
            if (open) {
                window.scrollTo(0, 0);
            }
        };

        setTheme();
        setAutoHide();
        scrollToTop();
    }, [open, theme]);

    return (
        <div className={cx(`alert alert--${customTheme}`)}>
            <Icon className="alert__icon" name={theme === 'success' ? 'check' : 'error'} />
            <span className="alert__text">{text}</span>
            {children}
            {onClose !== undefined && (
                <Icon className="alert__close" name="close" onClick={() => onClose!()} />
            )}
        </div>
    );
};

Toast.defaultProps = {
    open: false,
    theme: 'success',
};

export default Toast;
