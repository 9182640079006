import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AsyncEntity } from "../_shared/action-model-async";

import {
    fetchStatesAsync, fetchCountiesAsync,
    StatesAction, CountiesAction,
} from './actions';

import { StateModel } from '../../api';

const statesReducer = createReducer<AsyncEntity<StateModel[]>, StatesAction>(AsyncEntity.Hold())
    .handleAction(fetchStatesAsync.request, (state, action) => state.ApplyPend())
    .handleAction(fetchStatesAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(fetchStatesAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(fetchStatesAsync.cancel, (state, action) => state.ApplyAbort(true));

const countiesReducer = createReducer<AsyncEntity<string[], string | undefined>, CountiesAction>(AsyncEntity.Allocate<string[], string | undefined>(undefined))
    .handleAction(fetchCountiesAsync.request, (state, action) => state.ApplyPend(action.payload))
    .handleAction(fetchCountiesAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(fetchCountiesAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(fetchCountiesAsync.cancel, (state, action) => state.ApplyAbort(false));

export default combineReducers({
    states: statesReducer,
    counties: countiesReducer,
});