import { push } from 'connected-react-router';
import { FormSubTypes, TemplateModel } from '../api';
import { EditorMode } from './route-models';

const navCreator = (route: string) => (dispatch) => (): void => dispatch(push(route));
const paramNavCreator = <P>(routeGen: (p: P) => string) => (dispatch) => (param: P): void => dispatch(push(routeGen(param)));

export default {
    toDefault: navCreator('/'),
    toEvents: navCreator(`/event`),
    toSeminar: paramNavCreator((seminarId: number) => `/event/seminar/${seminarId}`),
    toHomeVisitScheduler: navCreator(`/event/schedule`),
    toDashboard: navCreator(`/admin`),
    toEditTemplate: paramNavCreator((template: TemplateModel) => `/admin/editor/${EditorMode.Edit}/${template.id}`),
    toSignIn: navCreator(`/login`),
};