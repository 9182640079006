import React from 'react';
import { connect } from 'react-redux';

import { CallbackComponent } from 'redux-oidc';

import Navigator from '../route-navigator';

import userManager from '../../utils/userManager';
import { RootState } from '../../store/types';

type StateProps = {
};

type DispatchProps = {
    onSuccess: () => void;
    onError: () => void;
};

type CallbackProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
});

const mapDispatchToProps = (dispatch) => ({
    onSuccess: Navigator.toDashboard(dispatch),
    onError: Navigator.toDefault(dispatch),
})

const Callback = ({ onSuccess, onError }: CallbackProps) => (
    <CallbackComponent
        userManager={userManager}
        successCallback={onSuccess}
        errorCallback={onError}
    >
        <div>Redirecting...</div>
    </CallbackComponent>
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Callback);