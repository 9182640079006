import React from 'react';

// Styles
import cx from 'classnames';
import './TextField.scss';

type TextFieldProps = {
    className?: string;
    classNameContainer?: string;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    id?: string;
    name: string;
    placeholder: string;
    required?: boolean;
    showLabel?: boolean;
    type?: string;
    value?: string;

    onBlur?: (event: any) => void;
    onChange: (event: any) => void;
    onFocus?: (event: any) => void;
};

const TextField = ({
    className,
    classNameContainer,
    disabled,
    error,
    helperText,
    id,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required,
    showLabel,
    type,
    value,
}: TextFieldProps) => {
    return (
        <div className={cx('text-field-container', classNameContainer)}>
            <input
                id={id}
                name={name}
                value={value}
                placeholder={placeholder}
                aria-label={placeholder}
                required={required ? required : undefined}
                disabled={disabled ? disabled : undefined}
                type={type ? type : 'text'}
                onBlur={onBlur ? onBlur : undefined}
                onChange={onChange}
                onFocus={onFocus ? onFocus : undefined}
                className={cx('text-field', className, { ['input-error']: error })}
            />
            {showLabel && <label className="text-field__label">{placeholder}</label>}

            {error && <span className="text-field-error">{helperText}</span>}
        </div>
    );
};

TextField.defaultProps = {
    showLabel: false,
    value: '',
};

export default TextField;
