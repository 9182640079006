import arrowAQE from './arrow-aqe.svg';
import arrowAdvise from './arrow-advise.svg';
import arrowBack from './arrow-back.svg';
import check from './check.svg';
import close from './close.svg';
import error from './error.svg';
import link from './link.svg';

export const svgMap = {
    arrowAdvise: arrowAdvise,
    arrowAQE: arrowAQE,
    arrowBack: arrowBack,
    check: check,
    close: close,
    error: error,
    link: link,
};

export default {
    ...svgMap,
};
