import React from 'react';

import { Form, applyContext, createFormTree } from 'dynamo-models';
import { NodeProps, EditorContainer, MetaNode, FormWidget } from 'dynamo';

import { ReadOnlyComponentFactory } from './read-only-component-factory/read-only-component-factory';

type FormReadonlyProps = {
    form: Form;
    context?: any;
};

export default ({ form, context }: FormReadonlyProps) => {
    const node = applyContext(createFormTree(form), context);
    const nodeProps = new NodeProps(node, node, new ReadOnlyComponentFactory(), new ReadOnlyComponentFactory());

    return (
        <EditorContainer container={new MetaNode(nodeProps.node)} defaultSpacing={10}>
            <FormWidget {...nodeProps} />
        </EditorContainer>
    );
};