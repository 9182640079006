import { Epic, combineEpics } from "redux-observable";

import { RootAction, RootState } from "../../store/types";
import { createPayloadFetchEpic } from '../_shared/epic-creation';

import { upsertLeadAsync } from "./actions";
import { leadApi } from "../../api";

export const upsertLeadEpic: Epic<RootAction, RootAction, RootState> = createPayloadFetchEpic(
    upsertLeadAsync,
    leadApi.create,
    (state: RootState) => state.analytics.schedule,
);

export default combineEpics(
    upsertLeadEpic,
);