import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AsyncEntity } from "../_shared/action-model-async";

import {
    searchHomeVisitsAsync,
    HomeVisitsSearchAction,
} from './actions';
import { HomeVisitFilterModel } from '../../api';

const searchReducer = createReducer<AsyncEntity<Date[], HomeVisitFilterModel>, HomeVisitsSearchAction>(AsyncEntity.Allocate<Date[], HomeVisitFilterModel>({}))
    .handleAction(searchHomeVisitsAsync.request, (state, action) => state.ApplyPend(action.payload))
    .handleAction(searchHomeVisitsAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(searchHomeVisitsAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(searchHomeVisitsAsync.cancel, (state, action) => state.ApplyAbort(false));

export default combineReducers({
    search: searchReducer,
});