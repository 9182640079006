type ValidationResult = {
    fieldName: string;
    error: string;
};

const isEmpty = (value: any) => value === undefined || value.length === 0;

export const normalizePhone = (value: string | undefined, previousValue: string | undefined): string | undefined => {
    if (!value || value.length < 10) {
        return value;
    }

    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return undefined;
};

export const normalizeZip = (value: string | undefined, previousValue: string | undefined): string | undefined => {
    if (!value) {
        return value;
    }

    let finalChar = value.substring(value.length - 1);
    let normalizedValue = value;

    if (/\D/.test(finalChar)) {
        while (/\D/.test(finalChar)) {
            normalizedValue = normalizedValue.substring(0, normalizedValue.length - 1);
            finalChar = normalizedValue.substring(normalizedValue.length - 1);
        }
    } else if (normalizedValue.length === 6) {
        normalizedValue = normalizedValue.substring(0, normalizedValue.length - 1) + "-" + finalChar;
    } else if (normalizedValue.length > 10) {
        normalizedValue = normalizedValue.substring(0, normalizedValue.length - 1);
    }

    return normalizedValue;
};

export const createValidator = (fieldName: string) => (validator: (value: any) => string | undefined) => (form: any): ValidationResult | undefined => {
    const error = validator(form[fieldName]);

    return error === undefined ? undefined : ({
        fieldName,
        error,
    });
};

export const validatePhoneNumber = (value: any) => !isEmpty(value) && !/^\(\d{3}\) \d{3}-\d{4}$/.test(value) ? 'Please enter a full phone number.' : undefined;
export const validateZip = (value: any) => !isEmpty(value) && !/^\d{5}$/.test(value) && !/^\d{5}-\d{4}$/.test(value) ? 'Please enter a 5 or 9 digit ZIP code.' : undefined;

export const require = (value: any) => value === undefined || value === null || value.length === 0 ? 'Please enter a value.' : undefined;
export const requireAll = (...fieldNames: string[]) => fieldNames.map(name => createValidator(name)(require));

export const combineValidators = (...validators: ((form: any) => ValidationResult | undefined)[]) => (form: any) => {
    const errors: any = {};
    validators
        .map(v => v(form))
        .forEach(result => {
            if (result === undefined) {
                return;
            }

            errors[result.fieldName] = errors[result.fieldName] ?? result.error;
        });

    return errors;
};