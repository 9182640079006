import { ActionType } from 'typesafe-actions';

import { HomeVisitFilterModel } from '../../api';
import { createAsyncActionPack } from '../_shared/action-creation';

export const searchHomeVisitsAsync = createAsyncActionPack(
    'SEARCH_HOME_VISITS_REQUEST',
    'SEARCH_HOME_VISITS_SUCCESS',
    'SEARCH_HOME_VISITS_FAILURE',
    'SEARCH_HOME_VISITS_CANCEL',
)<HomeVisitFilterModel, Date[]>();

export type HomeVisitsSearchAction = ActionType<typeof searchHomeVisitsAsync>;