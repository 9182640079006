import { createAction, ActionType } from 'typesafe-actions';

import { SeminarModel, SeminarFilterModel, SeminarPaginationModel } from '../../api';
import { createAsyncActionPack } from '../_shared/action-creation';

export const fetchSeminarAsync = createAsyncActionPack(
    'FETCH_SEMINAR_REQUEST',
    'FETCH_SEMINAR_SUCCESS',
    'FETCH_SEMINAR_FAILURE',
    'FETCH_SEMINAR_CANCEL',
)<number, SeminarModel>();

export const searchSeminarsAsync = createAsyncActionPack(
    'SEARCH_SEMINARS_REQUEST',
    'SEARCH_SEMINARS_SUCCESS',
    'SEARCH_SEMINARS_FAILURE',
    'SEARCH_SEMINARS_CANCEL',
)<SeminarFilterModel, SeminarPaginationModel>();

export const setSeminarPage = createAction('SET_SEMINAR_PAGE')<number>();

export type SeminarAction = ActionType<typeof fetchSeminarAsync>;
export type SeminarSearchAction = ActionType<typeof searchSeminarsAsync | typeof setSeminarPage>;