import { ActionType } from 'typesafe-actions';

import { createEmptyAsyncActionPack } from '../_shared/action-creation';

export const fetchLanguagesAsync = createEmptyAsyncActionPack(
    'FETCH_LANGUAGES_REQUEST',
    'FETCH_LANGUAGES_SUCCESS',
    'FETCH_LANGUAGES_FAILURE',
    'FETCH_LANGUAGES_CANCEL',
)<string[]>();

export type LanguageAction = ActionType<typeof fetchLanguagesAsync>;