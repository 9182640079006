import { Epic, combineEpics } from "redux-observable";

import { RootAction, RootState } from "../../store/types";
import { createEmptyFetchEpic, createNotificationEpic, createResponseSettings, createRefreshEpic, createPayloadFetchEpic } from '../_shared/epic-creation';

import { fetchTenantsAsync, fetchSettingsAsync, copyTenantAsync, publishAsync } from "./actions";
import { adminApi, configApi } from "../../api";
import { saveFormAsync } from "../template/actions";

const fetchTenantsEpic: Epic<RootAction, RootAction, RootState> = createEmptyFetchEpic(
    fetchTenantsAsync,
    () => adminApi.getTenants().then(response => ({
        ...response,
        data: response.data.tenants,
    })),
    (state: RootState) => state.tenant.all.schedule,
);

const fetchTenantsNotificationEpic = createNotificationEpic(
    fetchTenantsAsync,
    createResponseSettings(true, true),
);

const publishEpic: Epic<RootAction, RootAction, RootState> = createPayloadFetchEpic(
    publishAsync,
    adminApi.publishSite,
    (state: RootState) => state.template.save.schedule,
);

const publishNotificationEpic = createNotificationEpic(
    publishAsync,
    createResponseSettings(true, true, 'Your site changes have been published.'),
);

const copyTenantEpic: Epic<RootAction, RootAction, RootState> = createPayloadFetchEpic(
    copyTenantAsync,
    adminApi.copySite,
    (state: RootState) => state.tenant.all.schedule,
);

const copyTenantNotificationEpic = createNotificationEpic(
    copyTenantAsync,
    createResponseSettings(true, true, 'Layout Copied'),
);

const fetchSettingsEpic: Epic<RootAction, RootAction, RootState> = createEmptyFetchEpic(
    fetchSettingsAsync,
    configApi.getSettings,
    (state: RootState) => state.tenant.current.schedule,
);

const fetchSettingsNotificationEpic = createNotificationEpic(
    fetchSettingsAsync,
    createResponseSettings(true, true),
);

const refreshSettingsEpic = createRefreshEpic(
    fetchSettingsAsync.request,
    saveFormAsync.success,
    copyTenantAsync.success,
    publishAsync.success,
);

export default combineEpics(
    fetchTenantsEpic,
    publishEpic,
    copyTenantEpic,
    fetchSettingsEpic,

    fetchTenantsNotificationEpic,
    publishNotificationEpic,
    copyTenantNotificationEpic,
    fetchSettingsNotificationEpic,

    refreshSettingsEpic,
);