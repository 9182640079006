import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Tabs, Tab } from '@material-ui/core';

import SitePublishFab from '../../features/template/components/site-publish-fab';
import TemplateCollection from '../../features/template/components/template-card-collection';
import TenantList from '../../features/tenants/components/tenant-list';

import { fetchSettingsAsync, fetchTenantsAsync, onSelectTenantTemplate } from '../../features/tenants/actions';
import { setConfirmDialog } from "../../features/dialog/actions";

import { ClientSettings, TenantTupleModel, FormSubTypes, hasChanges, TemplateModel } from '../../api';
import { AsyncEntity, AsyncStatus } from '../../features/_shared/action-model-async';
import { RootState } from '../../store/types';
import Navigator from '../route-navigator';
import { useStyles } from '../../styles';
import { DialogActionModel } from '../../features/dialog/models';
import TemplateDataContainer from '../../features/template/components/template-data-container';
import TemplateFieldsEditor from '../../features/template/components/template-fields-editor';
import { upsertTemplateAsync, onTemplateNameChange, fetchTemplatesAsync, revertTemplateAsync, onSelectTemplate, publishTemplateAsync } from '../../features/template/actions';

type StateProps = {
    settingsEntity: AsyncEntity<ClientSettings, ClientSettings | undefined>;
    tenantsEntity: AsyncEntity<TenantTupleModel[]>;
    templatesEntity: AsyncEntity<TemplateModel[]>;
    templateEntity: AsyncEntity<TemplateModel>;
};

type DispatchProps = {
    fetchSettings: () => void;
    fetchTenants: () => void;
    fetchTemplates: () => void;

    openConfirmDialog: (dialog: DialogActionModel) => void;
    openEdit: (template: TemplateModel) => void;

    saveTemplate: (template: TemplateModel) => void;
    selectTemplate: (template: TemplateModel) => void;
    selectSiteTemplate: (template: TemplateModel) => void;
    changeTemplateName: (name: string) => void;
    revertTemplate: (template: TemplateModel) => void;
    publishTemplate: (template: TemplateModel) => void;
    setTemplateAsDefault: (template: TemplateModel) => void;
    toggleTemplateActiveStatus: (template: TemplateModel) => void;
};

type InterimDispatchProps = {
    createToggleTemplateCrossSiteStatus: (tenantId: number | undefined) => (template: TemplateModel) => void;
} & DispatchProps;

type MergeProps = {
    toggleTemplateCrossSiteStatus: (template: TemplateModel) => void;
};

type RoutedAdminProps = StateProps & DispatchProps & MergeProps;

const mapStateToProps = (state: RootState): StateProps => ({
    settingsEntity: state.tenant.current,
    tenantsEntity: state.tenant.all,
    templatesEntity: state.template.templates,
    templateEntity: state.template.currentTemplate,
});

const mapDispatchToProps = (dispatch): InterimDispatchProps => ({
    fetchSettings: () => dispatch(fetchSettingsAsync.request()),
    fetchTenants: () => dispatch(fetchTenantsAsync.request()),
    fetchTemplates: () => dispatch(fetchTemplatesAsync.request()),

    openConfirmDialog: (dialog: DialogActionModel) => dispatch(setConfirmDialog(dialog)),
    openEdit: Navigator.toEditTemplate(dispatch),

    saveTemplate: (template: TemplateModel) => dispatch(upsertTemplateAsync.request(template)),
    selectTemplate: (template: TemplateModel) => dispatch(onSelectTemplate(template)),
    selectSiteTemplate: (template: TemplateModel) => dispatch(onSelectTenantTemplate(template)),
    changeTemplateName: (name: string) => dispatch(onTemplateNameChange(name)),
    revertTemplate: (template: TemplateModel) => dispatch(revertTemplateAsync.request(template)),
    publishTemplate: (template: TemplateModel) => dispatch(publishTemplateAsync.request(template)),
    setTemplateAsDefault: (template: TemplateModel) => dispatch(upsertTemplateAsync.request({
        ...template,
        isDefault: true,
    })),
    toggleTemplateActiveStatus: (template: TemplateModel) => dispatch(upsertTemplateAsync.request({
        ...template,
        isActive: !template.isActive,
    })),

    createToggleTemplateCrossSiteStatus: (tenantId: number | undefined) => (template: TemplateModel) => dispatch(upsertTemplateAsync.request({
        ...template,
        tenantId: tenantId && !template.tenantId ? tenantId : undefined,
    })),
});

const mergeProps = (stateProps: StateProps, dProps: InterimDispatchProps): RoutedAdminProps => ({
    ...stateProps,
    ...dProps,

    toggleTemplateCrossSiteStatus: dProps.createToggleTemplateCrossSiteStatus(stateProps.settingsEntity.entity?.tenantId),
});

const RoutedAdmin = (props: RoutedAdminProps) => {
    const [isFieldsEditorOpen, setFieldsEditorOpen] = useState(false);
    const [currentTab, setTab] = useState(0);

    const {
        tenantsEntity,
        settingsEntity,
        templatesEntity,
        templateEntity,

        fetchSettings,
        fetchTenants,
        fetchTemplates,

        openConfirmDialog,
        openEdit,

        saveTemplate,
        selectTemplate,
        selectSiteTemplate,
        changeTemplateName,
        revertTemplate,
        publishTemplate,
        setTemplateAsDefault,
        toggleTemplateCrossSiteStatus,
        toggleTemplateActiveStatus,
    } = props;

    useEffect(() => {
        settingsEntity.HandleStatus(AsyncStatus.Inactive, fetchSettings);
        tenantsEntity.HandleStatus(AsyncStatus.Inactive, fetchTenants);
        templatesEntity.HandleStatus(AsyncStatus.Inactive, fetchTemplates);
    });

    const handleTabChange = (event, value) => {
        setTab(value);
    };

    const openCreate = (subType: FormSubTypes) => {
        if (!templatesEntity.entity) {
            return;
        }

        const defaultTemplate = templatesEntity.entity
            .find(template => template.isDefault && template.subType === subType)
            ?? templatesEntity.entity.find(template => template.subType === subType);

        if (!defaultTemplate) {
            return;
        }

        setFieldsEditorOpen(true);
        selectTemplate({
            ...defaultTemplate,
            id: 0,
            name: `${defaultTemplate.name} - Copy`,
            isDefault: false,
        });
    };

    const classes = useStyles();

    const hasFormChanges = settingsEntity.entity?.main.publishedFormId !== settingsEntity.entity?.main.stagedFormId ||
        settingsEntity.entity?.event.publishedFormId !== settingsEntity.entity?.event.stagedFormId ||
        settingsEntity.entity?.details.publishedFormId !== settingsEntity.entity?.details.stagedFormId;

    return (
        <div className={classes.adminContainer}>
            <div className={classes.adminSection}>
                <SitePublishFab
                    settings={settingsEntity.inputs ?? settingsEntity.entity}
                    disabled={!settingsEntity.entity || (!hasChanges(settingsEntity) && !hasFormChanges)}
                    confirm={openConfirmDialog}
                />
            </div>
            <TemplateFieldsEditor
                isOpen={isFieldsEditorOpen}
                template={templateEntity.inputs}
                onTemplateNameChange={changeTemplateName}
                onSaveTemplate={() => {
                    if (!templateEntity.inputs) {
                        return;
                    }

                    saveTemplate(templateEntity.inputs);
                    setFieldsEditorOpen(false);
                }}
                onCancel={() => setFieldsEditorOpen(false)}
            />
            <div className={classes.adminSection}>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Layout" />
                    <Tab label="Templates" />
                    <Tab label="Sites" />
                </Tabs>
            </div>

            <div className={classes.adminSection}>
                <div hidden={currentTab !== 0}>
                    <TemplateCollection
                        publishedSettings={settingsEntity.entity}
                        stagedSettings={settingsEntity.inputs}
                        templatesEntity={templatesEntity}
                        openEdit={openEdit}
                        selectTemplate={selectSiteTemplate}
                    />
                </div>
                <div hidden={currentTab !== 1}>
                    <TemplateDataContainer
                        settingsEntity={settingsEntity}
                        templateEntity={templateEntity}
                        itemsEntity={templatesEntity}
                        onCreateTemplate={openCreate}
                        onOpenItem={(template: TemplateModel) => openEdit(template)}
                        onRevertItem={revertTemplate}
                        onPublishItem={publishTemplate}
                        onSetItemToDefault={setTemplateAsDefault}
                        onToggleItemCrossSiteStatus={toggleTemplateCrossSiteStatus}
                        onToggleItemActiveStatus={toggleTemplateActiveStatus}
                    />
                </div>
                <div hidden={currentTab !== 2}>
                    <TenantList
                        tenantsEntity={tenantsEntity}
                        onOpenConfirmDialog={openConfirmDialog}
                    />
                </div>
            </div>
        </div>
    )
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(RoutedAdmin);