import React from 'react';

import Chip from '../../_shared/Chip/Chip';
import { TagModel } from '../../../api';

type TagReadonlyProps = {
    tag: TagModel;
    className?: string;
};

export default ({ tag, className }: TagReadonlyProps) => (
    <Chip text={tag.tagName} className={className} />
);
