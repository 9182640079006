import { TemplateModel, FormSubTypes } from "../../api";
import { AsyncEntity, flattenEntity } from "../_shared/action-model-async";
import { RootState } from "../../store/types";
import { getCurrentSeminar } from "../seminars/selectors";
import { getSiteSettings } from "../tenants/selectors";

export const getTemplates = (state: RootState): AsyncEntity<TemplateModel[]> => state.template.templates;

export const getTemplateById = (state: RootState, id: number): AsyncEntity<TemplateModel | undefined> => getTemplates(state)
    .Bind(entity => entity
        .find(template => template.id === id));

export const getSiteTemplateByFormType = (state: RootState, formType: FormSubTypes): AsyncEntity<TemplateModel | undefined> => getSiteSettings(state)
    .Bind(settings => {
        switch (formType) {
            case FormSubTypes.Info:
                return settings.main;

            case FormSubTypes.Body:
                return settings.event;

            case FormSubTypes.Description:
                return settings.details;

            default:
                return undefined;
        }
    });

export const getTemplateForCurrentSeminar = (state: RootState): AsyncEntity<TemplateModel | undefined> => flattenEntity(getCurrentSeminar(state)
    .Bind(seminar => seminar.hostTemplateId
        ? getTemplateById(state, seminar.hostTemplateId)
        : getSiteTemplateByFormType(state, FormSubTypes.Description)));