import React from 'react';

import {
    ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText,
    Avatar, IconButton, Checkbox, Button, ButtonGroup,
    FormControlLabel,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/create';

import { useStyles } from '../../../styles';

type TemplateDataItemProps = {
    name: string;

    isDefault: boolean;
    isCrossSite: boolean;
    isActive: boolean;
    canRevert: boolean;
    canPublish: boolean;

    onOpen: () => void;
    onRevert: () => void;
    onPublish: () => void;
    onSetToDefault: () => void;
    onToggleCrossSiteStatus: () => void;
    onToggleActiveStatus: () => void;
};

const TemplateDataItem = (props: TemplateDataItemProps) => {
    const {
        name,

        isDefault,
        isCrossSite,
        isActive,
        canRevert,
        canPublish,

        onOpen,
        onRevert,
        onPublish,
        onSetToDefault,
        onToggleCrossSiteStatus,
        onToggleActiveStatus,
    } = props;

    const classes = useStyles();

    return (
        <React.Fragment>
            <ListItem className={isDefault ? classes.templateDefaultDataItem : classes.templateDataItem}>
                <ListItemAvatar>
                    <IconButton
                        aria-label="edit"
                        onClick={onOpen}
                        disabled={!isActive}
                        color={!isActive ? "default" : "primary"}
                    >
                        <EditIcon />
                    </IconButton>
                </ListItemAvatar>
                <ListItemText
                    primary={name}
                    secondary={
                        <React.Fragment>
                            <FormControlLabel
                                label="Default"
                                control={
                                    <Checkbox
                                        name="defaultCb"
                                        color="primary"
                                        checked={isDefault}
                                        onChange={onSetToDefault}
                                        disabled={isDefault || !isActive || !isCrossSite}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Restricted"
                                control={
                                    <Checkbox
                                        name="crossSiteCb"
                                        color="primary"
                                        checked={!isCrossSite}
                                        onChange={onToggleCrossSiteStatus}
                                        disabled={isDefault || !isActive}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Inactive"
                                control={
                                    <Checkbox
                                        name="inactiveCb"
                                        color="primary"
                                        checked={!isActive}
                                        onChange={onToggleActiveStatus}
                                        disabled={isDefault}
                                    />
                                }
                            />
                            <Button
                                disabled={!canPublish}
                                onClick={onPublish}
                                size="small"
                                variant="text"
                                color="primary"
                            >
                                Publish
                            </Button>
                            <Button
                                disabled={!canRevert}
                                onClick={onRevert}
                                size="small"
                                variant="text"
                                color="primary"
                            >
                                Undo
                            </Button>
                        </React.Fragment>
                    }
                />
            </ListItem>
        </React.Fragment>
    );
};

export default TemplateDataItem;