import { Action } from "typesafe-actions";
import { RootAction } from "../../store/types";

export enum NotificationType {
    None,
    Error = 'error',
    Success = 'success',
    Warning = 'warning',
    Info = 'info',
}

export type NotificationActionModel = {
    type: NotificationType,
    message: string;
    retry?: Action<any>,
};

export const emptyNotification: NotificationActionModel = {
    type: NotificationType.None,
    message: '',
};

export type DialogActionModel = {
    title: string;
    bodySections: string[];

    actions: DialogReactionModel[];
};

export const emptyConfirmDialog: DialogActionModel = {
    title: '',
    bodySections: [],
    actions: [],
};

export type DialogReactionModel = {
    text: string;
    action: RootAction;
};