import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AsyncEntity } from "../_shared/action-model-async";

import {
    fetchTenantsAsync, fetchSettingsAsync, copyTenantAsync,
    TenantAction,
    onSelectTenantTemplate,
    publishAsync,
} from './actions';

import { TenantTupleModel, ClientSettings, TemplateModel, FormSubTypes } from '../../api';
import { fetchFormAsync, TemplateAction } from '../template/actions';
import { Form } from 'dynamo-models';

const applyTemplateChange = (settings: ClientSettings, template: TemplateModel) => {
    const settingsCopy = {
        ...settings,
    };

    switch (template.subType) {
        case FormSubTypes.Info:
            settingsCopy.main = template;
            break;
        case FormSubTypes.Body:
            settingsCopy.event = template;
            break;
        case FormSubTypes.Description:
            settingsCopy.details = template;
            break;
        default:
            break;
    }

    return settingsCopy;
};

const applyFormChange = (settings: ClientSettings, form: Form) => {
    const settingsCopy = {
        ...settings,
        main: { ...settings.main },
        event: { ...settings.event },
        details: { ...settings.details },
    };

    switch (form.id) {
        case settingsCopy.main.publishedFormId:
            settingsCopy.main.publishedForm = form;
            break;
        case settingsCopy.event.publishedFormId:
            settingsCopy.event.publishedForm = form;
            break;
        case settingsCopy.details.publishedFormId:
            settingsCopy.details.publishedForm = form;
            break;
        default:
            break;
    }

    return settingsCopy;
};

const isSiteForm = (settings: ClientSettings, formId: number) => formId === settings.main.publishedFormId || formId === settings.event.publishedFormId || formId === settings.details.publishedFormId;

const allTenantsReducer = createReducer<AsyncEntity<TenantTupleModel[]>, TenantAction>(AsyncEntity.Hold())
    .handleAction(fetchTenantsAsync.request, (state, action) => state.ApplyPend())
    .handleAction(fetchTenantsAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(fetchTenantsAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(fetchTenantsAsync.cancel, (state, action) => state.ApplyAbort(true));

const currentTenantReducer = createReducer<AsyncEntity<ClientSettings, ClientSettings | undefined>, TenantAction | TemplateAction>(
    AsyncEntity.Allocate<ClientSettings, ClientSettings | undefined>(undefined),
)
    .handleAction(copyTenantAsync.request, (state, action) => state.ApplyPend(state.inputs, true))
    .handleAction(copyTenantAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(copyTenantAsync.cancel, (state, action) => state.ApplyAbort(true))
    .handleAction(fetchSettingsAsync.request, (state, action) => state.ApplyPend(state.inputs, true))
    .handleAction(fetchSettingsAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(fetchSettingsAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(fetchSettingsAsync.cancel, (state, action) => state.ApplyAbort(false))
    .handleAction(fetchFormAsync.request, (state, action) => !state.entity || !isSiteForm(state.entity, action.payload) ? state : state.ApplyPend(state.inputs, true))
    .handleAction(fetchFormAsync.success, (state, action) => state.entity ? state.ApplyPut(applyFormChange(state.entity, action.payload)) : state)
    .handleAction(publishAsync.request, (state, action) => state.ApplyPend(state.inputs, true))
    .handleAction(publishAsync.success, (state, action) => state.Reset())
    .handleAction(publishAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(publishAsync.cancel, (state, action) => state.ApplyAbort(false))
    .handleAction(onSelectTenantTemplate, (state, action) => state.entity ? state.ApplyStaging(applyTemplateChange(state.inputs ?? state.entity, action.payload)) : state);

export default combineReducers({
    all: allTenantsReducer,
    current: currentTenantReducer,
});