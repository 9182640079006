import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { DialogAction, setConfirmDialog, setNotification } from './actions';
import { NotificationActionModel, DialogActionModel, emptyNotification, emptyConfirmDialog } from './models';

const confirmationReducer = createReducer<DialogActionModel, DialogAction>(emptyConfirmDialog)
    .handleAction(setConfirmDialog, (state, action) => action.payload);

const notificationReducer = createReducer<NotificationActionModel, DialogAction>(emptyNotification)
    .handleAction(setNotification, (state, action) => action.payload);

export default combineReducers({
    confirmation: confirmationReducer,
    notification: notificationReducer,
});