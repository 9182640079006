import { Epic, combineEpics } from "redux-observable";

import { RootAction, RootState } from "../../store/types";
import { createEmptyFetchEpic, createPayloadFetchEpic, createNotificationEpic, createResponseSettings, createRefreshEpic} from '../_shared/epic-creation';

import { saveFormAsync, fetchFormAsync, fetchTemplatesAsync, upsertTemplateAsync, revertTemplateAsync, publishTemplateAsync } from "./actions";
import { adminApi, FormSaveModel } from "../../api";
import { publishAsync } from "../tenants/actions";

const revertTemplateEpic: Epic<RootAction, RootAction, RootState> = createPayloadFetchEpic(
    revertTemplateAsync,
    adminApi.revertTemplate,
    (state: RootState) => state.template.revert.schedule,
);

const revertTemplateNotificationEpic = createNotificationEpic(
    revertTemplateAsync,
    createResponseSettings(true, true, 'Your latest template changes have been reverted.'),
);

const publishTemplateEpic: Epic<RootAction, RootAction, RootState> = createPayloadFetchEpic(
    publishTemplateAsync,
    adminApi.publishTemplate,
    (state: RootState) => state.template.revert.schedule,
);

const publishTemplateNotificationEpic = createNotificationEpic(
    publishTemplateAsync,
    createResponseSettings(true, true, 'Your latest template changes have been published.'),
);

const saveFormEpic: Epic<RootAction, RootAction, RootState> = createPayloadFetchEpic(
    saveFormAsync,
    (formSaveModel: FormSaveModel) => adminApi.saveForm(formSaveModel.form, formSaveModel.template),
    (state: RootState) => state.template.save.schedule,
);

const saveFormNotificationEpic = createNotificationEpic(
    saveFormAsync,
    createResponseSettings(true, true, 'Your site changes have been saved. They will take effect once the site has been published.'),
);

const fetchEpic: Epic<RootAction, RootAction, RootState> = createPayloadFetchEpic(
    fetchFormAsync,
    adminApi.getForm,
    (state: RootState) => state.template.templates.schedule,
);

const fetchFormNotificationEpic = createNotificationEpic(
    fetchFormAsync,
    createResponseSettings(true, true),
);

const fetchTemplatesEpic: Epic<RootAction, RootAction, RootState> = createEmptyFetchEpic(
    fetchTemplatesAsync,
    adminApi.getTemplates,
    (state: RootState) => state.template.templates.schedule,
    createResponseSettings(false, false, undefined, undefined, 6000),
);

const fetchTemplatesNotificationEpic = createNotificationEpic(
    fetchTemplatesAsync,
    createResponseSettings(true, true),
);

const refreshTemplatesEpic = createRefreshEpic(
    fetchTemplatesAsync.request,
    upsertTemplateAsync.success,
    publishAsync.success,
    revertTemplateAsync.success,
    saveFormAsync.success,
    publishTemplateAsync.success,
);

const upsertTemplateEpic: Epic<RootAction, RootAction, RootState> = createPayloadFetchEpic(
    upsertTemplateAsync,
    adminApi.upsertTemplate,
    (state: RootState) => state.template.currentTemplate.schedule,
);

const upsertTemplateNotificationEpic = createNotificationEpic(
    upsertTemplateAsync,
    createResponseSettings(true, true),
);

export default combineEpics(
    revertTemplateEpic,
    publishTemplateEpic,
    saveFormEpic,
    saveFormNotificationEpic,
    upsertTemplateEpic,
    fetchEpic,
    fetchTemplatesEpic,
    refreshTemplatesEpic,

    revertTemplateNotificationEpic,
    publishTemplateNotificationEpic,
    upsertTemplateNotificationEpic,
    fetchFormNotificationEpic,
    fetchTemplatesNotificationEpic,
);