import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    requireAll,
    validatePhoneNumber,
    validateZip,
    combineValidators,
    createValidator,
} from '../../../_shared/util/input-util';

import LeadInfoForm from './../lead-info-form/lead-info-form';
import { RootState } from '../../../../store/types';
import { LeadModel, StateModel, FeatureSettings } from '../../../../api/models';
import { AsyncEntity, AsyncStatus } from '../../../_shared/action-model-async';
import { fetchCountiesAsync, fetchStatesAsync } from '../../../location/actions';

// Styles
import './lead-info-container.scss';
import { getSiteSettings, getBranding } from '../../../tenants/selectors';

type StateProps = {
    lead: AsyncEntity<LeadModel, LeadModel | undefined>;
    states: AsyncEntity<StateModel[]>;
    counties: AsyncEntity<string[], string | undefined>;
    features: AsyncEntity<FeatureSettings | any>;
    isAdvise: boolean;
    extId?: string;
};

type DispatchProps = {
    fetchStates: () => void;
    fetchCounties: (zip: string) => void;
};

type OwnProps = {
    addressIsRequired?: boolean;
    onSave?: (lead: LeadModel) => void;
};

type LeadInfoProps = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: RootState): StateProps => ({
    lead: state.leads.current,
    states: state.location.states,
    counties: state.location.counties,
    features: state.features,
    isAdvise: getBranding(state)
});

const mapDispatchToProps = (dispatch) => ({
    fetchStates: () => dispatch(fetchStatesAsync.request()),
    fetchCounties: (zip: string) => dispatch(fetchCountiesAsync.request(zip)),
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

const getValidators = (isAddressRequired: boolean) =>
    combineValidators(
        ...requireAll(
            'firstName',
            'lastName',
            'phone',
            ...(isAddressRequired ? ['street', 'zip', 'city', 'state', 'county'] : [])
        ),
        createValidator('phone')(validatePhoneNumber),
        createValidator('zip')(validateZip)
    );

const LeadInfoContainer = (props: LeadInfoProps) => {
    const isReadonly = props.onSave === undefined;

    useEffect(() => {
        if (isReadonly) {
            return;
        }

        props.states.HandleStatus(AsyncStatus.Inactive, () => props.fetchStates());
        props.states.HandleStatus(AsyncStatus.Failed, () => props.fetchStates());
        props.states.HandleStatus(AsyncStatus.Canceled, () => props.fetchStates());
    });

    return (
        <div id="leadRegistrator" className="lead-info-container">
            <div id="leadRegistratorInternal">
                <LeadInfoForm
                    isReadonly={isReadonly}
                    validate={getValidators(props.addressIsRequired ?? false)}
                    onSubmit={props.onSave}
                    initialValues={{
                        ...props.lead.entity,
                        emailConsent: true,
                        extId: window.sessionStorage.getItem("extId")?.toString()
                    }}
                    onZipChange={props.fetchCounties}
                    states={props.states.entity ?? []}
                    counties={props.counties.entity ?? []}
                    addressIsRequired={props.addressIsRequired ?? false}
                    isBrandingEnabled={props.features.entity?.brandingEnabled}
                    isAdvise={props.isAdvise}
                />
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps )(LeadInfoContainer);
