import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Arrow from '../Icon/Icon';

//  Styles
import "react-datepicker/src/stylesheets/datepicker.scss";
import './Datepicker.scss';
import cx from 'classnames';

type PickerProps = {
    className?: string;
    label?: string;
    name?: string;
    onChange?: (param) => void;
    placeholder?: string;
    showAlternativeArrow?: boolean;
    showLabel?: boolean;
    value?: Date;
};

const Picker = (props: PickerProps) => {
    //  Props
    const {
        className,
        label,
        onChange,
        placeholder,
        showAlternativeArrow,
        showLabel,
        value,
    } = props;

    //  State
    const [startDate, setStartDate] = useState(value);
    let [hasChanged, setHasChanged] = useState(false);
    let [isFocused, setIsFocused] = useState(false);

    //  Effect
    useEffect(() => {
        setStartDate(value);
    }, [value]);

    let handleOnChange = (date) => {
        setIsFocused(false);
        setHasChanged(true);
        setStartDate(date);
        onChange!(date);
    };

    let handleOnBlur = () => {
        setIsFocused(false);
    };

    let handleOnFocus = () => {
        setIsFocused(true);
    };

    return (
        <div className={'datepicker__container'}>
            <div className="arrow-container">
                <DatePicker
                    selected={startDate}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onChange={(date) => handleOnChange(date)}
                    className={`${className} datepicker`}
                    placeholderText={placeholder}
                    name={name}
                ></DatePicker>

                <Arrow
                    name={showAlternativeArrow ? 'arrowAdvise' : 'arrowAQE'}
                    className={cx(
                        'datepicker__arrow',
                        { 'datepicker__arrow--focus': isFocused },
                        { 'datepicker__arrow--active': hasChanged && value !== null }
                    )}
                />
            </div>
            {showLabel && (
                <label
                    className={cx('datepicker__label', {
                        'datepicker__label--show': startDate !== null,
                    })}
                >
                    {label}
                </label>
            )}
        </div>
    );
};

Picker.defaultProps = {
    showAlternativeArrow: false,
    showLabel: false,
    value: null,
};

export default Picker;
