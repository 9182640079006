import { Epic, combineEpics } from "redux-observable";

import { RootAction, RootState } from "../../store/types";
import { createEmptyFetchEpic } from '../_shared/epic-creation';

import { fetchFeaturesAsync } from "./actions";
import { configApi } from "../../api";

const fetchFeaturesEpic: Epic<RootAction, RootAction, RootState> = createEmptyFetchEpic(
    fetchFeaturesAsync,
    configApi.getFeatureSettings,
    (state: RootState) => (state.features.schedule),
);

export default combineEpics(fetchFeaturesEpic);