
export enum AsyncStatus {
    Inactive = 0,
    Working = 1,
    Succeeded = 2,
    Failed = 3,
    Canceled = 4,
}

export type AsyncSchedule = {
    minTime: number;
    maxTime: number;
    debounceTime: number;
};

const defaultSchedule: AsyncSchedule = {
    minTime: 100,
    maxTime: 50000,
    debounceTime: 1000,
};

export const createSchedule = (debounceTime?: number, maxTime?: number, minTime?: number) => ({
    debounceTime: debounceTime ?? defaultSchedule.debounceTime,
    maxTime: maxTime ?? defaultSchedule.maxTime,
    minTime: minTime ?? defaultSchedule.minTime,
})

export class AsyncState {
    public static Initial = new AsyncState(AsyncStatus.Inactive);

    public static Cancel = (message?: string) => new AsyncState(AsyncStatus.Canceled, message);

    public static Work = (message?: string) => new AsyncState(AsyncStatus.Working, message);

    public static Complete = (message?: string) => new AsyncState(AsyncStatus.Succeeded, message);

    public static Fail = (message?: string) => new AsyncState(AsyncStatus.Failed, message);

    private constructor(
        readonly status: AsyncStatus,
        readonly message?: string,
    ) {
    }
}

export class AsyncJob {
    public static Reserve = (schedule: AsyncSchedule = defaultSchedule) => new AsyncJob(
        schedule,
        AsyncState.Initial,
    )

    protected constructor(
        readonly schedule: AsyncSchedule,
        readonly state: AsyncState,
    ) {
    }

    public Transition = (state: AsyncState) => new AsyncJob(this.schedule, state);

    public Reset = () => this.Transition(AsyncState.Initial);

    public HandleStatus = (status: AsyncStatus, onStatus?: () => void) => {
        if (this.state.status === status && onStatus !== undefined) {
            onStatus();
        }
    }

    get isWorking() {
        return this.state.status === AsyncStatus.Working;
    }

    get didSucceed() {
        return this.state.status === AsyncStatus.Succeeded;
    }
}

export class AsyncEntity<TEntity, TInputs = any> {
    public static Allocate = <TEntity, TInputs = undefined>(
        defaultInput: TInputs,
        defaultEntity?: TEntity,
        schedule: AsyncSchedule = defaultSchedule,
    ) => new AsyncEntity<TEntity, TInputs>(
        AsyncJob.Reserve(schedule),
        defaultInput,
        defaultEntity,
    )

    public static Hold = <TEntity>(
        defaultEntity?: TEntity,
        schedule: AsyncSchedule = defaultSchedule,
    ) => AsyncEntity.Allocate(undefined, defaultEntity, schedule);

    protected constructor(
        readonly job: AsyncJob,
        readonly inputs: TInputs,
        entity?: TEntity,
    ) {
        this.entityField = entity;
    }

    private readonly entityField?: TEntity;

    private Transition = (
        state: AsyncState,
        shouldRetainEntity: boolean,
        entity?: TEntity,
        inputs?: TInputs,
    ) => {
        const newInputs = inputs ?? this.inputs;
        let newEntity = shouldRetainEntity ? this.entityField : undefined;

        if (entity !== undefined) {
            newEntity = entity;
        }

        return new AsyncEntity(
            this.job.Transition(state),
            newInputs,
            newEntity,
        );
    }

    public ApplyPut = (entity: TEntity, successMessage?: string) => this.Transition(
        AsyncState.Complete(successMessage),
        false,
        entity,
    )

    public ApplyStaging = (inputs: TInputs) => this.Transition(
        this.state,
        true,
        this.entity,
        inputs,
    )

    public ApplyPend = (inputs?: TInputs, shouldRetainEntity: boolean = true, pendingMessage?: string) => this.Transition(
        AsyncState.Work(pendingMessage),
        shouldRetainEntity,
        undefined,
        inputs,
    )
    
    public ApplyAbort = (shouldRetainEntity: boolean = true) => this.Transition(
        AsyncState.Cancel(),
        shouldRetainEntity,
    )

    public ApplyError = (errorMessage?: string, shouldRetainEntity: boolean = false) => this.Transition(
        AsyncState.Fail(errorMessage),
        shouldRetainEntity,
    )

    public Bind = <TDerive>(binder: (entity: TEntity) => TDerive): AsyncEntity<TDerive, TInputs> => new AsyncEntity(this.job, this.inputs, this.entity ? binder(this.entity) : undefined);

    public Reset = (partialInput?: TInputs) => this.Transition(AsyncState.Initial, false, undefined, partialInput);

    public HandleStatus = (status: AsyncStatus, onStatus?: () => void): TEntity | undefined => {
        this.job.HandleStatus(status, onStatus);

        return this.entityField;
    }

    get isWorking() {
        return this.job.isWorking;
    }

    get didSucceed() {
        return this.job.didSucceed;
    }

    get didFail() {
        return this.job.state.status === AsyncStatus.Failed;
    }

    get entity() {
        return this.entityField;
    }

    get schedule() {
        return this.job.schedule;
    }

    get state() {
        return this.job.state;
    }
}

export const flattenEntity = <TEntity>(asyncEntity: AsyncEntity<AsyncEntity<TEntity | undefined>>): AsyncEntity<TEntity | undefined> => asyncEntity.entity ?? asyncEntity.Bind<TEntity | undefined>(_ => undefined);
