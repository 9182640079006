import { combineReducers } from 'redux';
import { createReducer, ActionType } from 'typesafe-actions';
import { AsyncJob, AsyncState, AsyncEntity } from "../_shared/action-model-async";

import {
    rsvpSeminarAsync, rsvpSeminarInitialize, rsvpHomeVisitAsync, rsvpHomeVisitInitialize,
    RsvpSeminarAction, RsvpHomeVisitAction, FetchRsvpAction, fetchRsvpAsync,
} from './actions';
import { RsvpModel } from '../../api';

const rsvpSeminarReducer = createReducer<AsyncJob, RsvpSeminarAction>(AsyncJob.Reserve())
    .handleAction(rsvpSeminarAsync.request, (state, action) => state.Transition(AsyncState.Work()))
    .handleAction(rsvpSeminarAsync.success, (state, action) => state.Transition(AsyncState.Complete()))
    .handleAction(rsvpSeminarAsync.failure, (state, action) => state.Transition(AsyncState.Fail(action.payload)))
    .handleAction(rsvpSeminarAsync.cancel, (state, action) => state.Transition(AsyncState.Cancel(action.payload)));

const rsvpHomeVisitReducer = createReducer<AsyncJob, RsvpHomeVisitAction>(AsyncJob.Reserve())
    .handleAction(rsvpHomeVisitInitialize, (state, action) => state.Reset())
    .handleAction(rsvpHomeVisitAsync.request, (state, action) => state.Transition(AsyncState.Work()))
    .handleAction(rsvpHomeVisitAsync.success, (state, action) => state.Transition(AsyncState.Complete()))
    .handleAction(rsvpHomeVisitAsync.failure, (state, action) => state.Transition(AsyncState.Fail(action.payload)))
    .handleAction(rsvpHomeVisitAsync.cancel, (state, action) => state.Transition(AsyncState.Cancel(action.payload)));

const fetchRsvpReducer = createReducer<AsyncEntity<RsvpModel, string>, FetchRsvpAction>(AsyncEntity.Allocate<RsvpModel, string>(''))
    .handleAction(fetchRsvpAsync.request, (state, action) => state.ApplyPend(action.payload))
    .handleAction(fetchRsvpAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(fetchRsvpAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(fetchRsvpAsync.cancel, (state, action) => state.ApplyAbort());

export default combineReducers({
    seminar: rsvpSeminarReducer,
    homeVisit: rsvpHomeVisitReducer,
    current: fetchRsvpReducer,
});