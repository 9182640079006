import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SeminarStubList from './../seminar-stub-list/seminar-stub-list';
import SeminarsFilter from './../seminar-filter/seminar-filter';
import LoadedPaginator from '../../../_shared/load/components/load-paginator';

import { setSeminarPage, searchSeminarsAsync } from '../../actions';
import { fetchFormAsync } from '../../../template/actions';
import { fetchTagsAsync, toggleTag } from '../../../tags/actions';

import {
    SeminarFilterModel,
    SeminarPaginationModel,
    TemplateModel,
    ClientSettings,
} from '../../../../api';
import { RootState } from '../../../../store/types';
import { AsyncEntity, AsyncStatus } from '../../../_shared/action-model-async';
import { TagSelectModel } from '../../../tags/models';
import Navigator from '../../../../routing/route-navigator';
import { getSiteSettings, getBranding } from '../../../tenants/selectors';

import {
    useLocation
} from "react-router";



// Styles
import './seminar-container.scss';

import queryString from 'query-string';
import { getDistance } from '../../../access/selector';

type StateProps = {
    settingsEntity: AsyncEntity<ClientSettings>;
    tagsEntity: AsyncEntity<TagSelectModel[]>;
    seminarsEntity: AsyncEntity<SeminarPaginationModel, SeminarFilterModel>;
    isBrandingEnabled: boolean;
    isDistanceFilteringEnabled: boolean;
    zipCodeQuery?: string;
    extId?: string;
};

type DispatchProps = {
    search: (filter: SeminarFilterModel) => void;
    setPage: (page: number) => void;
    selectSeminar: (seminarId: number) => void;
    fetchForm: (template: TemplateModel) => void;
    fetchTags: () => void;
    doToggleTag: (id: number) => void;
};

type SeminarsContainerProps = StateProps & DispatchProps;

const parseZipCode = (search): string | undefined => {
    const values = queryString.parse(search);

    return values?.zip as string | undefined;
};

const parseExtId = (search): string | undefined => {
    const values = queryString.parse(search);

    let extId = values?.extId as string | null;
    window.sessionStorage.setItem('extId', extId);
    return values?.extId as string | undefined;
};

const mapStateToProps = (state: RootState): StateProps => ({
    settingsEntity: getSiteSettings(state),
    tagsEntity: state.tags,
    seminarsEntity: state.seminars.search,
    isBrandingEnabled: getBranding(state),
    isDistanceFilteringEnabled: getDistance(state),
    zipCodeQuery: parseZipCode(state.router.location.search),
    extId: parseExtId(state.router.location.search)
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    search: (filter: SeminarFilterModel) => dispatch(searchSeminarsAsync.request(filter)),
    setPage: (page: number) => dispatch(setSeminarPage(page)),
    selectSeminar: Navigator.toSeminar(dispatch),
    fetchForm: (template: TemplateModel) =>
        dispatch(fetchFormAsync.request(template.publishedFormId)),
    fetchTags: () => dispatch(fetchTagsAsync.request()),
    doToggleTag: (id: number) => dispatch(toggleTag(id)),
});

const SeminarsContainer = (props: SeminarsContainerProps) => {
    const {
        settingsEntity,
        tagsEntity,
        seminarsEntity,
        isBrandingEnabled,
        isDistanceFilteringEnabled,
        zipCodeQuery,
        extId,
        search,
        setPage,
        selectSeminar,
        fetchForm,
        fetchTags,
    } = props;

    const location = useLocation();
    useEffect(() => {
        if (location.search === '?rsvpSuccess') {
            search({
                pageNumber: 1,
                showFull: false,
                tags: [],
            })
        }
      
    }, [location]);

    useEffect(() => {

       seminarsEntity.HandleStatus(AsyncStatus.Inactive, () =>
            search({
                pageNumber: 1,
                showFull: false,
                tags: [],
            })
        );
        
        tagsEntity.HandleStatus(AsyncStatus.Inactive, fetchTags);

        if (zipCodeQuery && !seminarsEntity.inputs.zip) {
            search({
                ...seminarsEntity.inputs,
                zip: zipCodeQuery,
            });
        }

        if (
            settingsEntity.entity?.event &&
            settingsEntity.entity.event.publishedFormId !== 0 &&
            !settingsEntity.isWorking &&
            !settingsEntity.entity.event.publishedForm
        ) {
            fetchForm(settingsEntity.entity.event);
        }
    });

    const isZipValid = seminarsEntity.entity?.isZipValid;
    const radius = seminarsEntity.entity?.radius;
    const results = seminarsEntity.entity?.seminars;
    const beId = settingsEntity.entity?.beId;
    return (
        <div>
            <h2 className="find-seminar-heading">
                {beId === 39 ? 'Let’s find an event for you': isBrandingEnabled? 'Refine my search': 'Let’s find a seminar for you' }
            </h2>

            <SeminarsFilter
                isBrandingEnabled={isBrandingEnabled}
                isDistanceFilteringEnabled={isDistanceFilteringEnabled}
                isZipValid={isZipValid}
                radius={radius}
                filter={seminarsEntity.inputs}
                search={search}
                results={results ? results?.length : 0}
            />

            <SeminarStubList
                isBrandingEnabled={isBrandingEnabled}
                seminarsEntity={seminarsEntity.Bind((entity) => entity.seminars)}
                stubFormEntity={settingsEntity.Bind((entity) => entity.event.publishedForm)}
                selectSeminar={selectSeminar}
                beId={settingsEntity.entity?.beId}
            />

            <LoadedPaginator
                pagesEntity={seminarsEntity.Bind((entity) => entity.pages)}
                currentPage={seminarsEntity.inputs?.pageNumber}
                setPage={setPage}
            />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SeminarsContainer);
