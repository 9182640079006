import { ActionType } from 'typesafe-actions';
import { createEmptyAsyncActionPack } from '../_shared/action-creation';
import { AnalyticsTrackerModel } from '../../api/models';

export const fetchAnalyticsAsync = createEmptyAsyncActionPack(
    'FETCH_ANALYTICS_REQUEST',
    'FETCH_ANALYTICS_SUCCESS',
    'FETCH_ANALYTICS_FAILURE',
    'FETCH_ANALYTICS_CANCEL',
)<AnalyticsTrackerModel[]>();

export type AnalyticsAction = ActionType<typeof fetchAnalyticsAsync>;
