import { ActionType, createAction } from 'typesafe-actions';

import { TenantTupleModel, ClientSettings, TemplateModel } from '../../api';
import { createAsyncActionPack, createEmptyAsyncActionPack } from '../_shared/action-creation';

export const fetchTenantsAsync = createEmptyAsyncActionPack(
    'FETCH_TENANTS_REQUEST',
    'FETCH_TENANTS_SUCCESS',
    'FETCH_TENANTS_FAILURE',
    'FETCH_TENANTS_CANCEL',
)<TenantTupleModel[]>();

export const copyTenantAsync = createAsyncActionPack(
    'COPY_TENANT_REQUEST',
    'COPY_TENANT_SUCCESS',
    'COPY_TENANT_FAILURE',
    'COPY_TENANT_CANCEL',
)<string, undefined>();

export const fetchSettingsAsync = createEmptyAsyncActionPack(
    'FETCH_SETTINGS_REQUEST',
    'FETCH_SETTINGS_SUCCESS',
    'FETCH_SETTINGS_FAILURE',
    'FETCH_SETTINGS_CANCEL',
)<ClientSettings>();

export const publishAsync = createAsyncActionPack(
    'PUBLISH_SITE_REQUEST',
    'PUBLISH_SITE_SUCCESS',
    'PUBLISH_SITE_FAILURE',
    'PUBLISH_SITE_CANCEL',
)<ClientSettings, any>();

export const onSelectTenantTemplate = createAction(
    'SELECT_TENANT_TEMPLATE',
)<TemplateModel>();

export type TenantAction = ActionType<
    typeof fetchTenantsAsync | typeof copyTenantAsync | typeof fetchSettingsAsync
    | typeof publishAsync | typeof onSelectTenantTemplate
>;