import React from 'react';
import { numToArray } from '../../../util/common-util';
import PaginatorButton from './../paginator-button/paginator-button';

// Styles
import './paginator-container.scss';

type PaginatorContainerProps = {
    pages: number;
    currentPage: number;

    setPage: (page: number) => void;
};

const PaginatorContainer = ({ pages, currentPage, setPage }: PaginatorContainerProps) => (
    <div className="paginator-container">
        {numToArray(pages).map((page) => (
            <PaginatorButton
                key={page}
                buttonPage={page}
                currentPage={currentPage}
                onSelect={() => setPage(page)}
            />
        ))}
    </div>
);

export default PaginatorContainer;
