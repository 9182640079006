import React from 'react';

import { InjectedFormProps, Field, change, reduxForm } from 'redux-form';
import Button from '../../../_shared/Button/Button';
import {
    renderCheckbox,
    renderSelectField,
    renderTextField,
    renderHiddenTextField,
} from '../../../_shared/form/components/custom-form-render';

import { StateModel, LeadModel } from '../../../../api';
import { normalizePhone, normalizeZip } from '../../../_shared/util/input-util';
import { SelectOptionsModel } from '../../../_shared/form/models';

// Styles
import './lead-info-form.scss';

type LeadInfoFormProps = {
    isBrandingEnabled: boolean;
    isReadonly: boolean;
    isAdvise: boolean;
    addressIsRequired: boolean;
    states: StateModel[];
    counties: string[];
    onZipChange: (zip: string) => void;
};

const LeadInfoForm = (
    props: LeadInfoFormProps & InjectedFormProps<LeadModel, LeadInfoFormProps>
) => {
    const { addressIsRequired, isBrandingEnabled, isReadonly, isAdvise} = props;
/* States (USA) Array */
    let statesArray: Array<SelectOptionsModel> = [];    
    props.states?.map((state) => {
        statesArray.push({
            label: state.name,
            value: state.abbreviation,
        });
    });

   
    /* Counties Array */
    let countiesArray: Array<SelectOptionsModel> = [];

    props.counties?.map((county) => {
        countiesArray.push({
            label: county,
            value: county,
        });
    });

    const addressField = (
        <Field
            component={renderTextField}
            disabled={isReadonly}
            required={addressIsRequired}
            props={{
                classNameContainer: 'full-lead-column',
                label: isAdvise ? 'Address' : 'Street (optional)',
            }}
            id="street"
            name="street"
            showLabel={isAdvise}
        />
    );
    const phoneField = (
        <Field
            component={renderTextField}
            disabled={isReadonly}
            required={true}
            props={{
                classNameContainer: 'lead-column',
                label: 'Phone number',
                type: 'tel',
            }}
            id="phone"
            name="phone"
            normalize={normalizePhone}
            showLabel={isAdvise}
        />
    );
    const emailField = (
        <Field
            component={renderTextField}
            disabled={isReadonly}
            props={{
                classNameContainer: isAdvise ? 'full-lead-column' : 'lead-column',
                label: isAdvise ? 'Email' : 'Email address',
                type: 'email',
            }}
            required={true}
            id="email"
            name="email"
            showLabel={isAdvise}
        />
    );
    const cityField = (
        <Field
            component={renderTextField}
            disabled={isReadonly}
            required={addressIsRequired}
            props={{
                classNameContainer: isAdvise ? 'three-quarter-lead-column' : 'lead-column',
                label: isAdvise ? 'City' : 'City (optional)',
            }}
            id="city"
            name="city"
            showLabel={isAdvise}
        />
    );
    const stateField = (
        <Field
            component={renderSelectField}
            options={statesArray}
            disabled={isReadonly}
            required={addressIsRequired}
            props={{
                classNameContainer: 'lead-column',
                label: 'State',
            }}
            id="state"
            name="state"
            showLabel={isAdvise}
            showAlternativeArrow={isAdvise}
        />
    );
    const zipField = (
        <Field
            component={renderTextField}
            disabled={isReadonly}
            required={addressIsRequired}
            props={{
                classNameContainer: isAdvise ? 'quarter-lead-column' : 'lead-column',
                label: isAdvise ? 'ZIP code' : 'ZIP code (optional)',
                showLabel: isAdvise,
            }}
            id="zip"
            name="zip"
            normalize={normalizeZip}
            onChange={(e) => props.onZipChange(e.target.value)}
        />
    );

    return (
        <>
            <span className="lead-info-heading">Register for a seminar</span>

            <form id="leadInfo" name="leadInfo" onSubmit={props.handleSubmit} className="lead-info">

                <Field
                    component={renderTextField}
                    disabled={isReadonly}
                    required={true}
                    props={{
                        classNameContainer: 'lead-column',
                        label: 'First name',
                    }}
                    id="firstName"
                    name="firstName"
                    showLabel={isAdvise}
                />

                <Field
                    component={renderTextField}
                    disabled={isReadonly}
                    required={true}
                    props={{
                        classNameContainer: 'lead-column',
                        label: 'Last name',
                    }}
                    id="lastName"
                    name="lastName"
                    showLabel={isAdvise}
                />

                {isAdvise ? null : phoneField}

                {isAdvise ? null : emailField}

                {addressField}

                {cityField}

                {isAdvise ? zipField : null}

                <Field
                    component={renderSelectField}
                    options={countiesArray}
                    disabled={isReadonly}
                    required={addressIsRequired}
                    props={{
                        classNameContainer: 'lead-column',
                        label: isAdvise ? 'County' : 'County (optional)',
                    }}
                    id="county"
                    name="county"
                    showAlternativeArrow={isAdvise}
                    showLabel={isAdvise}
                />

                {isAdvise ? phoneField : stateField}
                {isAdvise ? emailField : zipField}

                {isBrandingEnabled && !isReadonly ? (
                    <React.Fragment>
                        <Field
                            component={renderCheckbox}
                            disabled={isReadonly}
                            size="12"
                            color="primary"
                            label="By checking this box, I am agreeing to have a benefits advisor contact me to provide additional information about my Medicare benefits."
                            id="PermissionToContact"
                            name="PermissionToContact"
                            props={{
                                classNameContainer: 'full-lead-column',
                            }}
                        />
                    </React.Fragment>
                ) : null}


                <Field
                    component={renderHiddenTextField}
                    required={false}
                    props={{
                        classNameContainer: 'lead-column',
                        label: 'ExtId',
                    }}
                    id="extId"
                    name="extId"
                    showLabel={false}
                />
                {!isReadonly ? (
                    <Button
                        text="Submit registration"
                        type="submit"
                        size="large"
                        className="register-button"
                    />
                ) : null}
            </form>
        </>
    );
};

export default reduxForm<LeadModel, LeadInfoFormProps>({
    form: 'leadInfoForm',
})(LeadInfoForm);
