import { Form, LabelNode, ImageNode, createFormTree, FormNode, PageNode } from 'dynamo-models';
import { EditorConfigurator, ToolboxGroupType, createSessionNodeDesc, ToolboxNode } from 'dynamo';

import { FormSubTypes } from '../../../api/models';

const configureSession = (configurator: EditorConfigurator, subType: FormSubTypes): EditorConfigurator => {
    configurator.addTemplateGroup(ToolboxGroupType.Layout, 2);
    configurator.addToolboxTemplate(() => new LabelNode());

    switch (subType) {
        case FormSubTypes.Info:
            configurator.addToolboxTemplate(() => {
                const node = new ImageNode();
                node.url = "/assets/img/DefaultImage.png";
                return node;
            });
            break;

        case FormSubTypes.Body:
        case FormSubTypes.Description:
            configurator.addToolboxTemplate(() => {
                const node = new ImageNode();
                node.dynamicName = "ImageUrl";
                node.url = "/assets/img/DefaultImage.png";
                return node;
            }, undefined, "Seminar Image");

            // Order alphabetically
            configurator.addCustomSessionGroup("Seminar", [
                createSessionNodeDesc("Address.City"),
                createSessionNodeDesc("Address.State"),
                createSessionNodeDesc("Address.Street1"),
                createSessionNodeDesc("Address.Street2"),
                createSessionNodeDesc("Address.Zip"),
                createSessionNodeDesc("Capacity"),
                createSessionNodeDesc("SeatsAvailable"),
                createSessionNodeDesc("SeatsTaken"),
                createSessionNodeDesc("StartDateTime"),
                createSessionNodeDesc("Description"),
                createSessionNodeDesc("Location"),
                createSessionNodeDesc("Region"),
                createSessionNodeDesc("PhoneNumber"),
                createSessionNodeDesc("VirtualMeetingLink", () => {
                    const node = new LabelNode();
                    node.dynamicName = "VirtualMeetingLink";
                    node.text = `\$\{Virtual Meeting\|${node.dynamicName}\}`;
                    return node;
                }),
            ]);
            break;

        default:
            break;
    }

    return configurator;
};

export const createConfigurator = (subType: FormSubTypes, form?: Form) => {
    const emptyFormNode = new FormNode();
    emptyFormNode.tryAddChild(new PageNode());

    const formNode = form ? createFormTree(form) : emptyFormNode;
    const bareConfigurator = new EditorConfigurator(formNode);

    return configureSession(bareConfigurator, subType);
};