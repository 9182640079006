import React from 'react';

import { useStyles } from '../../../styles';
import { AsyncEntity } from '../../_shared/action-model-async';
import { TemplateModel, FormSubTypes, ClientSettings } from '../../../api';

import TemplateDataList from './template-data-list';
import FormSkeleton from '../../_shared/dynamo/components/form-readonly-skeleton';

type TemplateDataContainerProps = {
    settingsEntity: AsyncEntity<ClientSettings>;
    itemsEntity: AsyncEntity<TemplateModel[]>;
    templateEntity: AsyncEntity<TemplateModel>;

    onCreateTemplate: (subType: FormSubTypes) => void;
    onOpenItem: (template: TemplateModel) => void;
    onRevertItem: (template: TemplateModel) => void;
    onPublishItem: (template: TemplateModel) => void;
    onSetItemToDefault: (template: TemplateModel) => void;
    onToggleItemCrossSiteStatus: (template: TemplateModel) => void;
    onToggleItemActiveStatus: (template: TemplateModel) => void;
};

const TemplateDataContainer = (props: TemplateDataContainerProps) => {
    const {
        settingsEntity,
        itemsEntity,

        onCreateTemplate,
    } = props;

    const classes = useStyles();

    return settingsEntity.didSucceed && settingsEntity.entity ? (
        <div className={classes.templateDataContainer}>
            <TemplateDataList
                {...props}
                itemsEntity={itemsEntity.Bind(templates => templates.filter(template => template.subType === FormSubTypes.Info))}
                header="Home Page Description"
                onCreateTemplate={() => onCreateTemplate(FormSubTypes.Info)}
            />
            <TemplateDataList
                {...props}
                itemsEntity={itemsEntity.Bind(templates => templates.filter(template => template.subType === FormSubTypes.Body))}
                header="Seminar Summary"
                onCreateTemplate={() => onCreateTemplate(FormSubTypes.Body)}
            />
            <TemplateDataList
                {...props}
                itemsEntity={itemsEntity.Bind(templates => templates.filter(template => template.subType === FormSubTypes.Description))}
                header="Seminar Detail"
                onCreateTemplate={() => onCreateTemplate(FormSubTypes.Description)}
            />
        </div>
    ) : (
        <FormSkeleton height={300} count={1} />
    );
};

export default TemplateDataContainer;