import {
    CellNode,
    CheckboxNode,
    DateNode,
    DropdownElementNode,
    DropdownGroupNode,
    FormNode,
    LabelNode,
    PageNode,
    RadioButtonElementNode,
    RadioButtonGroupNode,
    RowNode,
    SectionNode,
    TableNode,
    TextBoxNode,
    ImageNode,
} from 'dynamo-models';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { AbsComponentFactory, NodeProps } from 'dynamo';

import { Checkbox, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

// Styles
import './read-only-component-factory.scss';

export class ReadOnlyComponentFactory extends AbsComponentFactory {
    public Prepare(node: FormNode) {}

    protected CreateFormComponent(
        children: React.Component[],
        nodeProps: NodeProps<FormNode>
    ): JSX.Element {
        const { node } = nodeProps;

        return (
            <Grid direction="column" container={true}>
                {children}
            </Grid>
        );
    }

    protected CreatePageComponent(
        children: React.Component[],
        nodeProps: NodeProps<PageNode>
    ): JSX.Element {
        return <div className="page-container">{children}</div>;
    }

    protected CreateCellComponent(
        children: React.Component[],
        nodeProps: NodeProps<CellNode>
    ): JSX.Element {
        const { node } = nodeProps;
        const style = this.getNodeStyle(nodeProps.node);
        const commonProps = this.GetCommonProps(node);
        commonProps.style = {
            height: '100%',
            ...commonProps.style,
            ...style,
        } as any;

        return <div {...commonProps}>{children}</div>;
    }

    protected CreateRowComponent(
        children: React.Component[],
        nodeProps: NodeProps<RowNode>
    ): JSX.Element {
        const { node } = nodeProps;
        const style = this.getNodeStyle(nodeProps.node);
        let templateColumns = '';

        children.forEach((element) => {
            templateColumns += 'max-content ';
        });

        const commonProps = this.GetCommonProps(node);
        commonProps.style = {
            gridTemplateColumns: templateColumns,
            display: 'grid',
            ...commonProps.style,
            ...style,
        } as any;

        return <div {...commonProps}>{children}</div>;
    }

    protected CreateTableComponent(
        children: React.Component[],
        nodeProps: NodeProps<TableNode>
    ): JSX.Element {
        return <div className="table-container">{children}</div>;
    }

    protected CreateDropdownGroupComponent(
        children: React.Component[],
        nodeProps: NodeProps<DropdownGroupNode>
    ): JSX.Element {
        const { node } = nodeProps;

        const style = this.getNodeStyle(nodeProps.node);
        const props = this.GetCommonInputProps(node);
        const onInput = (event: React.ChangeEvent<HTMLSelectElement>, r: ReactNode) =>
            (node.input = event.target.value);
        let keyIndex = 0;

        return (
            <FormControl style={{ ...props.style, width: '100%', ...style }}>
                <InputLabel htmlFor={node.nodeId}>{node.text}</InputLabel>
                <Select
                    hidden={node.isHidden}
                    onChange={onInput}
                    value={node.input}
                    inputProps={{
                        id: node.nodeId,
                    }}
                    {...props}
                    style={{ width: '100%' }}
                >
                    <MenuItem value="None" key={-1}>
                        None
                    </MenuItem>
                    {node.children.map((v) =>
                        this.CreateComponent([], NodeProps.CopyFor(nodeProps, v, keyIndex++))
                    )}
                </Select>
            </FormControl>
        );
    }

    protected CreateDropdownElementComponent(
        children: React.Component[],
        nodeProps: NodeProps<DropdownElementNode>
    ): JSX.Element {
        const { node } = nodeProps;

        return (
            <MenuItem value={node.text} key={nodeProps.keyIndex}>
                {node.text}
            </MenuItem>
        );
    }

    protected CreateRadioButtonGroupComponent(
        children: React.Component[],
        nodeProps: NodeProps<RadioButtonGroupNode>
    ): JSX.Element {
        const { node } = nodeProps;

        const selection = node.children.filter((v: any) => v.input === true)[0];
        const style = this.getNodeStyle(nodeProps.node);

        return (
            <RadioGroup
                {...this.GetCommonProps(node)}
                radioGroup={`Group_${node.nodeId}`}
                style={style}
                value={selection !== undefined ? selection.text : undefined}
            >
                {children}
            </RadioGroup>
        );
    }

    protected CreateRadioButtonElementComponent(
        children: React.Component[],
        nodeProps: NodeProps<RadioButtonElementNode>
    ): JSX.Element {
        const { node, parentNode } = nodeProps;
        const style = this.getNodeStyle(nodeProps.node);

        const onInput = (_) => {
            const groupNodeMaybe = parentNode as RadioButtonGroupNode | undefined;
            if (groupNodeMaybe === undefined) {
                return;
            }

            for (const child of groupNodeMaybe.getChildren()) {
                const radioMaybe = child as RadioButtonElementNode;
                if (radioMaybe === undefined) {
                    continue;
                }

                if (radioMaybe.nodeId !== node.nodeId) {
                    radioMaybe.input = false;
                }
            }

            node.input = true;
        };

        return (
            <FormControlLabel
                label={node.text}
                style={style}
                control={
                    <Radio
                        radioGroup={`Group_${nodeProps.parentNode.nodeId}`}
                        onChange={onInput}
                        checked={node.input}
                    />
                }
            />
        );
    }

    protected CreateSectionComponent(
        children: React.Component[],
        nodeProps: NodeProps<SectionNode>
    ): JSX.Element {
        const { node } = nodeProps;

        const style = this.getNodeStyle(nodeProps.node);

        return (
            <div {...this.GetCommonProps(node)} style={style}>
                {children}
            </div>
        );
    }

    protected CreateLabelComponent(
        children: React.Component[],
        nodeProps: NodeProps<LabelNode>
    ): JSX.Element {
        const { node } = nodeProps;

        const style = this.getNodeStyle(nodeProps.node);
        const props = this.GetCommonProps(node);

        return (
            <span
                dangerouslySetInnerHTML={{ __html: props.text !== undefined ? props.text : '' }}
                {...props}
                style={style}
            />
        );
    }

    protected CreateCheckboxComponent(
        children: React.Component[],
        nodeProps: NodeProps<CheckboxNode>
    ): JSX.Element {
        const { node } = nodeProps;

        const onInput = (event) => (node.input = event.target.checked);
        const style = this.getNodeStyle(nodeProps.node);
        const commonProps = this.GetCommonInputProps(node);

        return (
            <FormControlLabel
                label={node.text}
                {...commonProps}
                style={style}
                control={
                    <Checkbox defaultChecked={node.input} onChange={onInput} {...commonProps} />
                }
            />
        );
    }

    protected CreateTextBoxComponent(
        children: React.Component[],
        nodeProps: NodeProps<TextBoxNode>
    ): JSX.Element {
        const { node } = nodeProps;

        const style = this.getNodeStyle(nodeProps.node);
        const props = this.GetCommonInputProps(node);
        const onInput = (event) =>
            (node.input = event.target.value !== '' ? event.target.value : undefined);

        return (
            <TextField
                helperText={node.errorText}
                onChange={onInput}
                label={node.text}
                multiline={node.isMultiline}
                defaultValue={node.input}
                rows={node.isMultiline ? '6' : '0'}
                style={style}
                fullWidth={style.width === undefined}
                {...props}
            />
        );
    }

    protected CreateDateComponent(
        children: React.Component[],
        nodeProps: NodeProps<DateNode>
    ): JSX.Element {
        const { node } = nodeProps;

        const date = moment(node.input).format('YYYY-MM-DD');
        const props = this.GetCommonInputProps(node);
        const style = this.getNodeStyle(nodeProps.node);
        const onInput = (event) =>
            (node.input = event.target.value !== '' ? event.target.value : undefined);

        return (
            <TextField
                helperText={node.errorText}
                onInput={onInput}
                label={node.text}
                type="date"
                style={style}
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth={style.width === undefined}
                defaultValue={date}
                {...props}
            />
        );
    }

    protected CreateImageComponent(children: [], nodeProps: NodeProps<ImageNode>) {
        const props = this.GetCommonProps(nodeProps.node);
        const styleCalc = this.getNodeStyle(nodeProps.node);
        const style = {
            ...styleCalc,
            alignSelf: styleCalc.justifyContent === 'center' ? 'center' : styleCalc.alignSelf,
            display: 'block',
        };

        return <img {...props} style={style} />;
    }
}
