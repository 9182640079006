import Axios, { AxiosPromise } from 'axios';
import { homeVisitUrls } from './endpoints';
import { HomeVisitFilterModel } from './models';

export const homeVisitApi = {
    search: (filter: HomeVisitFilterModel): AxiosPromise<Date[]> => filter.language && filter.zipCode?.length === 5 ? Axios.get<string[]>(homeVisitUrls.search, {
        params: {
            ...filter,
            month: (filter.date ?? new Date()).toISOString(),
        },
    }).then((response) => ({
      ...response,
      data: response.data.map(d => new Date(d)),
    })) : new Promise(() => ({ response: true, data: []})),

    getLanguages: (): AxiosPromise<string[]> => Axios.get<string[]>(homeVisitUrls.getLanguages),
};