import { ActionType, createAction } from 'typesafe-actions';

import { SeminarRsvpActionModel, HomeVisitRsvpActionModel } from '../_shared/action-models';
import { LeadModel, RsvpModel } from '../../api';
import { createAsyncActionPack } from '../_shared/action-creation';

export const rsvpSeminarAsync = createAsyncActionPack(
    'RSVP_SEMINAR_REQUEST',
    'RSVP_SEMINAR_SUCCESS',
    'RSVP_SEMINAR_FAILURE',
    'RSVP_SEMINAR_CANCEL',
)<SeminarRsvpActionModel, LeadModel>();

export const rsvpSeminarInitialize = createAction('RSVP_SEMINAR_INITIALIZE')();

export const rsvpHomeVisitAsync = createAsyncActionPack(
    'RSVP_HOME_VISIT_REQUEST',
    'RSVP_HOME_VISIT_SUCCESS',
    'RSVP_HOME_VISIT_FAILURE',
    'RSVP_HOME_VISIT_CANCEL',
)<HomeVisitRsvpActionModel, LeadModel>();

export const rsvpHomeVisitInitialize = createAction('RSVP_HOME_VISIT_INITIALIZE')();

export const fetchRsvpAsync = createAsyncActionPack(
    'FETCH_RSVP_REQUEST',
    'FETCH_RSVP_SUCCESS',
    'FETCH_RSVP_FAILURE',
    'FETCH_RSVP_CANCEL',
)<string, RsvpModel>();

export const cancelRsvpAsync = createAsyncActionPack(
    'CANCEL_RSVP_REQUEST',
    'CANCEL_RSVP_SUCCESS',
    'CANCEL_RSVP_FAILURE',
    'CANCEL_RSVP_CANCEL',
)<string, any>();

export type RsvpSeminarAction = ActionType<typeof rsvpSeminarAsync | typeof rsvpSeminarInitialize>;
export type RsvpHomeVisitAction = ActionType<typeof rsvpHomeVisitAsync | typeof rsvpHomeVisitInitialize>;
export type FetchRsvpAction = ActionType<typeof fetchRsvpAsync>;