import React from 'react';

import TemplateCard from './template-card';

import { FormSubTypes, ClientSettings, TemplateModel } from '../../../api';
import { AsyncEntity } from '../../_shared/action-model-async';
import { useStyles } from "../../../styles";

type TemplateCardCollectionProps = {
    publishedSettings?: ClientSettings;
    stagedSettings?: ClientSettings;
    templatesEntity: AsyncEntity<TemplateModel[]>;

    openEdit: (template: TemplateModel) => void;
    selectTemplate: (template: TemplateModel) => void;
};

const TemplateCardCollection = (props: TemplateCardCollectionProps) => {
    const {
        publishedSettings,
        stagedSettings,
        templatesEntity,

        openEdit,
        selectTemplate,
    } = props;

    const classes = useStyles();

    const onSelectTemplate = (subType: FormSubTypes) => (id: number) => {
        const templateMatch = templatesEntity.entity?.find(v => v.subType === subType && v.id === id);

        if (!templateMatch) {
            return;
        }

        selectTemplate(templateMatch);
    };

    const formPropsFactory = (subType: FormSubTypes, currentTemplate: TemplateModel | undefined, selectedTemplate: TemplateModel | undefined) => {
        return {
            selectedTemplate: selectedTemplate ?? currentTemplate,
            templates: templatesEntity.Bind(templates => templates.filter(v => v.subType === subType && v.isActive)).entity ?? [],
            canOpen: true,
            canRevert: selectedTemplate !== undefined && currentTemplate !== undefined && currentTemplate.id !== selectedTemplate.id,

            selectTemplate: onSelectTemplate(subType),
            onOpen: () => currentTemplate ? openEdit(currentTemplate) : {},
            onRevert: () => currentTemplate ? selectTemplate(currentTemplate) : {},
        };
    };

    const mainFormProps = formPropsFactory(FormSubTypes.Info, publishedSettings?.main, stagedSettings?.main);
    const stubFormProps = formPropsFactory(FormSubTypes.Body, publishedSettings?.event, stagedSettings?.event);
    const detailsFormProps = formPropsFactory(FormSubTypes.Description, publishedSettings?.details, stagedSettings?.details);

    return (
        <div className={classes.templateCollection}>
            <TemplateCard
                title="Home Page Description"
                content="The main information, images and text, for the main page of your site. This is an excellent place to let the consumer know any business details you would like."
                {...mainFormProps}
            />

            <TemplateCard
                title="Seminar Summary"
                content="The general information displayed on each of the seminar's listed in the list. Date, time, and location information are examples of items to list here."
                {...stubFormProps}
            />

            <TemplateCard
                title="Seminar Detail"
                content="The detailed information for a seminar. Anything relevant to a particular seminar should be added here."
                {...detailsFormProps}
            />
        </div>
    );
};

export default TemplateCardCollection;