import { FormPack, Form } from 'dynamo-models';
import { TagSelectModel } from '../features/tags/models';

export enum FormSubTypes {
    Invalid = 0,
    Info = 5,
    Body = 6,
    Description = 7,
}

export type FeatureSettings = {
    seminarsEnabled: boolean;
    homeVisitsEnabled: boolean;
    brandingEnabled: boolean;
    distanceFilteringEnabled: boolean;
};

export type SeminarFilterModel = {
    pageNumber: number;
    name?: string;
    zip?: string;
    isZipValid: boolean;
    date?: Date;
    showFull: boolean;
    tags: number[];
    radius: number;
};

export type SeminarRsvpModel = {
    seminarId: number;
    leadId: number;
};

export type HomeVisitFilterModel = {
    zipCode?: string;
    language?: string;
    date?: Date;
};

export type HomeVisitRsvpModel = {
    language: string;
    zip: string;
    startDateTime: Date;
};

export type StateModel = {
    abbreviation: string;
    name: string;
};

export type LeadModel = {
    leadId?: number;

    firstName: string;
    lastName: string;

    phone: string;
    email: string;

    street: string;
    city: string;
    county: string;
    state: string;
    zip: string;
    ptc: boolean;

    emailConsent: boolean;
};

export type TenantTupleModel = {
    url: string;
    tags: string[];
};

export type TagModel = {
    tagId: number;
    tagName: string;
};

export type SeminarPaginationModel = {
    seminars: SeminarModel[];
    pages: number;
};

export type AddressModel = {
    city: String;
    county: String;
    state: String;
    street1: String;
    street2: String;
    zip: String;
};

export type SeminarModel = {
    appointmentId: number;
    date: Date;
    description: String;
    location: String;
    phoneNumber: String;
    seatsAvailable: number;
    startDateTime: string;
    tags: TagSelectModel[];
    website: String;
    address: AddressModel;
    hostTemplateId?: number;
};

export type ClientSettings = {
    tenantId: number;
    main: TemplateModel;
    event: TemplateModel;
    details: TemplateModel;
    redirectUrl: string;
    beid: number;
};

export type TenantResponse = {
    tenants: TenantTupleModel[];
};

export type FormSaveModel = {
    form: FormPack;
    template: TemplateModel;
};

export const getFormOfType = (settings: ClientSettings, subType: FormSubTypes) => {
    switch (subType) {
        case FormSubTypes.Info:
            return settings.main;

        case FormSubTypes.Body:
            return settings.event;

        case FormSubTypes.Description:
            return settings.details;

        default:
            return undefined;
    }
};

export type RsvpModel = {
    seminar: number;
    lead: LeadModel;
};

export type AnalyticsTrackerModel = {
    name: string;
    code: string;
};

export type TemplateModel = {
    id: number;
    name: string;
    subType: FormSubTypes;
    stagedFormId: number;
    publishedFormId: number;
    tenantId?: number;
    isActive: boolean;
    isDefault: boolean;
    publishedForm?: Form;
    stagedForm?: Form;
};

export type FeaturesModel = {
    homeVisits: boolean;
    branding: boolean;
    distanceFiltering: boolean;
};
