import React from 'react';

import { List, Typography, Button } from '@material-ui/core';

import AddIcon from "@material-ui/icons/add";

import TemplateDataItem from './template-data-item';
import FormSkeleton from '../../_shared/dynamo/components/form-readonly-skeleton';

import { useStyles } from '../../../styles';
import { AsyncEntity } from '../../_shared/action-model-async';
import { TemplateModel } from '../../../api';

type TemplateDataListProps = {
    header: string;
    itemsEntity: AsyncEntity<TemplateModel[]>;
    templateEntity: AsyncEntity<TemplateModel>;

    onCreateTemplate: () => void;
    onOpenItem: (template: TemplateModel) => void;
    onRevertItem: (template: TemplateModel) => void;
    onPublishItem: (template: TemplateModel) => void;
    onSetItemToDefault: (template: TemplateModel) => void;
    onToggleItemCrossSiteStatus: (template: TemplateModel) => void;
    onToggleItemActiveStatus: (template: TemplateModel) => void;
};

const TemplateDataList = (props: TemplateDataListProps) => {
    const {
        header,
        itemsEntity,
        templateEntity,

        onCreateTemplate,
        onOpenItem,
        onRevertItem,
        onPublishItem,
        onSetItemToDefault,
        onToggleItemCrossSiteStatus,
        onToggleItemActiveStatus,
    } = props;

    const generateItemActions = (template: TemplateModel) => ({
        onOpen: () => onOpenItem(template),
        onRevert: () => onRevertItem(template),
        onPublish: () => onPublishItem(template),
        onSetToDefault: () => onSetItemToDefault(template),
        onToggleCrossSiteStatus: () => onToggleItemCrossSiteStatus(template),
        onToggleActiveStatus: () => onToggleItemActiveStatus(template),
    });

    const classes = useStyles();
    const sortedItems = itemsEntity.entity?.reduce((acc, item) => {
        if (item.isDefault) {
            acc.unshift(item);
        } else {
            acc.push(item);
        }

        return acc;
    }, Array.of<TemplateModel>());

    return (
        <div className={classes.templateDataList}>
            <div className={classes.templateDataListHeaderDiv}>
                <Typography variant="h6">
                    {header}
                </Typography>
                <Button
                    variant="text"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={onCreateTemplate}
                >
                    Create
                </Button>
            </div>
            <List
                dense={true}
            >
                {
                    !templateEntity.isWorking
                    && itemsEntity.didSucceed
                    && sortedItems
                    ? sortedItems.map(template => (
                        <TemplateDataItem
                            key={template.id}
                            name={template.name}
                            isDefault={template.isDefault}
                            isActive={template.isActive}
                            isCrossSite={!template.tenantId}
                            canRevert={template.isActive && template.publishedFormId !== template.stagedFormId}
                            canPublish={template.isActive && template.stagedFormId !== template.publishedFormId}
                            {...generateItemActions(template)}
                        />
                    )) : (
                        <FormSkeleton height={120} count={3} />
                    )
                }
            </List>
        </div>
    );
};

export default TemplateDataList;