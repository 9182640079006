import * as qs from 'qs';
import { AxiosResponse } from 'axios';
import { ClientSettings, TemplateModel } from './models';
import { AsyncEntity } from '../features/_shared/action-model-async';

export const repeatSerializer = p => qs.stringify(p, { arrayFormat: "repeat" });

export const createConfig = (params: any, paramsSerializer?: (p: any) => string) => ({
    params,
    paramsSerializer,
});

export function dataOf(response: AxiosResponse<string[]>): string[] {
    return response.data;
}

export const hasChanges = (
    (hasFormChanges) =>
        (settings: AsyncEntity<ClientSettings, ClientSettings | undefined>) => (
            !settings.entity ||
            settings.inputs && (
                settings.entity.main.id !== settings.inputs.main.id ||
                settings.entity.event.id !== settings.inputs.event.id ||
                settings.entity.details.id !== settings.inputs.details.id
            )
        )
)(
    (deltaModel: TemplateModel) => deltaModel.publishedFormId !== deltaModel.stagedFormId,
);