import React, { useEffect } from "react";
import { connect } from "react-redux";

import HomeVisitRegistrator from '../../features/home-visits/components/home-visit-registrator';

import { fetchLanguagesAsync } from '../../features/localization/actions';
import { RootState } from "../../store/types";
import { AsyncEntity, AsyncStatus } from "../../features/_shared/action-model-async";

type StateProps = {
    languagesEntity: AsyncEntity<string[]>,
};

type DispatchProps = {
    fetchLanguages: () => void;
};

type RoutedHomeVisitRegistratorProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
    languagesEntity: state.localization.languages,
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    fetchLanguages: () => dispatch(fetchLanguagesAsync.request()),
});

const RoutedHomeVisitRegistrator = (props: RoutedHomeVisitRegistratorProps) => {
    const {
        languagesEntity,

        fetchLanguages,
    } = props;

    useEffect(() => {
        languagesEntity.HandleStatus(AsyncStatus.Inactive, fetchLanguages);
    });

    return languagesEntity.entity ? (
        <HomeVisitRegistrator />
    ) : null;
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RoutedHomeVisitRegistrator);