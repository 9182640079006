import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AsyncEntity } from "../_shared/action-model-async";

import {
    upsertLeadAsync, setLead, clearLead,
    LeadAction,
} from './actions';

import { LeadModel } from '../../api';
import { RsvpHomeVisitAction, RsvpSeminarAction, FetchRsvpAction, fetchRsvpAsync, rsvpHomeVisitAsync, rsvpSeminarAsync } from '../rsvp/actions';

const leadReducer = createReducer<AsyncEntity<LeadModel, LeadModel | undefined>, LeadAction | FetchRsvpAction | RsvpHomeVisitAction | RsvpSeminarAction>(AsyncEntity.Allocate<LeadModel, LeadModel | undefined>(undefined))
    .handleAction(upsertLeadAsync.request, (state, action) => state.ApplyPend(action.payload))
    .handleAction(upsertLeadAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(upsertLeadAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(upsertLeadAsync.cancel, (state, action) => state.ApplyAbort(true))
    .handleAction([
        rsvpHomeVisitAsync.success,
        rsvpSeminarAsync.success,
        setLead,
    ], (state, action) => state.ApplyPut(action.payload))
    .handleAction(fetchRsvpAsync.success, (state, action) => state.ApplyPut(action.payload.lead))
    .handleAction(clearLead, (state, action) => state.Reset());

export default combineReducers({
    current: leadReducer,
});