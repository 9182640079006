import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { ConnectedRouter } from 'connected-react-router';

import store, { history } from './store';

import AppContainer from './containers/app-container';
import RouteContainer from './routing/route-container';

import userManager from './utils/userManager';

// DO NOT REMOVE: REQUIRED FOR CSS LOADING ISSUE
const s =  require("react-datepicker/dist/react-datepicker.css");

const App = () => (
    <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
            <ConnectedRouter history={history}>
                <AppContainer>
                    <RouteContainer />
                </AppContainer>
            </ConnectedRouter>
        </OidcProvider>
    </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));
