import React, { useState, useEffect } from 'react';

import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogContentText, DialogActions } from '@material-ui/core';
import { TemplateModel } from '../../../api';

type TemplateFieldsEditorProps = {
    isOpen: boolean;
    template?: TemplateModel;

    onTemplateNameChange: (name: string) => void;
    onSaveTemplate: () => void;
    onCancel: () => void;
};

const TemplateFieldsEditor = (props: TemplateFieldsEditorProps) => {
    const {
        isOpen,
        template,

        onTemplateNameChange,
        onSaveTemplate,
        onCancel,
    } = props;

    return (
        <Dialog open={isOpen} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Template</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Please choose a name for the template.
            </DialogContentText>
            <TextField
                autoFocus
                id="name"
                label="Name"
                type="text"
                fullWidth
                value={template?.name ?? ''}
                onChange={(e) => onTemplateNameChange(e.currentTarget.value)}
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={onCancel} color="primary">
                Cancel
            </Button>
            <Button onClick={onSaveTemplate} color="primary">
                Save
            </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TemplateFieldsEditor;