import Axios, { AxiosResponse } from 'axios';
import { AxiosPromise } from 'axios';
import { adminUrls } from './endpoints';
import { createConfig } from './util';
import { TenantResponse, FormSubTypes, TemplateModel, ClientSettings } from './models';
import { FormPack, Form } from 'dynamo-models';

export const adminApi = {
    getTenants: (): AxiosPromise<TenantResponse> => Axios.get<TenantResponse>(adminUrls.getTenants),

    copySite: (sourceUrl?: string): AxiosPromise => Axios.post(adminUrls.copySite, {
        sourceTenantUrl: sourceUrl,
    }),

    publishSite: (settings: ClientSettings): AxiosPromise => Axios.post(adminUrls.publishSite, {
        mainTemplateId: settings.main.id,
        eventTemplateId: settings.event.id,
        detailsTemplateId: settings.details.id,
    }),

    revertTemplate: (template: TemplateModel) => Axios.post(adminUrls.revertTemplate, {
        templateId: template.id,
    }),

    publishTemplate: (template: TemplateModel) => Axios.post(adminUrls.publishTemplate, {
        templateId: template.id,
    }),

    saveForm: (formPack: FormPack, template: TemplateModel): AxiosPromise => Axios.post(adminUrls.saveForm, {
        form: formPack,
        template,
    }),

    upsertTemplate: (template: TemplateModel): AxiosPromise<TemplateModel> => Axios.post(adminUrls.upsertTemplate, {
        ...template,
        publishedForm: undefined,
        stagedForm: undefined,
    }),

    getForm: (formId: number): AxiosPromise<Form> => Axios.get(adminUrls.getForm, {
        params: {
            formId,
        },
    }),

    getTemplates: (): AxiosPromise<TemplateModel[]> => Axios.get(adminUrls.getTemplates),
};