import Axios, { AxiosPromise } from 'axios';

import { seminarUrls } from './endpoints';
import { createConfig, repeatSerializer } from './util';

import { SeminarFilterModel, SeminarModel, SeminarPaginationModel } from './models';
import { TagSelectModel } from '../features/tags/models';

export const seminarApi = {
    getSeminar: (id: number): AxiosPromise<SeminarModel> => Axios.get(seminarUrls.getSeminar,
        createConfig({
            seminar: id,
        })),

    getBy: (filter: SeminarFilterModel): AxiosPromise<SeminarPaginationModel> => Axios.get(seminarUrls.getBy, createConfig({
        page: filter.pageNumber,
        nameFilter: filter.name,
        zipFilter: filter.zip,
        dateFilter: filter.date !== undefined ? filter.date.toISOString() : undefined,
        showFull: filter.showFull,
        tag: filter.tags,
        radius: filter.radius,
    }, repeatSerializer)),

    getTags: (): AxiosPromise<TagSelectModel[]> => Axios.get(seminarUrls.getTags),
};