import { Epic, combineEpics } from 'redux-observable';
import { isActionOf, PayloadActionCreator, PayloadAction, Action, ActionCreator } from 'typesafe-actions';

import { from, of, Observable } from 'rxjs';
import { filter, mergeMap, switchMap, map, catchError, tap, timeoutWith, delay, debounceTime, withLatestFrom } from 'rxjs/operators';

import { RootState } from '../../store/types';
import { AxiosPromise, AxiosResponse, AxiosError } from 'axios';
import { AsyncSchedule } from './action-model-async';
import { setNotification } from '../dialog/actions';
import { NotificationType } from '../dialog/models';
import { AsyncActionPack, AsyncEmptyActionPack } from './action-creation';

const processPromise = <
    TSuccess extends string,
    TFailure extends string,
    TResult,
>(
    observable: Observable<AxiosResponse<TResult>>,
    successAction: (result: TResult) => PayloadAction<TSuccess, TResult>,
    failureAction: (error: string) => PayloadAction<TFailure, string>,
    schedule: AsyncSchedule,
    effect: (result: TResult) => void,
) => observable.pipe(
    delay(schedule.minTime),
    map(response => successAction(response.data)),
    tap(success => effect(success.payload)),
    timeoutWith(schedule.maxTime, of(failureAction('The request timed out'))),
    catchError((error: AxiosError) => of(failureAction(error.message))),
);

const createSuccessEpic = (
    successActionCreator: ActionCreator<any>,
    message: string,
): Epic<Action<any>, Action<any>> => (action$) => action$.pipe(
    filter(isActionOf(successActionCreator)),
    mergeMap(action => of(setNotification({
        type: NotificationType.Success,
        message,
    }))),
);

const createErrorEpic = (
    failureActionCreator: PayloadActionCreator<any, string>,
): Epic<Action<any>, Action<any>> => (action$) => action$.pipe(
    filter(isActionOf(failureActionCreator)),
    mergeMap(action => of(setNotification({
        type: NotificationType.Error,
        message: action.payload,
    }))),
);

const createRetryEpic = (
    requestActionCreator: ActionCreator<any>,
    failureActionCreator: PayloadActionCreator<any, string>,
): Epic<Action<any>, Action<any>> => (action$) => action$.pipe(
    filter(isActionOf(failureActionCreator)),
    withLatestFrom(action$.pipe(
        filter(isActionOf(requestActionCreator)),
    )),
    mergeMap(actions => of(setNotification({
        type: NotificationType.Error,
        message: actions[0].payload,
        retry: actions[1],
    }))),
);

const createHandledSuccessEpic = (
    handledSuccessActionCreator: PayloadActionCreator<any, string>,
    handler: (payload: any) => string,
): Epic<Action<any>, Action<any>> => (action$) => action$.pipe(
        filter(isActionOf(handledSuccessActionCreator)),
        mergeMap(action => of(setNotification({
            type: NotificationType.Success,
            message: handler(action.payload),
        }))),
);

export type ResponseSettings = {
    successMessage?: string;
    successHandler?: (payload: any) => string;
    showErrorMessages: boolean;
    allowRetry: boolean;
    setdelay?: number;
};

export const createResponseSettings = (
    showErrorMessages: boolean,
    allowRetry: boolean,
    successMessage?: string,
    successHandler?: (payload: any) => string,
    setdelay?: number,
) => ({
    showErrorMessages,
    allowRetry,
    successMessage,
    successHandler,
    setdelay,
});

export type NotificationPack = {
    request: ActionCreator<any>;
    success: ActionCreator<any>;
    failure: PayloadActionCreator<any, string>;
};

export const createNotificationEpic = (
    actionPack: NotificationPack,
    settings: ResponseSettings,
): Epic<Action<any>, Action<any>> => {
    const notificationEpics: Array<Epic<Action<any>, Action<any>>> = [];

    if (settings.successHandler) {
        notificationEpics.push(createHandledSuccessEpic(actionPack.success, settings.successHandler));
    }

    if (settings.successMessage) {
        notificationEpics.push(createSuccessEpic(actionPack.success, settings.successMessage));
    }

    if (settings.showErrorMessages && !settings.allowRetry) {
        notificationEpics.push(createErrorEpic(actionPack.failure));
    }

    if (settings.showErrorMessages && settings.allowRetry) {
        notificationEpics.push(createRetryEpic(actionPack.request, actionPack.failure));
    }

    return combineEpics(...notificationEpics);
};

export const createPayloadFetchEpic = <
    TRequest extends string,
    TSuccess extends string,
    TFailure extends string,
    TCancel extends string,
    TParam,
    TResult
>(
    actionPack: AsyncActionPack<TRequest, TSuccess, TFailure, TCancel, TParam, TResult>,
    apiPromise: (param: TParam) => AxiosPromise<TResult>,
    getSchedule: (state: RootState) => AsyncSchedule,
    effect: (result: TResult) => void = (result: TResult) => null,
): Epic<Action<any>, Action<any>, RootState> => (action$, state) => action$.pipe(
    filter(isActionOf(actionPack.request)),
    debounceTime(getSchedule(state.value).debounceTime),
    switchMap(action =>
        processPromise(
            from(apiPromise(action.payload)),
            actionPack.success,
            actionPack.failure,
            getSchedule(state.value),
            effect,
        ),
    ),
);

export const createEmptyFetchEpic = <
    TRequest extends string,
    TSuccess extends string,
    TFailure extends string,
    TCancel extends string,
    TResult
>(
    actionPack: AsyncEmptyActionPack<TRequest, TSuccess, TFailure, TCancel, TResult>,
    apiPromise: () => AxiosPromise<TResult>,
    getSchedule: (state: RootState) => AsyncSchedule,
    settings: ResponseSettings,
    effect: (result: TResult) => void = (result: TResult) => null,
): Epic<Action<any>, Action<any>, RootState> => (action$, state) => action$.pipe(
    filter(isActionOf(actionPack.request)),
    debounceTime(getSchedule(state.value).debounceTime),
    switchMap(action =>
        processPromise(
            from(apiPromise()),
            actionPack.success,
            actionPack.failure,
            getSchedule(state.value),
            effect,
        ),
    ),
    delay(settings?.setdelay?? 0),
);

export const createRefreshEpic = <
    TSource extends string,
    TTarget extends string,
>(
    targetAction: ActionCreator,
    ...sourceActions: ActionCreator[]
): Epic<Action<any>, Action<any>, RootState> => (action$, state) => action$.pipe(
    filter(isActionOf(sourceActions)),
    switchMap(action => [targetAction()]),
);