import React from 'react';
import Checkbox from '../../../_shared/Checkbox/Checkbox';
import TextInput from '../../../_shared/TextField/TextField';
import Select from '../../../_shared/Select/Select';

export const renderTextField = ({ input, label, meta: { touched, error }, ...custom }: any) => {
    return (
        <TextInput
            error={touched && error !== undefined}
            placeholder={label}
            helperText={touched && error}
            {...input}
            {...custom}
        />
    );
};

export const renderHiddenTextField = ({ input, label, meta: { touched, error }, ...custom }: any) => {
    return (
        <TextInput
            error={touched && error !== undefined}
            placeholder={label}
            helperText={touched && error}
            type="hidden"
            class=""
            showLabel
            {...input}
            {...custom}
        />
    );
};
export const renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
}: any) => (
    <Select
        error={touched && error !== undefined}
        label={label}
        helperText={touched && error}
        {...input}
        {...custom}
        onChange={(event) => input.onChange(event.target.value)}
        children={children}
    />
);

export const renderCheckbox = ({ input, label, ...custom }: any) => (
    <Checkbox
        value={input.value === true}
        onChange={input.onChange}
        text={label}
        {...input}
        {...custom}
    />
);
