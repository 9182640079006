import React from "react";

import { Card, CardHeader, CardContent, Typography, CardActions, IconButton, Select, MenuItem } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import EditIcon from "@material-ui/icons/create";
import AddIcon from "@material-ui/icons/add";
import CloseIcon from "@material-ui/icons/close";

import { AsyncJob } from "../../_shared/action-model-async";
import { useStyles } from "../../../styles";
import { TemplateModel } from "../../../api";

type TemplateCardProps = {
    title: string;
    content: string;
    selectedTemplate: TemplateModel | undefined;
    templates: TemplateModel[];
    canOpen: boolean;
    canRevert: boolean;

    selectTemplate: (id: number) => void;
    onOpen: () => void;
    onRevert: () => void;
};

const TemplateCard = (props: TemplateCardProps) => {
    const {
        title,
        content,
        selectedTemplate,
        templates,
        canOpen,
        canRevert,

        selectTemplate,
        onOpen,
        onRevert,
    } = props;

    const classes = useStyles();

    const onChange = (event) => {
        selectTemplate(event.target.value);
    };

    return (
        <Card className={classes.templateCard}>
            <CardHeader title={title}>
            </CardHeader>
            <CardContent className={classes.templateCardContent}>
                <Typography>
                    {content}
                </Typography>
            </CardContent>
            <CardActions className={classes.templateCardActions}>
                <IconButton title="Edit saved" aria-label="Edit" color="primary" disabled={!canOpen} onClick={onOpen} className={classes.squaredIconButton}>
                    <EditIcon />
                    Edit
                </IconButton>
                <Select
                    value={selectedTemplate?.id ?? ''}
                    onChange={onChange}
                >
                    {
                        templates.map(v => (
                            <MenuItem value={v.id} key={v.id}>
                                {v.name}
                            </MenuItem>
                        ))
                    }
                </Select>
                <div className={classes.templateUndoAction}>
                    <IconButton title="Revert saved changes to the currently published version" aria-label="Revert" color="primary" disabled={!canRevert} onClick={onRevert} className={classes.squaredIconButton}>
                        <CloseIcon />
                        Cancel
                    </IconButton>
                </div>
            </CardActions>
        </Card>
    )
};

export default TemplateCard;