import Axios, { AxiosPromise } from 'axios';
import { leadUrls } from './endpoints';
import { HomeVisitRsvpModel, LeadModel, RsvpModel } from './models';

export const leadApi = {
    rsvpHomeVisit: (leadId: number, homeVisit: HomeVisitRsvpModel) => Axios.post(leadUrls.rsvpHomeVisit, {
        ...homeVisit,
        startDateTime: homeVisit.startDateTime.toISOString(),
        leadId,
    }),

    rsvpSeminar: (leadId: number, seminarId: number, emailConsent: boolean) => Axios.post<string>(leadUrls.rsvpSeminar, {
        seminarId,
        leadId,
        emailConsent
    }).then(response => ({
        ...response,
        data: response.data,
    })),

    create: (lead: LeadModel): AxiosPromise<LeadModel> => Axios.post<number>(leadUrls.create, lead)
        .then(response => ({
            ...response,
            data: {
                ...lead,
                leadId: response.data,
            },
        })),

    getRsvp: (passcode: string): AxiosPromise<RsvpModel> => Axios.get<RsvpModel>(leadUrls.getRsvp, {
        params: {
            passcode,
        },
    }),

    cancelRsvp: (passcode: string): AxiosPromise => Axios.post(leadUrls.cancelRsvp, {
        passcode,
    }),
};