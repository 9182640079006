import { Epic, combineEpics } from "redux-observable";
import { isActionOf } from "typesafe-actions";

import { of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

import { RootAction, RootState } from "../../store/types";
import { createPayloadFetchEpic, createNotificationEpic, createResponseSettings } from '../_shared/epic-creation';

import { fetchSeminarAsync, searchSeminarsAsync, setSeminarPage } from "./actions";
import { seminarApi, SeminarFilterModel } from "../../api";

const fetchSeminarEpic: Epic<RootAction, RootAction, RootState> = createPayloadFetchEpic(
    fetchSeminarAsync,
    seminarApi.getSeminar,
    (state: RootState) => state.seminars.current.schedule,
);

const setSeminarPageEpic: Epic<RootAction, RootAction, RootState> = (action$, state) => action$.pipe(
    filter(isActionOf(setSeminarPage)),
    switchMap(action =>
        of(searchSeminarsAsync.request(state.value.seminars.search.inputs ? {
            ...state.value.seminars.search.inputs,
            pageNumber: action.payload,
        } : {
            showFull: false,
            pageNumber: action.payload,
            tags: [],
        } as SeminarFilterModel))
    ),
);

const searchSeminarsEpic: Epic<RootAction, RootAction, RootState> = createPayloadFetchEpic(
    searchSeminarsAsync,
    seminarApi.getBy,
    (state: RootState) => state.seminars.search.schedule,
);

const searchSeminarNotificationEpic = createNotificationEpic(
    searchSeminarsAsync,
    createResponseSettings(true, true),
);

export default combineEpics(
    fetchSeminarEpic,
    setSeminarPageEpic,
    searchSeminarsEpic,
    searchSeminarNotificationEpic,
);