import React from "react";

import { Paper, IconButton, Typography } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/launch";

import TagContainer from '../../tags/components/tag-readonly-list';

import { TenantTupleModel } from "../../../api";
import { useStyles } from '../../../styles';

type TenantWidgetProps = {
    tenant: TenantTupleModel;

    onOpen: () => void;
    onCopy: () => void;
};

export default (props: TenantWidgetProps) => {
    const {
        tenant,

        onOpen,
        onCopy,
    } = props;

    let fakeId = 1;

    const tags = tenant.tags.map(tag => ({
        tagId: fakeId++,
        tagName: tag,
    }));

    const classes = useStyles();

    return (
        <Paper className={classes.tenantItemContainer} key={tenant.url}>
            <IconButton aria-label="Open" onClick={onOpen} color="primary" className={classes.squaredIconButton}>
                <Typography className={classes.overflowButtonLabel}>
                    {props.tenant.url}
                </Typography>
                <LaunchIcon />
            </IconButton>
            <TagContainer
                tags={tags}
                listClass={classes.tenantTagContainer}
                emptyListClass={classes.emptyTenantContainer}
                itemClass={classes.tenantTagItem}
            />
            <IconButton aria-label="Copy" onClick={onCopy} color="primary" className={classes.squaredIconButton}>
                <Typography>
                    Copy Layout
                </Typography>
            </IconButton>
        </Paper>
    );
};