import { createReducer } from 'typesafe-actions';
import { AsyncEntity } from "../_shared/action-model-async";

import { FeatureSettings } from '../../api';
import { fetchFeaturesAsync, AccessAction } from './actions';

export default createReducer<AsyncEntity<FeatureSettings>, AccessAction>(AsyncEntity.Hold())
    .handleAction(fetchFeaturesAsync.request, (state, action) => state.ApplyPend())
    .handleAction(fetchFeaturesAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(fetchFeaturesAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(fetchFeaturesAsync.cancel, (state, action) => state.ApplyAbort(false));