import { Epic, combineEpics } from "redux-observable";

import { RootAction, RootState } from "../../store/types";
import { createEmptyFetchEpic, createNotificationEpic, createResponseSettings } from '../_shared/epic-creation';

import { fetchLanguagesAsync } from "./actions";
import { homeVisitApi } from "../../api";

const fetchLanguagesEpic: Epic<RootAction, RootAction, RootState> = createEmptyFetchEpic(
    fetchLanguagesAsync,
    homeVisitApi.getLanguages,
    (state: RootState) => state.analytics.schedule,
);

const fetchLanguagesNotificationEpic = createNotificationEpic(
    fetchLanguagesAsync,
    createResponseSettings(true, true),
);

export default combineEpics(
    fetchLanguagesEpic,
    fetchLanguagesNotificationEpic,
);