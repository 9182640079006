import React from "react";

import { Paper, IconButton, Typography } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import LaunchIcon from "@material-ui/icons/launch";

export default (props) => (
    <Paper style={{ margin: "1em", textAlign: "center", width: "min-content" }}>
        <IconButton aria-label="Open" disabled={true} color="primary" style={{ borderRadius: 0, display: "flex", justifyContent: "space-between", minWidth: "10em" }}>
            <LaunchIcon style={{ marginLeft: "0.5em" }} />
        </IconButton>
        <div style={{ display: "flex", flexDirection: "column", height: "2.5em", overflowY: "auto", backgroundColor: "#f7f7f7", padding: "0.5em" }}>
            <Skeleton animation="wave" />
        </div>
        <IconButton aria-label="Copy" disabled={true} color="primary" style={{ borderRadius: 0, display: "flex" }}>
            <Typography>
                Copy Layout
            </Typography>
        </IconButton>
    </Paper>
);