export const numToArray = (n: number) => {
    const arr = [n];

    for (let i = 0; i < n; i++) {
        arr[i] = i + 1;
    }

    return arr;
};

type Thunk = () => void;

export const combineThunks = (...thunks: Thunk[]) => () => thunks.forEach(thunk => thunk());