import { createAction, ActionType } from 'typesafe-actions';

import { TagSelectModel } from './models';
import { createEmptyAsyncActionPack } from '../_shared/action-creation';

export const toggleTag = createAction('TOGGLE_SEMINAR_TAG')<number>();
export const resetTags = createAction('RESET_SEMINAR_TAGS')();

export const fetchTagsAsync = createEmptyAsyncActionPack(
    'FETCH_TAGS_REQUEST',
    'FETCH_TAGS_SUCCESS',
    'FETCH_TAGS_FAILURE',
    'FETCH_TAGS_CANCEL',
)<TagSelectModel[]>();

export type TagsAction = ActionType<typeof fetchTagsAsync | typeof toggleTag | typeof resetTags>;