import React from 'react';

//  Components
import Item from './seminar-infobox-item';
import Button from '../../../_shared/Button/Button';
import Icon from '../../../_shared/Icon/Icon';

type InfoboxProps = {
    setClipboardCopy: (active: boolean) => void;

    alertAutoHideDuration?: number;
    description?: String;
    location?: String;
    phoneNumber?: String;
    seatsAvailable?: number;
    website?: String;
};

const redirectToForm = () => {
    location.href = '#leadRegistratorInternal';
};

const Infobox = (props: InfoboxProps) => {
    const {
        alertAutoHideDuration,
        description,
        location,
        phoneNumber,
        seatsAvailable,
        website,
        setClipboardCopy,
    } = props;

    let copyToClipboard = () => {
        let currentUrl = window.location.href;
        //  Create temporary input, to provide current URL as value
        let textArea = document.createElement('textarea');
        document.body.appendChild(textArea);
        textArea.value = currentUrl;
        textArea.select();
        document.execCommand('copy');
        //  Remove temporary input
        document.body.removeChild(textArea);
        //  Set copy state
        setClipboardCopy(true);
        //  Set timeout for state
        setTimeout(() => {
            setClipboardCopy(false);
        }, alertAutoHideDuration);
    };

    return (
        <div className="infobox">
            <div className="infobox__description">
                <label>{description}</label>
            </div>
            <Item title="Location" value={location} />
            <Item title="Phone number" value={phoneNumber} />
            <Item title="Availability" value={` ${seatsAvailable} seats left`} />
            <Item title="Website" value={website} />
            {seatsAvailable! > 0 && (
                <div className="infobox__cta">
                    <Button
                        text="Register now"
                        className="infobox__button"
                        onClick={redirectToForm}
                    />
                </div>
            )}
            <div className="infobox__media">
                <div className="infobox__media-item" onClick={copyToClipboard}>
                    <Icon name="link" className="infobox__media-item--copy-lik-icon" />
                    <label className="infobox__media-item--label">Copy Link</label>
                </div>
            </div>
        </div>
    );
};

Infobox.defaultProps = {
    onClick: () => {},
};

export default Infobox;
