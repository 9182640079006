import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import LeadInfo from './lead-info-container/lead-info-container';
import LeadConfirm from './lead-confirm/lead-confirm';

import { clearLead } from '../actions';
import { RootState } from '../../../store/types';
import { LeadModel } from '../../../api/models';

type StateProps = {
    lead: LeadModel | undefined;
};

type DispatchProps = {
    clearLeadEntity: () => void;
};

type OwnProps = {
    isAddressRequired: boolean;

    onSubmit: (lead: LeadModel) => void;
};

type LeadRegistratorProps = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: RootState): StateProps => ({
    lead: state.leads.current.entity,
});

const dispatchProps = (dispatch): DispatchProps => ({
    clearLeadEntity: () => dispatch(clearLead()),
});

const mergeProps = (stateProps: StateProps, dProps: DispatchProps, ownProps: OwnProps) => ({
    ...stateProps,
    ...dProps,
    ...ownProps,
});

const LeadRegistrator = (props: LeadRegistratorProps) => {
    const [didConfirm, confirm] = React.useState(false);
    const onConfirm = () => confirm(true);

    const {
        lead,
        isAddressRequired,

        onSubmit,
        clearLeadEntity,
    } = props;

    return lead && !didConfirm ? (
        <Fragment>
            <LeadConfirm isOpen={true} lead={lead} confirm={onConfirm} deny={clearLeadEntity} />
        </Fragment>
    ) : (
        <Fragment>
            <LeadInfo addressIsRequired={isAddressRequired} onSave={onSubmit} />
        </Fragment>
    );
};

export default connect(mapStateToProps, dispatchProps, mergeProps)(LeadRegistrator);
