import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Form } from 'dynamo-models';

import LeadRegistrator from '../../../leads/components/lead-registrator';
import TagReadonlyList from '../../../tags/components/tag-readonly-list';
import FormReadonlyContainer from '../../../_shared/dynamo/components/form-readonly-container';
import Button from '../../../_shared/Button/Button';

import { SeminarModel, LeadModel } from '../../../../api/models';
import { AsyncJob } from '../../../_shared/action-model-async';
import ToastNotification from '../../../_shared/ToastNotification/ToastNotification';
import Navigator from '../../../../routing/route-navigator';
import Infobox from '../seminar-infobox/seminar-infobox';
import Icon from '../../../_shared/Icon/Icon';

import { RootState } from '../../../../store/types';

// Styles
import './seminar-details.scss';
import { getSiteSettings, getBranding } from '../../../tenants/selectors';

type StateProps = {
    isBrandingEnabled: boolean;
};

type SeminarDetailsProps = {
    seminar: SeminarModel;
    detailsForm: Form;
    rsvpJob: AsyncJob;

    onBack: () => void;
    onRsvpSubmit: (lead: LeadModel) => void;
    onRsvpSuccess: () => void;
    onCancel: () => void;
} & StateProps;

type RsvpSegmentProps = {
    hasAvailableSeats: boolean;
    seminar: SeminarModel;
};

type DispatchProps = {
    onBack: () => void;
};

const mapStateToProps = (state: RootState): StateProps => ({
    isBrandingEnabled: getBranding(state),
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    onBack: Navigator.toEvents(dispatch),
});

const RsvpSegment = ({ hasAvailableSeats, seminar }: RsvpSegmentProps) => {
    return (
        <ToastNotification
            open={true}
            text="This seminar has no more seats available."
            theme="error"
        />
    );
};

const alertAutoHideDuration = 7000;

const ClipboardAlert = () => {
    return (
        <ToastNotification
            open={true}
            text="Success: URL has been copied to clipboard"
            theme="success"
            autoHideDuration={alertAutoHideDuration}
        />
    );
};

const SeminarDetails = ({
    seminar,
    detailsForm,
    isBrandingEnabled,
    onBack,
    onRsvpSubmit,
}: SeminarDetailsProps) => {
    const hasAvailableSeats = seminar.seatsAvailable > 0;
    let [clipboardCopy, setClipboardCopy] = useState(false);

    return (
        <>
            {clipboardCopy && <ClipboardAlert />}
            <div className="seminar-details__container">
                {!hasAvailableSeats && (
                    <div className="seminar-details__header">
                        <div className="seminar-rsvp-container">
                            <RsvpSegment hasAvailableSeats={hasAvailableSeats} seminar={seminar} />
                        </div>
                    </div>
                )}

                <div id="seminarInternals" className="seminar-details__content-inner">
                    <Button className="back-button" size="auto" theme="icon" onClick={onBack}>
                        {isBrandingEnabled ? (
                            <span className="back-button__container">
                                <Icon name="arrowAdvise" className={'back-button__arrow'} />
                                Back to seminars
                            </span>
                        ) : (
                            <Icon name="arrowBack" />
                        )}
                    </Button>

                    <FormReadonlyContainer form={detailsForm} context={seminar} />

                    <p className="seminar-details-tag-title">Tags</p>
                    <TagReadonlyList
                        tags={seminar.tags}
                        itemClass={'seminar-details-tag'}
                        listClass="seminar-details-tag-list"
                    />

                    {hasAvailableSeats ? (
                        <div className="lead-registrator-container">
                            <LeadRegistrator isAddressRequired={false} onSubmit={onRsvpSubmit} />
                        </div>
                    ) : null}
                </div>

                {isBrandingEnabled && (
                    <div className="seminar-details--outer">
                        <Infobox
                            alertAutoHideDuration={alertAutoHideDuration}
                            description={seminar.description}
                            location={seminar.location}
                            phoneNumber={seminar.phoneNumber}
                            seatsAvailable={seminar.seatsAvailable}
                            setClipboardCopy={setClipboardCopy}
                            website={seminar.website}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SeminarDetails);
