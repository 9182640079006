import { PayloadActionCreator, createAsyncAction, EmptyActionCreator, createAction } from "typesafe-actions";

type ResponseActionPack<
    TSuccess extends string,
    TFailure extends string,
    TCancel extends string,
    TResult,
> = {
    success: PayloadActionCreator<TSuccess, TResult>;
    failure: PayloadActionCreator<TFailure, string>;
    cancel: PayloadActionCreator<TCancel, string | undefined>;
};

export type AsyncActionPack<
    TRequest extends string,
    TSuccess extends string,
    TFailure extends string,
    TCancel extends string,
    TParam,
    TResult,
> = {
    request: PayloadActionCreator<TRequest, TParam>;
} & ResponseActionPack<TSuccess, TFailure, TCancel, TResult>;

export type AsyncEmptyActionPack<
    TRequest extends string,
    TSuccess extends string,
    TFailure extends string,
    TCancel extends string,
    TResult,
> = {
    request: EmptyActionCreator<TRequest>;
} & ResponseActionPack<TSuccess, TFailure, TCancel, TResult>;

export const createAsyncActionPack = <
    TRequest extends string,
    TSuccess extends string,
    TFailure extends string,
    TCancel extends string,
>(
    requestArg: TRequest,
    successArg: TSuccess,
    failureArg: TFailure,
    cancelArg: TCancel,
) => <
    TParam,
    TResult,
>(): AsyncActionPack<TRequest, TSuccess, TFailure, TCancel, TParam, TResult> => {
    const asyncAction = createAsyncAction(
        requestArg,
        successArg,
        failureArg,
        cancelArg,
    )<any, any, string, string | undefined>();

    return asyncAction as AsyncActionPack<
        TRequest,
        TSuccess,
        TFailure,
        TCancel,
        TParam,
        TResult
    >;
};

export const createEmptyAsyncActionPack = <
    TRequest extends string,
    TSuccess extends string,
    TFailure extends string,
    TCancel extends string,
>(
    requestArg: TRequest,
    successArg: TSuccess,
    failureArg: TFailure,
    cancelArg: TCancel,
) => <
    TResult,
>(): AsyncEmptyActionPack<TRequest, TSuccess, TFailure, TCancel, TResult> => {
    const asyncAction = createAsyncAction(
        requestArg,
        successArg,
        failureArg,
        cancelArg,
    )<undefined, any, string, string | undefined>();

    return asyncAction as AsyncEmptyActionPack<
        TRequest,
        TSuccess,
        TFailure,
        TCancel,
        TResult
    >;
};
