import React from 'react';

//  Style
import cx from 'classnames';
import './Button.scss';

type ButtonProps = {
    onClick: () => void;

    children?: any;
    className?: string;
    disabled?: boolean;
    size?: string;
    text?: string;
    theme?: string;
    type?: string;
};

export const Button = ({
    children,
    className,
    disabled,
    onClick,
    size,
    text,
    theme,
    type,
}: ButtonProps) => {
    return (
        <button
            className={cx('button-root', `button-${theme}`, `button-${size}`, className)}
            disabled={disabled ? disabled : undefined}
            onClick={() => onClick()}
            type={type}
        >
            {text}
            {children ? children : null}
        </button>
    );
};

Button.defaultProps = {
    onClick: () => {},
    size: 'small',
    theme: 'primary',
    text: '',
    type: undefined,
};

export default Button;
