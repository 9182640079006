import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AsyncEntity } from "../_shared/action-model-async";

import {
    fetchLanguagesAsync,
    LanguageAction,
} from './actions';

const languagesReducer = createReducer<AsyncEntity<string[]>, LanguageAction>(AsyncEntity.Hold())
    .handleAction(fetchLanguagesAsync.request, (state, action) => state.ApplyPend())
    .handleAction(fetchLanguagesAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(fetchLanguagesAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(fetchLanguagesAsync.cancel, (state, action) => state.ApplyAbort(true));

export default combineReducers({
    languages: languagesReducer,
});