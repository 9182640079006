import React, { useState } from 'react';
import { SelectOptionsModel } from '../form/models';

//  Styles
import cx from 'classnames';
import './Select.scss';
import Arrow from '../Icon/Icon';

type SelectProps = {
    className?: string;
    classNameContainer?: string;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    id?: string;
    label?: string;
    name?: string;
    options?: Array<SelectOptionsModel>;
    required?;
    showAlternativeArrow?: boolean;
    showLabel?: boolean;
    size?: string;
    value?: any;

    onBlur?: (event: any) => void;
    onChange: (event: any) => void;
    onFocus?: (event: any) => void;
};

export const Select = ({
    className,
    classNameContainer,
    disabled,
    error,
    helperText,
    label,
    id,
    name,
    onBlur,
    onChange,
    onFocus,
    options,
    required,
    showAlternativeArrow,
    showLabel,
    size,
    value,
}: SelectProps) => {
    //  State
    let [isActive, setIsActive] = useState(false);
    let [hasChanged, setHasChanged] = useState(false);

    let handleChange = (e) => {
        value !== label && setHasChanged(true);
        setIsActive(false);
        onChange(e);
    };

    let handleOnBlur = () => {
        setIsActive(false);
        onBlur ? onBlur : undefined;
    };

    let handleOnClick = () => {
        setIsActive(true);
    };

    let handleOnKeyDown = (event) => {
        // Activates dropdown on "spacebar" keydown
        if (event.keyCode === 32 || event.keyCode === ' ') {
            setIsActive(true);
        }
    };

    return (
        <div className={cx('select-field-container', classNameContainer)}>
            <div className="arrow-container">
                <select
                    className={cx(
                        `select select--${size}`,
                        className,
                        { 'select--error': error },
                        { 'select--filled': hasChanged },
                        { 'select--placeholder': !hasChanged && showLabel }
                    )}
                    defaultValue={''}
                    disabled={disabled ? disabled : undefined}
                    id={id}
                    name={name}
                    aria-label={label}
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    onClick={handleOnClick}
                    onFocus={onFocus ? onFocus : undefined}
                    onKeyDown={(e) => handleOnKeyDown(e)}
                    placeholder={label}
                    required={required ? required : undefined}
                >
                    <option value={''} disabled>
                        {label}
                    </option>
                    {options &&
                        options.map((item) => (
                            <option value={item.value} key={item.value}>
                                {item.label}
                            </option>
                        ))}
                </select>
                <Arrow
                    name={showAlternativeArrow ? 'arrowAdvise' : 'arrowAQE'}
                    className={cx(
                        'select__arrow',
                        { 'select__arrow--filled': hasChanged },
                        { 'select__arrow--active': isActive },
                        { 'select__arrow--error': error }
                    )}
                />
            </div>

            {showLabel && hasChanged && (
                <label className={cx('select__label', { ['select__label--show']: hasChanged })}>
                    {label}
                </label>
            )}

            {error && <span className="select__helper-error">{helperText}</span>}
        </div>
    );
};

Select.defaultProps = {
    onChange: () => {},
    showAlternativeArrow: false,
    showLabel: false,
    size: 'large',
};

export default Select;
