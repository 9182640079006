import { ActionType } from 'typesafe-actions';

import { StateModel } from '../../api';
import { createAsyncActionPack, createEmptyAsyncActionPack } from '../_shared/action-creation';

export const fetchStatesAsync = createEmptyAsyncActionPack(
    'FETCH_STATES_REQUEST',
    'FETCH_STATES_SUCCESS',
    'FETCH_STATES_FAILURE',
    'FETCH_STATES_CANCEL',
)<StateModel[]>();

export const fetchCountiesAsync = createAsyncActionPack(
    'FETCH_COUNTIES_REQUEST',
    'FETCH_COUNTIES_SUCCESS',
    'FETCH_COUNTIES_FAILURE',
    'FETCH_COUNTIES_CANCEL',
)<string, string[]>();

export type StatesAction = ActionType<typeof fetchStatesAsync>;
export type CountiesAction = ActionType<typeof fetchCountiesAsync>;