import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';

import EventRoute from './route-components/routed-event/routed-event';
import AdminRoute from './route-components/routed-admin';
import EditorRoute from './route-components/routed-editor';
import HomeVisitRegistratorRoute from './route-components/routed-home-visit-registrator';
import SeminarDetailsRoute from './route-components/routed-seminar-details';
import AuthRoute from './route-components/routed-callback';
import PasscodeRoute from './route-components/routed-passcode';
import RouteBar from './route-components/route-bar/route-bar';

import { User } from 'oidc-client';
import { RootState } from '../store/types';
import Navigator from './route-navigator';
import userManager from '../utils/userManager';
import { getSiteSettings, getBranding } from '../features/tenants/selectors';

type StateProps = {
    user?: User;
    isLoadingUser: boolean;
    isBrandingEnabled: boolean;
};

type DispatchProps = {
    openEvents: () => void;
    openDashboard: () => void;
};

type RouteContainerProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
    user: state.auth.user,
    isLoadingUser: state.auth.isLoadingUser,
    isBrandingEnabled: getBranding(state),
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    openEvents: Navigator.toEvents(dispatch),
    openDashboard: Navigator.toDashboard(dispatch),
});

const RouteContainer = (props: RouteContainerProps) => {
    const {
        user,
        isLoadingUser,
        isBrandingEnabled,

        openEvents,
        openDashboard,
    } = props;

    const signOut = () => {
        userManager.removeUser();
        openEvents();
    };

    const withAuth = (child: any) =>
        isLoadingUser ? null : user !== null ? child : <Redirect to="/login" />;

    return (
        <React.Fragment>
            <RouteBar
                user={user}
                openEvents={openEvents}
                openDashboard={openDashboard}
                signOut={signOut}
                isBrandingEnabled={isBrandingEnabled}
            />

            <Route path="/#/">
                <Redirect to="/event" />
            </Route>
            <Route exact path="/">
                <Redirect to="/event" />
            </Route>
            <Route exact path="/event">
                <EventRoute />
            </Route>
            <Route exact path="/event/seminar/:seminarId">
                <SeminarDetailsRoute />
            </Route>
            <Route exact path="/event/schedule">
                <EventRoute />
                <HomeVisitRegistratorRoute />
            </Route>
            <Route exact path="/admin">
                {withAuth(<AdminRoute />)}
            </Route>
            <Route exact path="/admin/editor/edit/:templateId">
                {withAuth(<EditorRoute />)}
            </Route>
            <Route exact path="/login">
                <AuthRoute />
            </Route>
            <Route exact path="/passcode/:passcode">
                <PasscodeRoute />
            </Route>
        </React.Fragment>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteContainer);
