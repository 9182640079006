import { ActionType } from 'typesafe-actions';

import { FeatureSettings } from '../../api';
import { createEmptyAsyncActionPack } from '../_shared/action-creation';

export const fetchFeaturesAsync = createEmptyAsyncActionPack(
    'FETCH_FEATURES_REQUEST',
    'FETCH_FEATURES_SUCCESS',
    'FETCH_FEATURES_FAILURE',
    'FETCH_FEATURES_CANCEL',
)<FeatureSettings>();

export type AccessAction = ActionType<typeof fetchFeaturesAsync>;