import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import analyticsReducer from '../features/analytics/reducer';
import featureReducer from '../features/access/reducer';
import leadReducer from '../features/leads/reducer';
import tagsReducer from '../features/tags/reducer';
import seminarsReducer from '../features/seminars/reducer';
import homeVisitReducer from '../features/home-visits/reducer';
import localizationReducer from '../features/localization/reducer';
import locationReducer from '../features/location/reducer';
import rsvpReducer from '../features/rsvp/reducer';
import tenantReducer from '../features/tenants/reducer';
import templateReducer from '../features/template/reducer';
import dialogReducer from '../features/dialog/reducer';

import { reducer as oidcReducer } from 'redux-oidc';
import { reducer as reduxFormReducer } from 'redux-form';

const rootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    auth: oidcReducer,
    analytics: analyticsReducer,
    features: featureReducer,
    leads: leadReducer,
    tags: tagsReducer,
    seminars: seminarsReducer,
    homeVisits: homeVisitReducer,
    localization: localizationReducer,
    location: locationReducer,
    rsvp: rsvpReducer,
    // Not dynamo forms
    form: reduxFormReducer,
    tenant: tenantReducer,
    template: templateReducer,
    dialog: dialogReducer,
});

export default rootReducer;