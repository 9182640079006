import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Paper } from '@material-ui/core';
import TextField from '../../_shared/TextField/TextField';
import Button from '../../_shared/Button/Button';
import CloseIcon from '@material-ui/icons/close';
import Select from '../../_shared/Select/Select';
import LeadRegistrator from '../../leads/components/lead-registrator';
import { searchHomeVisitsAsync } from '../actions';
import { rsvpHomeVisitAsync } from '../../rsvp/actions';
import { HomeVisitFilterModel, LeadModel, HomeVisitRsvpModel } from '../../../api';
import { RootState } from '../../../store/types';
import { AsyncEntity, AsyncJob } from '../../_shared/action-model-async';
import Navigator from '../../../routing/route-navigator';
import { HomeVisitRsvpActionModel } from '../../_shared/action-models';
import { SelectOptionsModel } from '../../_shared/form/models';

// Styles
import './home-visit-registrator.scss';
import { getSiteSettings, getBranding } from '../../tenants/selectors';

type StateProps = {
    datesEntity: AsyncEntity<Date[], HomeVisitFilterModel>;
    languagesEntity: AsyncEntity<string[]>;
    rsvpJob: AsyncJob;
    isBrandingEnabled: boolean;
};

type DispatchProps = {
    search: (filter: HomeVisitFilterModel) => void;
    onRsvp: (rsvpModel: HomeVisitRsvpActionModel) => void;
    onSuccess: () => void;
    onCancel: () => void;
};

type HomeVisitRegistratorProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
    datesEntity: state.homeVisits.search,
    languagesEntity: state.localization.languages,
    rsvpJob: state.rsvp.homeVisit,
    isBrandingEnabled: getBranding(state),
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    search: (filter: HomeVisitFilterModel) => dispatch(searchHomeVisitsAsync.request(filter)),
    onRsvp: (rsvpModel: HomeVisitRsvpActionModel) =>
        dispatch(rsvpHomeVisitAsync.request(rsvpModel)),
    onSuccess: Navigator.toEvents(dispatch),
    onCancel: Navigator.toEvents(dispatch),
});

const createApplyMonthDelta = (
    year: number,
    month: number,
    filterDateSetter: (filterDate: Date) => void
) => (delta: number) => {
    if (delta === 1 && month === 11) {
        return filterDateSetter(new Date(year + 1, 0));
    } else if (delta === -1 && month === 0) {
        return filterDateSetter(new Date(year - 1, 11));
    } else {
        return filterDateSetter(new Date(year, month + delta));
    }
};

const createSetDateWithString = (dateSetter: (date: Date | undefined) => void) => (date: string) =>
    dateSetter(date.length > 0 ? new Date(date) : undefined);

const HomeVisitRegistrator = (props: HomeVisitRegistratorProps) => {
    const [hasChosen, setHasChosen] = useState(false);

    const {
        isBrandingEnabled,
        languagesEntity,
        datesEntity,

        search,
        onCancel,
    } = props;

    const { language, zipCode, date } = datesEntity.inputs;

    const changeFilter = (deltaFilter: HomeVisitFilterModel) =>
        search({
            ...datesEntity.inputs,
            ...deltaFilter,
        });

    const changeZip = (e: any) => changeFilter({ zipCode: e.target.value });
    const changeLanguage = (e: any) => changeFilter({ language: e.target.value });
    const changeDate = (e: Date | undefined) => changeFilter({ date: e });

    const applyMonthDelta = createApplyMonthDelta(
        (date ?? new Date()).getFullYear(),
        (date ?? new Date()).getMonth(),
        changeDate
    );
    const incrementMonth = () => applyMonthDelta(1);
    const decrementMonth = () => applyMonthDelta(-1);

    const homeVisitModel: HomeVisitRsvpModel = {
        zip: zipCode ?? '',
        language: language ?? '',
        startDateTime: date ?? new Date(),
    };

    /* Language Array */
    let languagesArray: Array<SelectOptionsModel> = [];
    languagesEntity.entity &&
        languagesEntity.entity.map((language) => {
            languagesArray.push({
                label: language,
                value: language,
            });
        });

    const languageRender = languagesEntity.entity ? (
        <Select
            classNameContainer="home-visit-field"
            options={languagesArray}
            onChange={changeLanguage}
            label="Language"
            name="language"
            showLabel={isBrandingEnabled}
            showAlternativeArrow={isBrandingEnabled}
        />
    ) : null;

    /* Dates Array */
    let datesArray: Array<SelectOptionsModel> = [];
    datesEntity.entity?.map((date) => {
        datesArray.push({
            label: date.toLocaleString(),
            value: date,
        });
    });

    let datesRender = (
        <div className="home-visit-content-inner">
            <div>
                <Button size="large" text="Prev Month" onClick={decrementMonth} />
                <label className="dates-label">Dates</label>
                <Button size="large" text="Next Month" onClick={incrementMonth} />
            </div>
            <Select
                name="time"
                label="Time"
                onChange={(e) => createSetDateWithString(changeDate)(e.target.value)}
                options={datesArray}
                showLabel={isBrandingEnabled}
                showAlternativeArrow={isBrandingEnabled}
            />
        </div>
    );

    return (
        <div className="home-visit-container">
            <Paper className="home-visit-content" elevation={3}>
                <div className="home-visit-header-container">
                    <h2 className="home-visit-heading">Schedule a Home Visit</h2>

                    <Button theme="icon" size="auto" className="close-button" onClick={onCancel}>
                        <CloseIcon />
                    </Button>
                </div>
                <div className="home-visit-content-inner">
                    <TextField
                        name="zip"
                        placeholder="ZIP code"
                        classNameContainer="home-visit-field"
                        onChange={changeZip}
                        showLabel={isBrandingEnabled}
                        value={homeVisitModel.zip}
                    />

                    {languageRender}
                </div>
                {datesRender}
                {date ? (
                    <Button
                        onClick={() => setHasChosen(true)}
                        size="large"
                        text="Choose"
                        className="choose-button"
                    />
                ) : null}

                {hasChosen ? (
                    <LeadRegistrator
                        isAddressRequired={true}
                        onSubmit={(lead: LeadModel) =>
                            props.onRsvp({ homeVisit: homeVisitModel, lead })
                        }
                    />
                ) : null}
            </Paper>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeVisitRegistrator);
