import { ActionType, createAction } from 'typesafe-actions';

import { FormSaveModel, TemplateModel } from '../../api';
import { Form } from 'dynamo-models';
import { createAsyncActionPack, createEmptyAsyncActionPack } from '../_shared/action-creation';

export const revertTemplateAsync = createAsyncActionPack(
    'REVERT_TEMPLATE_REQUEST',
    'REVERT_TEMPLATE_SUCCESS',
    'REVERT_TEMPLATE_FAILURE',
    'REVERT_TEMPLATE_CANCEL',
)<TemplateModel, any>();

export const publishTemplateAsync = createAsyncActionPack(
    'PUBLISH_TEMPLATE_REQUEST',
    'PUBLISH_TEMPLATE_SUCCESS',
    'PUBLISH_TEMPLATE_FAILURE',
    'PUBLISH_TEMPLATE_CANCEL',
)<TemplateModel, any>();

export const saveFormAsync = createAsyncActionPack(
    'SAVE_FORM_REQUEST',
    'SAVE_FORM_SUCCESS',
    'SAVE_FORM_FAILURE',
    'SAVE_FORM_CANCEL',
)<FormSaveModel, any>();

export const upsertTemplateAsync = createAsyncActionPack(
    'CREATE_TEMPLATE_REQUEST',
    'CREATE_TEMPLATE_SUCCESS',
    'CREATE_TEMPLATE_FAILURE',
    'CREATE_TEMPLATE_CANCEL',
)<TemplateModel, TemplateModel>();

export const onSelectTemplate = createAction(
    'SELECT_TEMPLATE',
)<TemplateModel>();

export const onTemplateNameChange = createAction(
    'TEMPLATE_NAME_CHANGE',
)<string>();

export const fetchFormAsync = createAsyncActionPack(
    'FETCH_FORM_REQUEST',
    'FETCH_FORM_SUCCESS',
    'FETCH_FORM_FAILURE',
    'FETCH_FORM_CANCEL',
)<number, Form>();

export const fetchTemplatesAsync = createEmptyAsyncActionPack(
    'FETCH_TEMPLATES_REQUEST',
    'FETCH_TEMPLATES_SUCCESS',
    'FETCH_TEMPLATES_FAILURE',
    'FETCH_TEMPLATES_CANCEL',
)<TemplateModel[]>();

export type ManageAction = ActionType<
    typeof revertTemplateAsync | typeof publishTemplateAsync | typeof saveFormAsync
    | typeof upsertTemplateAsync | typeof onSelectTemplate | typeof onTemplateNameChange
    | typeof fetchFormAsync | typeof fetchTemplatesAsync
>;

export type TemplateAction = ManageAction;