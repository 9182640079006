import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AsyncEntity } from "../_shared/action-model-async";

import {
    searchSeminarsAsync, fetchSeminarAsync,
    SeminarAction, SeminarSearchAction,
} from './actions';

import { SeminarModel, SeminarPaginationModel, SeminarFilterModel } from '../../api';

const seminarReducer = createReducer<AsyncEntity<SeminarModel, number>, SeminarAction>(AsyncEntity.Allocate(0))
    .handleAction(fetchSeminarAsync.request, (state, action) => state.ApplyPend(action.payload))
    .handleAction(fetchSeminarAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(fetchSeminarAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(fetchSeminarAsync.cancel, (state, action) => state.ApplyAbort(false));

const searchReducer = createReducer<AsyncEntity<SeminarPaginationModel, SeminarFilterModel>, SeminarSearchAction>(
    AsyncEntity.Allocate<SeminarPaginationModel, SeminarFilterModel>(
        {
            pageNumber: 1,
            showFull: false,
            tags: [],
        },
    ))
    .handleAction(searchSeminarsAsync.request, (state, action) => state.ApplyPend(action.payload))
    .handleAction(searchSeminarsAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(searchSeminarsAsync.failure, (state, action) => state.ApplyError(action.payload, true))
    .handleAction(searchSeminarsAsync.cancel, (state, action) => state.ApplyAbort(true));

export default combineReducers({
    current: seminarReducer,
    search: searchReducer,
});