import React from 'react';
import { User } from 'oidc-client';
import userManager from '../../../utils/userManager';
import Button from '../../../features/_shared/Button/Button';
import { useLocation } from 'react-router';

//  Style
import './route-bar.scss';

type RouteBarProps = {
    user?: User;
    isBrandingEnabled?: boolean;

    openEvents: () => void;
    openDashboard: () => void;
    signOut: () => void;
};

const RouteBar = (props: RouteBarProps) => {
    const {
        user,
        isBrandingEnabled,

        openEvents,
        openDashboard,
        signOut,
    } = props;

    const isSeminarDetailsPage = useLocation().pathname.includes('seminar');

    return (
        <div
            className={
                isSeminarDetailsPage ? 'nav-header-background--seminar' : 'nav-header-background'
            }
        >
            <div className="nav-header">
                <span className="nav-home" onClick={openEvents} role="button">
                    {isBrandingEnabled ? '' : 'Ascend Event Hub'}
                </span>

                <div className="nav-items">
                    {user == null ? (
                        <Button
                            text="Sign in"
                            theme="primary"
                            onClick={() => userManager.signinRedirect()}
                        />
                    ) : null}
                    {user != null ? (
                        <Button text="Sign out" theme="primary" onClick={signOut} />
                    ) : null}
                    {user != null ? (
                        <Button
                            text="Portal"
                            className="portal-button"
                            theme="primary"
                            onClick={openDashboard}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default RouteBar;
