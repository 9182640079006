import { createReducer } from 'typesafe-actions';
import { AsyncEntity, AsyncStatus } from "../_shared/action-model-async";

import {
    fetchTagsAsync, toggleTag, resetTags,
    TagsAction,
} from './actions';
import { TagSelectModel } from './models';

const tagsReducer = createReducer<AsyncEntity<TagSelectModel[]>, TagsAction>(AsyncEntity.Hold())
    .handleAction(fetchTagsAsync.request, (state, action) => state.ApplyPend())
    .handleAction(fetchTagsAsync.success, (state, action) => state.ApplyPut(action.payload))
    .handleAction(fetchTagsAsync.failure, (state, action) => state.ApplyError(action.payload))
    .handleAction(fetchTagsAsync.cancel, (state, action) => state.ApplyAbort(true))
    .handleAction(resetTags, (state, action) => state.state.status === AsyncStatus.Succeeded
        ? state.ApplyPut(state.entity?.map(v => ({
            ...v,
            isSelected: false,
        })) ?? [])
        : state)
    .handleAction(toggleTag, (state, action) => state.state.status === AsyncStatus.Succeeded
        ? state.ApplyPut(state.entity?.map(v => ({
            ...v,
            isSelected: (v.tagId === action.payload ? !v.isSelected : v.isSelected) ?? false,
        })) ?? [])
        : state);

export default tagsReducer;