import { Epic, combineEpics } from "redux-observable";
import { isActionOf } from "typesafe-actions";

import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { RootAction, RootState } from "../../store/types";
import { createEmptyFetchEpic, createNotificationEpic, createResponseSettings } from '../_shared/epic-creation';

import { toggleTag, resetTags, fetchTagsAsync } from "./actions";
import { searchSeminarsAsync } from '../seminars/actions';
import { seminarApi, SeminarFilterModel } from "../../api";

const fetchTagsEpic: Epic<RootAction, RootAction, RootState> = createEmptyFetchEpic(
    fetchTagsAsync,
    seminarApi.getTags,
    (state: RootState) => state.tags.schedule,
);

const fetchTagsNotificationEpic = createNotificationEpic(
    fetchTagsAsync,
    createResponseSettings(true, true),
);

const toggleTagEpic: Epic<RootAction, RootAction, RootState> = (action$, state) => action$.pipe(
    filter(isActionOf(toggleTag)),
    switchMap(action =>
        of(searchSeminarsAsync.request(state.value.seminars.search.inputs ? {
            ...state.value.seminars.search.inputs,
            tags: state.value.seminars.search.inputs.tags.some(v => v === action.payload)
            ? state.value.seminars.search.inputs.tags.filter(v => v !== action.payload)
            : state.value.seminars.search.inputs.tags.concat(action.payload),
            pageNumber: 1,
        } : {
            tags: state.value.tags.entity?.map(v => v.tagId) ?? [],
            showFull: false,
            pageNumber: 1,
        } as SeminarFilterModel)),
    ),
);

const resetTagsEpic: Epic<RootAction, RootAction, RootState> = (action$, state) => action$.pipe(
    filter(isActionOf(resetTags)),
    switchMap(action =>
        of(searchSeminarsAsync.request(state.value.seminars.search.inputs ? {
            ...state.value.seminars.search.inputs,
            tags: state.value.tags.entity?.map(v => v.tagId ?? 0) ?? [],
            pageNumber: 1,
        } : {
            tags: state.value.tags.entity?.map(v => v.tagId ?? 0) ?? [],
            showFull: false,
            pageNumber: 1,
        } as SeminarFilterModel)),
    ),
);

export default combineEpics(
    fetchTagsEpic,
    toggleTagEpic,
    resetTagsEpic,

    fetchTagsNotificationEpic,
);